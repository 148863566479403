import { Button, Col, DatePicker, Form, Input, Row, Select, Spin, Switch, UploadFile, notification } from 'antd';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import {
  FORMAT_DATE,
  REGEX_BANK_NUMBER,
  REGEX_CITIZEN_ID,
  REGEX_EMAIL,
  REGEX_PHONE,
  validationMessage,
} from '../../../constants';
import { AddressFull, ButtonPicker, SearchAddress } from '../../../components';
import { CategoryEntity, CategoryTypeEnum, PartnerEntity, StatusEnum } from '../../../graphql/type.interface';
import { useCategoriesQuery } from '../../../graphql/queries/categories.generated';
import { useAgencyUpdateTechnicianMutation } from '../../../graphql/mutations/agencyUpdateTechnician.generated';
import { AgencyGetDetailTechnicianDocument } from '../../../graphql/queries/agencyGetDetailTechnician.generated';
import { UploadImage, UploadImageRef } from '../../../components/upload-image';
import { useAgencyCreateTechnicianMutation } from '../../../graphql/mutations/agencyCreateTechnician.generated';
import { AgencyGetTechniciansDocument } from '../../../graphql/queries/agencyGetTechnicians.generated';
import { AppRoutes } from '../../../helpers';

type Props = {
  technician?: PartnerEntity;
  setEditing?: () => void;
  editing: boolean;
};

type FormData = {
  avatarId: string;
  bank: string;
  birthday: string;
  cardNumber: string;
  citizenId: string;
  education?: string;
  email: string;
  phone: string;
  fullname: string;
  level: string;
  qualifications: string[];
  isActive: boolean;
  address: AddressFull;
};

const FilterCategory = {
  filters: null,
  limit: 1000,
  isActive: StatusEnum.ACTIVE,
};

export const TechnicianForm = memo(({ technician, setEditing, editing }: Props) => {
  const navigation = useNavigate();

  const [form] = useForm();

  const educationValue = Form.useWatch('education', form);

  const { data: dataQualications, loading: gettingQualications } = useCategoriesQuery({
    variables: { ...FilterCategory, type: CategoryTypeEnum.QUALIFICATION },
  });
  const { data: dataEducation, loading: gettingEducation } = useCategoriesQuery({
    variables: { ...FilterCategory, type: CategoryTypeEnum.EDUCATION },
  });

  const optionsQualification = useMemo(
    () => (dataQualications?.categories.items ?? []).map((q: CategoryEntity) => ({ value: q.id, label: q.name })),
    [dataQualications],
  );
  const optionsEducation = useMemo(
    () => (dataEducation?.categories.items ?? []).map((q: CategoryEntity) => ({ value: q.id, label: q.name })),
    [dataEducation],
  );

  const [updateTechnicianApi, { loading: updating }] = useAgencyUpdateTechnicianMutation({
    onError(error) {
      notification.error({
        message: 'Sửa thông tin kỹ thuật viên thất bại',
        description: error.message,
      });
    },
    onCompleted() {
      notification.success({
        message: 'Sửa thông tin kỹ thuật viên thành công',
        description: `Thông tin kỹ thuật viên ${technician?.fullname} đã được cập nhật`,
      });
      navigation(AppRoutes.technician.list);
    },
  });

  const [createTechnicianApi, { loading: creating }] = useAgencyCreateTechnicianMutation({
    onError(error) {
      notification.error({
        message: 'Thêm mới kỹ thuật viên thất bại',
        description: error.message,
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Thêm mới kỹ thuật viên thành công',
        description: `Kỹ thuật viên ${data?.agencyCreateTechnician?.fullname} đã có trong danh sách`,
      });
      navigation(AppRoutes.technician.list);
    },
  });

  const onFinish = async (input: FormData) => {
    const avatarId = await ref.current?.upload();

    const { address, phone, ...withoutInput } = input;
    // update
    if (technician) {
      updateTechnicianApi({
        variables: { input: { ...withoutInput, ...address, id: technician?.id, avatarId } },
        refetchQueries: [AgencyGetDetailTechnicianDocument],
      });
      return;
    }
    createTechnicianApi({
      variables: { input: { ...withoutInput, ...address, phone, avatarId } },
      refetchQueries: [AgencyGetTechniciansDocument],
    });
  };

  const ref = useRef<UploadImageRef>(null);

  useEffect(() => {
    if (technician) {
      form.setFieldsValue({
        ...technician,
        birthday: dayjs(technician?.birthday),
        education: technician?.education?.id,
        qualifications: (technician?.qualifications || []).map((q: CategoryEntity) => q.id),
      });
    }
  }, [form, technician]);

  return (
    <Spin spinning={gettingEducation || gettingQualications || updating || creating}>
      <Form
        disabled={!editing}
        form={form}
        name="technician-form"
        id="technician-form"
        layout="vertical"
        initialValues={{ isActive: !technician && true }}
        className="bg-white w-8/12 mx-auto rounded  p-20px  m-20px mb-80px"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="px-20px">
          <h2 className="font-bold text-16px leading-24px pb-20px">Thông tin cá nhân</h2>
          <Form.Item label="Ảnh đại diện" name="avatarId">
            <UploadImage
              ref={ref}
              files={
                technician?.avatar?.fullThumbUrl
                  ? ([
                      {
                        uid: technician?.avatar?.id,
                        name: technician?.avatar?.name,
                        status: 'done',
                        url: technician?.avatar?.fullThumbUrl,
                      },
                    ] as UploadFile[])
                  : undefined
              }
            />
          </Form.Item>
          <Row gutter={20} align={'middle'}>
            <Col sm={24} lg={8}>
              <Form.Item
                label="Họ và Tên"
                name="fullname"
                rules={[{ required: true, message: validationMessage.required }]}
              >
                <Input maxLength={255} placeholder="Nhập Họ và Tên" />
              </Form.Item>
            </Col>
            <Col sm={24} lg={8}>
              <Form.Item
                label="Ngày sinh"
                name="birthday"
                rules={[{ required: true, message: validationMessage.required }]}
              >
                <DatePicker format={FORMAT_DATE} className="w-full" placeholder="Chọn ngày sinh" />
              </Form.Item>
            </Col>
            <Col sm={24} lg={8}>
              <Form.Item
                label="Số điện thoại"
                name="phone"
                rules={[
                  { required: true, message: validationMessage.required },
                  {
                    pattern: REGEX_PHONE,
                    message: validationMessage.phone.notValid,
                  },
                ]}
              >
                <Input
                  disabled={technician ? true : false}
                  maxLength={10}
                  className="w-full"
                  placeholder="Nhập Số điện thoại"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} align={'middle'} className="mt-20px">
            <Col sm={24} lg={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: validationMessage.required },
                  {
                    pattern: REGEX_EMAIL,
                    message: validationMessage.email.notValid,
                  },
                ]}
              >
                <Input maxLength={255} placeholder="Nhập Email" />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                label="Số CMND/CCCD"
                name="citizenId"
                rules={[
                  { required: true, message: validationMessage.required },
                  { pattern: REGEX_CITIZEN_ID, message: validationMessage.cccd.notValid },
                ]}
              >
                <Input maxLength={12} minLength={9} placeholder="Nhập Số CMND/CCCD" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} align={'middle'} className="mt-20px">
            <Col sm={24}>
              <Form.Item
                label={
                  <span>
                    <span className="text-error">*</span> Địa chỉ
                  </span>
                }
                name="address"
                rules={[
                  {
                    validator(_, value) {
                      if (
                        !value ||
                        !value?.addressMoreInfo ||
                        !value?.latitude ||
                        !value?.longitude ||
                        !value.mapAddress
                      )
                        return Promise.reject(validationMessage?.required);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <SearchAddress
                  defaultValues={
                    technician
                      ? {
                          mapAddress: technician?.mapAddress as string,
                          latitude: technician?.latitude as number,
                          longitude: technician?.longitude as number,
                          addressMoreInfo: technician?.addressMoreInfo as string,
                        }
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} align={'middle'} className="mt-20px">
            <Col sm={24}>
              <Form.Item
                label="Chuyên môn"
                name="qualifications"
                rules={[{ required: true, message: validationMessage.required }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn chuyên môn"
                  options={optionsQualification}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20} align={'middle'} className="mt-20px">
            <Col sm={24}>
              <Form.Item label="Trình độ học vấn" name="education">
                <ButtonPicker options={optionsEducation} defaultValue={educationValue} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="h-8px w-full bg-[#f5f5f5]"></div>
        <div className="px-20px  py-20px">
          <h2 className="font-bold text-16px leading-24px pb-20px">Thông tin thanh toán</h2>
          <Row gutter={20} align={'middle'}>
            <Col sm={12}>
              <Form.Item label="Ngân hàng" name="bank">
                <Input maxLength={255} placeholder="Nhập Ngân hàng" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                label="Số thẻ"
                name="cardNumber"
                rules={[
                  {
                    pattern: REGEX_BANK_NUMBER,
                    message: validationMessage.cardNumber.notValid,
                  },
                ]}
              >
                <Input minLength={10} maxLength={19} placeholder="Số thẻ" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="h-8px w-full bg-[#f5f5f5]"></div>
        <div className="px-20px  pt-20px">
          <Row gutter={20} align={'middle'}>
            <Col span={8}>
              <Form.Item label="">Trạng thái làm việc</Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="" name="isActive">
                <Switch defaultChecked={technician ? technician?.isActive : true} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}></Form.Item>
      </Form>
      {editing && (
        <div className="fixed inset-x-0 bottom-0  bg-white h-56px w-full flex justify-end py-8px pr-24px">
          {technician && (
            <Button type="default" className="mr-16px" onClick={setEditing}>
              Hủy
            </Button>
          )}
          <Button
            loading={gettingEducation || gettingQualications || updating || creating}
            disabled={gettingEducation || gettingQualications || updating || creating}
            type="primary"
            htmlType="submit"
            form="technician-form"
          >
            Lưu
          </Button>
        </div>
      )}
    </Spin>
  );
});
