import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type PartnerGetCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type PartnerGetCourseQueryResponse = { __typename?: 'Query' } & {
  partnerGetCourse: { __typename?: 'CourseEntity' } & Pick<
    Types.CourseEntity,
    | 'address'
    | 'bannerId'
    | 'content'
    | 'createdAt'
    | 'deletedAt'
    | 'endDate'
    | 'enrolledCount'
    | 'id'
    | 'isActive'
    | 'isEnrolled'
    | 'name'
    | 'openDate'
    | 'price'
    | 'startDate'
    | 'teacher'
    | 'teacherDescription'
    | 'updatedAt'
    | 'videoUrl'
  > & {
      banner?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'createdAt'
          | 'fileSize'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'id'
          | 'isDeleted'
          | 'mimeType'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
          | 'updatedAt'
          | 'videoUrl'
        >
      >;
      enrollments: Array<
        { __typename?: 'CourseEnrollmentEntity' } & Pick<
          Types.CourseEnrollmentEntity,
          'courseId' | 'createdAt' | 'deletedAt' | 'id' | 'partnerId' | 'updatedAt'
        >
      >;
    };
};

export const PartnerGetCourseDocument = gql`
  query partnerGetCourse($id: String!) {
    partnerGetCourse(id: $id) {
      address
      banner {
        createdAt
        fileSize
        fullOriginalUrl
        fullThumbUrl
        id
        isDeleted
        mimeType
        name
        originalUrl
        ownerId
        thumbUrl
        type
        updatedAt
        videoUrl
      }
      bannerId
      content
      createdAt
      deletedAt
      endDate
      enrolledCount
      enrollments {
        courseId
        createdAt
        deletedAt
        id
        partnerId
        updatedAt
      }
      id
      isActive
      isEnrolled
      name
      openDate
      price
      startDate
      teacher
      teacherDescription
      updatedAt
      videoUrl
    }
  }
`;
export function usePartnerGetCourseQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerGetCourseQueryResponse, PartnerGetCourseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerGetCourseQueryResponse, PartnerGetCourseQueryVariables>(
    PartnerGetCourseDocument,
    options,
  );
}
export function usePartnerGetCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerGetCourseQueryResponse, PartnerGetCourseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerGetCourseQueryResponse, PartnerGetCourseQueryVariables>(
    PartnerGetCourseDocument,
    options,
  );
}
export type PartnerGetCourseQueryHookResult = ReturnType<typeof usePartnerGetCourseQuery>;
export type PartnerGetCourseLazyQueryHookResult = ReturnType<typeof usePartnerGetCourseLazyQuery>;
export type PartnerGetCourseQueryResult = Apollo.QueryResult<
  PartnerGetCourseQueryResponse,
  PartnerGetCourseQueryVariables
>;
