import React, { ReactNode } from 'react';
import { InputNumber, InputNumberProps } from 'antd';

type NumericInputProps = {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  suffix?: ReactNode | string;
} & InputNumberProps;

export const InputNumberFormat = (props: NumericInputProps) => {
  const { value, onChange } = props;

  const handleChange = (newValue: any) => {
    onChange?.(newValue);
  };

  return (
    <div className={`w-full relative ${props.suffix ? 'hidden-arrows-input-number' : ''} `}>
      <InputNumber
        min={1}
        {...props}
        defaultValue={value}
        onChange={handleChange}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        parser={(value) => value!.replace(/\$\s?|([.]*)/g, '')}
      />
      <div className="absolute right-[8px] top-[50%] translate-y-[-50%]">{props.suffix}</div>
    </div>
  );
};
