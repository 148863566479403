import { Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumb } from '../../components/breadcrumb';
import { AppRoutes, AppRoutesName } from '../../helpers';
import { BookingStatusEnum, PartnerTypeEnum } from '../../graphql/type.interface';
import { useAuth } from '../../contexts';

import { TitlePage } from './components/title-page';
import { TableRequest } from './components/table';
import { PopupRequestRepair } from './components/popup-request-repair';

import './style.less';

export const REQUEST_REPAIR_KEYS = {
  waiting: {
    value: BookingStatusEnum.WAIT_FOR_CONFIRM,
    label: 'Chờ nhận',
  },
  waiting_ktv: {
    value: BookingStatusEnum.ASSIGNED_TECHNICIAN,
    label: 'Chờ KTV nhận',
  },
  ktv_receiving: {
    value: 'ktv_receiving',
    label: 'Đang nhận',
  },
  technician_arriving: {
    value: 'technician_arriving',
    label: 'KTV đang đến',
  },
  quotation: {
    value: 'quotation',
    label: 'Kiểm tra - báo giá',
  },
  settlement: {
    value: 'settlement',
    label: 'Đang bàn giao',
  },
  completed: {
    value: 'completed',
    label: 'Hoàn thành',
  },
  cancel: {
    value: 'cancel',
    label: 'Đã hủy',
  },
  home_receiving: {
    value: 'home_receiving',
    label: 'Đang nhận',
  },
};

const TABS_AGENCY = [
  {
    key: REQUEST_REPAIR_KEYS.waiting.value,
    label: REQUEST_REPAIR_KEYS.waiting.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.waiting_ktv.value,
    label: REQUEST_REPAIR_KEYS.waiting_ktv.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.technician_arriving.value,
    label: REQUEST_REPAIR_KEYS.technician_arriving.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.quotation.value,
    label: REQUEST_REPAIR_KEYS.quotation.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.settlement.value,
    label: REQUEST_REPAIR_KEYS.settlement.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.completed.value,
    label: REQUEST_REPAIR_KEYS.completed.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.cancel.value,
    label: REQUEST_REPAIR_KEYS.cancel.label,
  },
];

const TABS_TECHNICIAN = [
  {
    key: REQUEST_REPAIR_KEYS.waiting_ktv.value,
    label: REQUEST_REPAIR_KEYS.waiting.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.technician_arriving.value,
    label: REQUEST_REPAIR_KEYS.ktv_receiving.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.quotation.value,
    label: REQUEST_REPAIR_KEYS.quotation.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.settlement.value,
    label: REQUEST_REPAIR_KEYS.settlement.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.completed.value,
    label: REQUEST_REPAIR_KEYS.completed.label,
  },
  {
    key: REQUEST_REPAIR_KEYS.cancel.value,
    label: REQUEST_REPAIR_KEYS.cancel.label,
  },
];

export default function ListRequestRepair() {
  const location = useLocation();
  const { user } = useAuth();
  const isAgency = useMemo(() => user?.type === PartnerTypeEnum.AGENCY, [user]);

  const [activeKey, setActiveKey] = useState<string>(REQUEST_REPAIR_KEYS.waiting.value);

  const tabs = useMemo(() => {
    if (isAgency) {
      return TABS_AGENCY;
    }
    return TABS_TECHNICIAN;
  }, [isAgency]);

  const onChangeTab = (tab: string) => {
    setActiveKey(tab);
  };

  useEffect(() => {
    if (location?.search) {
      const statusTabs = location?.search;
      const tabURL = statusTabs.slice(8);
      const isExistTab = tabs.find((tab) => tab.key === tabURL);
      if (isExistTab) {
        setActiveKey(isExistTab?.key);
        return;
      }
    }
    setActiveKey(tabs[0].key as BookingStatusEnum);
  }, [location, tabs]);

  return (
    <div className="content-rq">
      <div className="px-[24px] pt-[16px] bg-white">
        <BreadCrumb
          names={[AppRoutesName.home, AppRoutesName.requestRepair]}
          routesName={[AppRoutes.home, AppRoutes.requestRepair.index]}
        />
        <TitlePage>Yêu cầu sửa chữa</TitlePage>
        <Tabs items={tabs} activeKey={activeKey} onChange={onChangeTab} />
      </div>
      <div className="mx-[24px] my-[20px] p-[20px] bg-white">
        <TableRequest activeKey={activeKey} isAgency={isAgency} />
        <PopupRequestRepair />
      </div>
    </div>
  );
}
