import { memo } from 'react';

type Props = {
  userInfo: {
    name: string;
    avatar: string;
    technique?: string;
    phoneNumber?: string;
  };
};

export const UserCard = memo(({ userInfo }: Props) => {
  return (
    <div className="flex space-x-12px p-12px rounded border border-solid border-bright-gray">
      <img
        className="w-40px h-40px rounded-full border-2 border-solid border-white shadow-md"
        src={userInfo.avatar}
        alt="avatar"
      />
      <div className="flex flex-col grow">
        <span className="text-14px font-medium leading-20px">{userInfo.name}</span>
        {userInfo.technique && <span className="text-12px text-grayscale-gray leading-16px">{userInfo.technique}</span>}
        {userInfo.phoneNumber && (
          <span className="text-12px text-grayscale-gray leading-16px">{userInfo.phoneNumber}</span>
        )}
      </div>
    </div>
  );
});
