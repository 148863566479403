import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { PartnerFragmentFragmentDoc } from '../fragments/partner.fragment.generated';

const defaultOptions = {} as const;
export type MePartnerQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MePartnerQueryResponse = { __typename?: 'Query' } & {
  mePartner: { __typename?: 'PartnerEntity' } & Pick<
    Types.PartnerEntity,
    | 'description'
    | 'avatarId'
    | 'citizenId'
    | 'email'
    | 'fullname'
    | 'addressMoreInfo'
    | 'hotline'
    | 'id'
    | 'isActive'
    | 'phone'
    | 'type'
    | 'latitude'
    | 'longitude'
    | 'mapAddress'
    | 'isApproved'
    | 'bank'
    | 'birthday'
    | 'cardNumber'
    | 'parentId'
    | 'countTechnician'
    | 'countProduct'
    | 'star'
  > & {
      avatar?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'createdAt'
          | 'fileSize'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'id'
          | 'isDeleted'
          | 'mimeType'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
          | 'updatedAt'
        >
      >;
      qualifications?: Types.Maybe<
        Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>>
      >;
      education?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      level?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      reviewSummary?: Types.Maybe<{ __typename?: 'ReviewSummary' } & Pick<Types.ReviewSummary, 'total'>>;
      starInfo: Array<{ __typename?: 'StarInfo' } & Pick<Types.StarInfo, 'total'>>;
    };
};

export const MePartnerDocument = gql`
  query mePartner {
    mePartner {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export function useMePartnerQuery(
  baseOptions?: Apollo.QueryHookOptions<MePartnerQueryResponse, MePartnerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MePartnerQueryResponse, MePartnerQueryVariables>(MePartnerDocument, options);
}
export function useMePartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MePartnerQueryResponse, MePartnerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MePartnerQueryResponse, MePartnerQueryVariables>(MePartnerDocument, options);
}
export type MePartnerQueryHookResult = ReturnType<typeof useMePartnerQuery>;
export type MePartnerLazyQueryHookResult = ReturnType<typeof useMePartnerLazyQuery>;
export type MePartnerQueryResult = Apollo.QueryResult<MePartnerQueryResponse, MePartnerQueryVariables>;
