import { gql } from '@apollo/client';

import type * as Types from '../type.interface';

export type OrderFragmentFragment = { __typename?: 'OrderEntity' } & Pick<
  Types.OrderEntity,
  | 'code'
  | 'createdAt'
  | 'deletedAt'
  | 'id'
  | 'note'
  | 'partnerId'
  | 'shippingFee'
  | 'status'
  | 'total'
  | 'discount'
  | 'updatedAt'
  | 'userId'
> & {
    address?: Types.Maybe<
      { __typename?: 'OrderAddressEntity' } & Pick<Types.OrderAddressEntity, 'addressDetail' | 'mapAddress'>
    >;
    partner?: Types.Maybe<{ __typename?: 'OrderPartner' } & Pick<Types.OrderPartner, 'id'>>;
    product: Array<
      { __typename?: 'OrderProductEntity' } & Pick<
        Types.OrderProductEntity,
        | 'avatarId'
        | 'createdAt'
        | 'deletedAt'
        | 'descriptionImageIds'
        | 'id'
        | 'isNew'
        | 'name'
        | 'operatingNumber'
        | 'operatingUnit'
        | 'orderId'
        | 'ordinalNumber'
        | 'partNumber'
        | 'productId'
        | 'quantity'
        | 'serialNumber'
        | 'type'
        | 'unitPrice'
        | 'updatedAt'
        | 'vehicleRegistrationPlate'
        | 'vinNumber'
        | 'yearOfManufacture'
      > & { avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'id' | 'fullThumbUrl'>> }
    >;
    user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'id' | 'phone'> & {
        userAddress?: Types.Maybe<{ __typename?: 'AddressEntity' } & Pick<Types.AddressEntity, 'mapAddress'>>;
        avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'id' | 'fullThumbUrl'>>;
      };
    statusDetail?: Types.Maybe<
      { __typename?: 'OrderStatusEntity' } & Pick<
        Types.OrderStatusEntity,
        'createdAt' | 'partnerId' | 'userId' | 'note'
      > & {
          reasons?: Types.Maybe<
            Array<{ __typename?: 'OrderStatusCategoryEntity' } & Pick<Types.OrderStatusCategoryEntity, 'id' | 'name'>>
          >;
        }
    >;
  };

export const OrderFragmentFragmentDoc = gql`
  fragment OrderFragment on OrderEntity {
    address {
      addressDetail
      mapAddress
    }
    code
    createdAt
    deletedAt
    id
    note
    partner {
      id
    }
    partnerId
    product {
      avatarId
      createdAt
      deletedAt
      descriptionImageIds
      id
      isNew
      name
      operatingNumber
      operatingUnit
      orderId
      ordinalNumber
      partNumber
      productId
      quantity
      serialNumber
      type
      unitPrice
      updatedAt
      vehicleRegistrationPlate
      vinNumber
      yearOfManufacture
      avatar {
        id
        fullThumbUrl
      }
    }
    shippingFee
    status
    total
    discount
    updatedAt
    user {
      fullname
      id
      phone
      userAddress {
        mapAddress
      }
      avatar {
        id
        fullThumbUrl
      }
    }
    statusDetail {
      reasons {
        id
        name
      }
      createdAt
      partnerId
      userId
      note
    }
    userId
  }
`;
