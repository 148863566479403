import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CheckProductVinExistQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  vinNumber: Types.Scalars['String'];
}>;

export type CheckProductVinExistQueryResponse = { __typename?: 'Query' } & Pick<Types.Query, 'checkProductVinExist'>;

export const CheckProductVinExistDocument = gql`
  query checkProductVinExist($id: String, $vinNumber: String!) {
    checkProductVinExist(id: $id, vinNumber: $vinNumber)
  }
`;
export function useCheckProductVinExistQuery(
  baseOptions: Apollo.QueryHookOptions<CheckProductVinExistQueryResponse, CheckProductVinExistQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckProductVinExistQueryResponse, CheckProductVinExistQueryVariables>(
    CheckProductVinExistDocument,
    options,
  );
}
export function useCheckProductVinExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckProductVinExistQueryResponse, CheckProductVinExistQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckProductVinExistQueryResponse, CheckProductVinExistQueryVariables>(
    CheckProductVinExistDocument,
    options,
  );
}
export type CheckProductVinExistQueryHookResult = ReturnType<typeof useCheckProductVinExistQuery>;
export type CheckProductVinExistLazyQueryHookResult = ReturnType<typeof useCheckProductVinExistLazyQuery>;
export type CheckProductVinExistQueryResult = Apollo.QueryResult<
  CheckProductVinExistQueryResponse,
  CheckProductVinExistQueryVariables
>;
