import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AgencyCreateTechnicianMutationVariables = Types.Exact<{
  input: Types.AgencyCreateTechnicianInput;
}>;

export type AgencyCreateTechnicianMutationResponse = { __typename?: 'Mutation' } & {
  agencyCreateTechnician: { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'id' | 'fullname'>;
};

export const AgencyCreateTechnicianDocument = gql`
  mutation agencyCreateTechnician($input: AgencyCreateTechnicianInput!) {
    agencyCreateTechnician(input: $input) {
      id
      fullname
    }
  }
`;
export function useAgencyCreateTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyCreateTechnicianMutationResponse,
    AgencyCreateTechnicianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AgencyCreateTechnicianMutationResponse, AgencyCreateTechnicianMutationVariables>(
    AgencyCreateTechnicianDocument,
    options,
  );
}
export type AgencyCreateTechnicianMutationHookResult = ReturnType<typeof useAgencyCreateTechnicianMutation>;
export type AgencyCreateTechnicianMutationResult = Apollo.MutationResult<AgencyCreateTechnicianMutationResponse>;
export type AgencyCreateTechnicianMutationOptions = Apollo.BaseMutationOptions<
  AgencyCreateTechnicianMutationResponse,
  AgencyCreateTechnicianMutationVariables
>;
