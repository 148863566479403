import { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { useNavigate, useParams } from 'react-router-dom';

import { InputNumberFormat, SubHeader } from '../../components';
import { AppRoutes } from '../../helpers';
import { DiscountCodeUnitEnum, ProductEntity } from '../../graphql/type.interface';
import { DefaultPagination, FORMAT_DATE, validationMessage } from '../../constants';
import { usePartnerCreateDiscountCodeMutation } from '../../graphql/mutations/partnerCreateDiscountCode.generated';
import { showNotification } from '../../helpers/utils';
import { usePartnerGetDiscountCodeQuery } from '../../graphql/queries/partnerGetDiscountCode.generated';
import { AppButton } from '../../components/button';
import { usePartnerUpdateDiscountCodeMutation } from '../../graphql/mutations/partnerUpdateDiscountCode.generated';
import { useDialog } from '../../contexts';
import { usePartnerDeleteDiscountCodeMutation } from '../../graphql/mutations/partnerDeleteDiscountCode.generated';
import { usePartnerEndDiscountMutation } from '../../graphql/mutations/partnerEndDiscount.generated';

import { ModalSelectProduct } from './components/modal-select-product';

type Props = {
  isEdit?: boolean;
  isDetail?: boolean;
};
type FormData = {
  endDate?: Date;
  id?: string;
  isActive?: boolean;
  limit?: number;
  limitPerAccount?: number;
  minOrderValue: number;
  name: string;
  productIds?: string[];
  startDate: Date;
  unit: DiscountCodeUnitEnum;
  value: number;
};
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current <= dayjs();
};

export const PromitionForm = memo(({ isDetail = false, isEdit = false }: Props) => {
  const navigation = useNavigate();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const { id = '' } = useParams();
  const { showDialog } = useDialog();

  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const productIds = Form.useWatch('productIds', form);
  const startDate = Form.useWatch('startDate', form);
  const unit = Form.useWatch('unit', form);

  const { data, loading: getting } = usePartnerGetDiscountCodeQuery({
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });
  const discount = useMemo(() => data?.partnerGetDiscountCode, [data]);
  const [createDiscountMutation, { loading: creating }] = usePartnerCreateDiscountCodeMutation({
    onError(error) {
      showNotification('error', 'Thêm mã giảm giá thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Thêm mã giảm giá thành công');
      navigation(AppRoutes.promotions.list);
    },
  });

  const [updateDiscountMutation, { loading: updating }] = usePartnerUpdateDiscountCodeMutation({
    onError(error) {
      showNotification('error', 'Cập nhật mã giảm giá thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Cập nhật mã giảm giá thành công');
      navigation(AppRoutes.promotions.list);
    },
  });

  const [removeDiscountMutation, { loading: removing }] = usePartnerDeleteDiscountCodeMutation({
    onError(error) {
      showNotification('error', 'Xóa khuyến mãi giá thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Xóa khuyến mãi giá thành công');
      navigation(AppRoutes.promotions.list);
    },
  });

  const [endDiscountMutation, { loading: ending }] = usePartnerEndDiscountMutation({
    onCompleted() {
      showNotification('success', 'Kết thúc chương trình khuyến mãi thành công');
      navigation(AppRoutes.promotions.list);
    },
    onError(error) {
      showNotification('error', 'Kết thúc chương trình khuyến mãi thất bại', error?.message);
    },
  });

  const onFinish = useCallback(
    (values: FormData) => {
      // create
      if (!isDetail && !isEdit) {
        createDiscountMutation({ variables: { input: { ...values, isActive: true } } });
        return;
      }
      // update
      // check if startDate change => push
      if (!discount) return;
      if (dayjs(discount?.startDate).format(FORMAT_DATE) !== dayjs(startDate).format(FORMAT_DATE)) {
        updateDiscountMutation({ variables: { input: { ...values, id: discount?.id } } });
        return;
      }
      const { startDate: _startDate, ...withoutValues } = values;
      updateDiscountMutation({ variables: { input: { ...withoutValues, id: discount?.id } } });
    },
    [createDiscountMutation, discount, isDetail, isEdit, startDate, updateDiscountMutation],
  );

  const handleFinishSelectProducts = useCallback(
    (newProductIds: string[], newProducts: ProductEntity[]) => {
      form.setFieldValue('productIds', newProductIds);
      setProducts(newProducts);
      setOpen(false);
    },
    [form],
  );

  const handleRemoveProduct = useCallback(
    (id: string) => {
      const newProductIds = productIds.filter((item: string) => item !== id);
      form.setFieldsValue({ productIds: newProductIds });
      setProducts(products.filter((p) => p.id !== id));
    },
    [form, productIds, products],
  );

  const isLoading = useMemo(
    () => getting || getting || creating || updating || removing || ending,
    [creating, ending, getting, removing, updating],
  );

  useEffect(() => {
    if (discount) {
      form.setFieldsValue({
        ...discount,
        startDate: dayjs(discount?.startDate),
        endDate: discount?.endDate ? dayjs(discount?.endDate) : null,
        productIds: (discount?.products ?? []).map((it) => it?.id),
      });
      setProducts((discount?.products as ProductEntity[]) ?? []);
    } else {
      form.resetFields();
    }
  }, [form, discount]);

  useEffect(() => {
    setEditing(isEdit);
  }, [isEdit]);

  return (
    <Spin spinning={isLoading}>
      <SubHeader
        items={[
          {
            title: 'Trang chủ',
            to: AppRoutes.home,
          },
          {
            title: 'Khuyến mãi',
            to: AppRoutes.promotions.list,
          },
          {
            title: !isDetail && !isEdit ? 'Thêm mới khuyến mãi' : (discount?.name as string),
          },
        ]}
        rightContent={
          !editing &&
          discount && (
            <div>
              <AppButton
                type="_D63120"
                className="mr-12px"
                onClick={() =>
                  showDialog({
                    content: discount?.name,
                    title: `Bạn có chắc chắn muốn xóa chương trình khuyến mại này không?`,
                    confirmText: 'Xóa',
                    onOK: () => removeDiscountMutation({ variables: { input: { id: discount?.id as string } } }),
                  })
                }
              >
                Xóa
              </AppButton>
              {((dayjs(discount?.startDate).isBefore(dayjs()) && !discount?.endDate) ||
                dayjs(discount?.endDate).isAfter(dayjs())) && (
                <AppButton
                  type="_D63120"
                  className="mr-12px"
                  onClick={() =>
                    showDialog({
                      content: discount?.name,
                      title: `Bạn chắc chắn muốn kết thúc chương trình khuyến mãi này?`,
                      confirmText: 'Kết thúc',
                      onOK: () => endDiscountMutation({ variables: { id: discount?.id as string } }),
                    })
                  }
                >
                  Kết thúc
                </AppButton>
              )}
              <Button type="primary" onClick={() => setEditing(true)}>
                Chỉnh sửa
              </Button>
            </div>
          )
        }
      />
      <Form
        form={form}
        name="form-promotion"
        id="form-promotion"
        layout="vertical"
        disabled={editing ? !editing : isDetail}
        className="max-w-[868px] bg-white mx-auto mt-20px p-20px mb-[80px]"
        initialValues={{
          unit: DiscountCodeUnitEnum.PERCENTAGE,
          startDate: dayjs(),
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <span>
              Tên chương trình <span className="text-grayscale-light font-light">(không hiển thị với khách hàng)</span>
            </span>
          }
          name="name"
          normalize={(e) => e.trimStart()}
          rules={[{ required: true, message: validationMessage?.required }]}
        >
          <Input maxLength={255} placeholder="Nhập tên chương trình" />
        </Form.Item>
        <Form.Item
          name="productIds"
          label={
            <span>
              <span className="text-error">* </span> Sản phẩm áp dụng
            </span>
          }
        >
          {productIds && productIds.length > 0 ? (
            <Table
              dataSource={products}
              pagination={{
                ...DefaultPagination,
              }}
              bordered={true}
              columns={[
                {
                  title: 'Mã sản phẩm',
                  dataIndex: 'id',
                  key: 'id',
                },
                {
                  title: 'Tên sản phẩm',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Tùy chọn',
                  dataIndex: 'id',
                  key: 'action',
                  render(id) {
                    return (
                      <span className="text-error hover:cursor-pointer" onClick={() => handleRemoveProduct(id)}>
                        Bỏ chọn
                      </span>
                    );
                  },
                },
              ]}
            />
          ) : (
            <span>Áp dụng cho tất cả sản phẩm</span>
          )}
        </Form.Item>
        <div className="w-full flex justify-end">
          <Button type="primary" onClick={() => setOpen(true)}>
            Chọn sản phẩm
          </Button>
        </div>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Ngày bắt đầu"
              name="startDate"
              rules={[{ required: true, message: validationMessage?.required }]}
            >
              <DatePicker className="w-full" format={'DD/MM/YYYY'} disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Ngày kết thúc'} name="endDate">
              <DatePicker
                className="w-full"
                format={'DD/MM/YYYY'}
                disabledDate={(current) => current && current <= dayjs(startDate)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item name="unit" label="Giá trị giảm">
              <Select
                className="w-full"
                options={[
                  {
                    label: '%',
                    value: DiscountCodeUnitEnum.PERCENTAGE,
                  },
                  {
                    label: 'vnđ',
                    value: DiscountCodeUnitEnum.VND,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mức giảm" name="value" rules={[{ required: true, message: validationMessage?.required }]}>
              <InputNumberFormat
                className="w-full"
                suffix={unit === DiscountCodeUnitEnum.PERCENTAGE ? '%' : 'đ'}
                max={unit === DiscountCodeUnitEnum.PERCENTAGE ? 100 : undefined}
                min={1}
                placeholder="Nhập Mức giảm"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item label={'Giới hạn tổng số lượt sử dụng'} name="limit">
              <InputNumberFormat min={1} className="w-full" placeholder={`Nhập giới hạn tổng số lượt sử dụng.`} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={'Giới hạn số lượt sử dụng tài khoản'} name="limitPerAccount">
              <InputNumberFormat min={1} className="w-full" placeholder={`Nhập giới hạn số lượt sử dụng tài khoản.`} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Giá trị đơn hàng tối thiểu"
              name="minOrderValue"
              rules={[{ required: true, message: validationMessage?.required }]}
            >
              <InputNumberFormat
                className="w-full"
                placeholder={`Nhập giá trị đơn hàng tối thiểu.`}
                min={1}
                suffix="đ"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {(editing || (!isDetail && !isEdit)) && (
        <div className="flex justify-end items-center fixed left-0 right-0 bottom-0 pr-[24px] py-8px bg-white">
          <Button
            className="mr-16px"
            type="default"
            loading={isLoading}
            onClick={() => {
              if (isEdit || isDetail) {
                setEditing(false);
                return;
              }
              navigation(-1);
            }}
          >
            Huỷ
          </Button>
          <Button type="primary" htmlType="submit" form="form-promotion" disabled={isLoading} loading={isLoading}>
            Lưu
          </Button>
        </div>
      )}
      {open && (
        <ModalSelectProduct
          open={open}
          setOpen={setOpen}
          onFinish={handleFinishSelectProducts}
          productIds={productIds}
          products={products}
        />
      )}
    </Spin>
  );
});
