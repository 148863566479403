import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type TechnicianGetSettlementsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  technicianId: Types.Scalars['String'];
}>;

export type TechnicianGetSettlementsQueryResponse = { __typename?: 'Query' } & {
  technicianGetSettlements: { __typename?: 'SettlementConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'SettlementEntity' } & Pick<
          Types.SettlementEntity,
          | 'bookingId'
          | 'createdAt'
          | 'deletedAt'
          | 'discount'
          | 'id'
          | 'invoiceId'
          | 'quotationId'
          | 'status'
          | 'total'
          | 'updatedAt'
          | 'vatTax'
        > & {
            booking: { __typename?: 'BookingEntity' } & Pick<Types.BookingEntity, 'id' | 'status'>;
            user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'phone'> & {
                avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'fullOriginalUrl'>>;
              };
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const TechnicianGetSettlementsDocument = gql`
  query technicianGetSettlements(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $technicianId: String!
  ) {
    technicianGetSettlements(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      technicianId: $technicianId
    ) {
      items {
        booking {
          id
          status
        }
        bookingId
        createdAt
        deletedAt
        discount
        id
        invoiceId
        quotationId
        status
        total
        updatedAt
        vatTax
        user {
          fullname
          phone
          avatar {
            fullThumbUrl
            fullOriginalUrl
          }
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useTechnicianGetSettlementsQuery(
  baseOptions: Apollo.QueryHookOptions<TechnicianGetSettlementsQueryResponse, TechnicianGetSettlementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TechnicianGetSettlementsQueryResponse, TechnicianGetSettlementsQueryVariables>(
    TechnicianGetSettlementsDocument,
    options,
  );
}
export function useTechnicianGetSettlementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TechnicianGetSettlementsQueryResponse,
    TechnicianGetSettlementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TechnicianGetSettlementsQueryResponse, TechnicianGetSettlementsQueryVariables>(
    TechnicianGetSettlementsDocument,
    options,
  );
}
export type TechnicianGetSettlementsQueryHookResult = ReturnType<typeof useTechnicianGetSettlementsQuery>;
export type TechnicianGetSettlementsLazyQueryHookResult = ReturnType<typeof useTechnicianGetSettlementsLazyQuery>;
export type TechnicianGetSettlementsQueryResult = Apollo.QueryResult<
  TechnicianGetSettlementsQueryResponse,
  TechnicianGetSettlementsQueryVariables
>;
