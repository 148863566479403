import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AgencyUpdateTechnicianMutationVariables = Types.Exact<{
  input: Types.AgencyUpdateTechnicianInput;
}>;

export type AgencyUpdateTechnicianMutationResponse = { __typename?: 'Mutation' } & {
  agencyUpdateTechnician: { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'id' | 'fullname'>;
};

export const AgencyUpdateTechnicianDocument = gql`
  mutation agencyUpdateTechnician($input: AgencyUpdateTechnicianInput!) {
    agencyUpdateTechnician(input: $input) {
      id
      fullname
    }
  }
`;
export function useAgencyUpdateTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyUpdateTechnicianMutationResponse,
    AgencyUpdateTechnicianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AgencyUpdateTechnicianMutationResponse, AgencyUpdateTechnicianMutationVariables>(
    AgencyUpdateTechnicianDocument,
    options,
  );
}
export type AgencyUpdateTechnicianMutationHookResult = ReturnType<typeof useAgencyUpdateTechnicianMutation>;
export type AgencyUpdateTechnicianMutationResult = Apollo.MutationResult<AgencyUpdateTechnicianMutationResponse>;
export type AgencyUpdateTechnicianMutationOptions = Apollo.BaseMutationOptions<
  AgencyUpdateTechnicianMutationResponse,
  AgencyUpdateTechnicianMutationVariables
>;
