import { memo, useMemo, useState, useCallback } from 'react';
import { Button, notification } from 'antd';
import { CountdownProps } from 'antd/es/statistic/Countdown';

import { CloseIcon, LocationIcon, StarIcon } from '../../../assets/icon';
import '../style.less';
import { useOnCreateBookingSubscription } from '../../../graphql/subscriptions/onCreateBooking.generated';
import { PartnerBookingDocument, usePartnerBookingLazyQuery } from '../../../graphql/queries/partnerBooking.generated';
import { numberWithDots } from '../../../helpers/utils';
import { useAuth } from '../../../contexts';
import { PartnerTypeEnum } from '../../../graphql/type.interface';
import { AppButton } from '../../../components/button';
import { useTechnicianArrivingBookingMutation } from '../../../graphql/mutations/technicianArrivingBooking.generated';
import { PartnerBookingsDocument } from '../../../graphql/queries/partnerBookings.generated';

import ModalGroup from './modal/modal-group';
import { TYPE_MODAL } from './table';

export const PopupRequestRepair = memo(() => {
  const { user } = useAuth();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string | undefined>();

  const [getBookingApi, { data: resBooking }] = usePartnerBookingLazyQuery({
    onCompleted(data) {
      if (data?.partnerBooking?.id !== booking?.id) {
        setOpenPopup(true);
      }
    },
  });
  const booking = useMemo(() => resBooking?.partnerBooking, [resBooking]);

  useOnCreateBookingSubscription({
    async onData({ data }) {
      const id = data?.data?.onCreateBooking?.id;
      if (id) {
        await getBookingApi({ variables: { id } });
      }
    },
  });

  const [technicianArrivingApi, { loading: arriving }] = useTechnicianArrivingBookingMutation({
    onError(error) {
      notification.error({
        message: 'Nhận yêu cầu thất bại',
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: 'Nhận yêu cầu thành công',
      });
      setOpenPopup(false);
    },
  });

  const handleOpenModal = useCallback((type: string) => {
    setTypeModal(type);
  }, []);

  const handleHiddenPopup: CountdownProps['onFinish'] = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const technicianArriving = useCallback(() => {
    if (!booking) return;
    technicianArrivingApi({
      variables: { input: { bookingId: booking.id } },
      refetchQueries: [PartnerBookingDocument, PartnerBookingsDocument],
    });
  }, [booking, technicianArrivingApi]);

  if (!booking || !openPopup) return null;
  if (openPopup)
    return (
      <div className="snackbar-repair w-[480px] h-fit bg-[#202C38] rounded p-20px text-white relative z-10">
        <div
          className="absolute top-3 right-3 w-7 h-7 rounded-full cursor-pointer bg-white/20 hover:bg-light-gray grid place-items-center"
          onClick={() => setOpenPopup(false)}
        >
          <CloseIcon />
        </div>
        <div className="flex items-center space-x-6">
          <div className="flex flex-col justify-center font-normal border-r-2 border-[#EEEEEE]">
            <span className="text-xs">Yêu cầu sửa chữa</span>
            <span className="text-14px font-semibold">
              {booking?.transportDistance} KM - {numberWithDots(booking?.transportFee)} đ
            </span>
          </div>
          <div className="h-40px w-[1px] bg-[#EEEEEE]/20"></div>
          <div className="flex-center space-x-3">
            <div className="relative">
              <img
                className="h-36px aspect-square border-2 border-solid rounded-full"
                src={booking?.user.avatar ? (booking?.user?.avatar?.fullThumbUrl as string) : ''}
                alt="avatar"
              />
              <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-1 w-[40px] h-4 bg-white flex-center space-x-1 rounded-full">
                <StarIcon />
                <span className="text-[#202C38] text-[11px] font-semibold ">5.0</span>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-13px font-medium leading-18px">{booking?.user?.fullname}</span>
              <span className="text-xs">{booking?.user?.phone}</span>
            </div>
          </div>
        </div>
        <div className="p-3 flex items-center space-x-4 my-20px bg-[#293847] rounded">
          <img
            className="w-[40px] h-[40px] rounded"
            src={booking?.vehicle?.avatar ? (booking?.vehicle?.avatar?.fullThumbUrl as string) : ''}
            alt="Hình ảnh máy cần sửa chữa"
          />
          <div>
            <span className="text-14px font-medium leading-[20px]">{booking?.vehicle?.name}</span>
            <span className="flex items-center space-x-2">
              <LocationIcon />
              <span className="text-13px leading-[18px]">{booking?.mapAddress}</span>
            </span>
          </div>
        </div>
        {/* <Countdown
            title=" "
            value={timeout}
            onFinish={onFinish}
            format="mm:ss"
            valueStyle={{ color: '#fff', fontSize: 24, fontWeight: 600 }}
          /> */}
        <div className="flex justify-end">
          <AppButton
            size="small"
            type="_D63120"
            disabled={arriving}
            onClick={() => handleOpenModal(TYPE_MODAL.CANCEL_REPAIR)}
          >
            Từ chối
          </AppButton>
          {user?.type === PartnerTypeEnum.AGENCY && (
            <Button
              onClick={() => handleOpenModal(TYPE_MODAL.ASSIGN_TECHNICIAN)}
              className="w-[126px] h-[40px] flex-center text-13px ml-12px"
              type="primary"
            >
              Nhận yêu cầu
            </Button>
          )}
          {(user?.type === PartnerTypeEnum.TECHNICIAN || user?.type === PartnerTypeEnum.FREELANCER_TECHNICIAN) && (
            <AppButton
              type="_03A1FA"
              size="small"
              className="mx-[12px]"
              disabled={arriving}
              onClick={() => handleOpenModal(TYPE_MODAL.RESCHEDULE)}
            >
              Hẹn ngày đến
            </AppButton>
          )}
          {(user?.type === PartnerTypeEnum.TECHNICIAN || user?.type === PartnerTypeEnum.FREELANCER_TECHNICIAN) && (
            <Button type="primary" size="small" onClick={technicianArriving} disabled={arriving}>
              Nhận yêu cầu và đến ngay
            </Button>
          )}
        </div>
        {typeModal && (
          <ModalGroup
            bookingId={booking?.id}
            type_modal={typeModal}
            setTypeModal={setTypeModal}
            onCompleted={handleHiddenPopup}
          />
        )}
      </div>
    );
  return null;
});
