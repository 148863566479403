import { Spin } from 'antd';
import { memo } from 'react';

type Props = {
  top?: number;
};

export const FullscreenLoading = memo(({ top = 200 }: Props) => {
  return (
    <div className="flex-center" style={{ paddingTop: top, paddingBottom: top }}>
      <Spin />
    </div>
  );
});
