import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type PartnerGetDiscountCodeQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type PartnerGetDiscountCodeQueryResponse = { __typename?: 'Query' } & {
  partnerGetDiscountCode: { __typename?: 'DiscountCodeEntity' } & Pick<
    Types.DiscountCodeEntity,
    | 'createdAt'
    | 'deletedAt'
    | 'endDate'
    | 'id'
    | 'isActive'
    | 'limit'
    | 'limitPerAccount'
    | 'minOrderValue'
    | 'name'
    | 'startDate'
    | 'unit'
    | 'updatedAt'
    | 'value'
  > & {
      products?: Types.Maybe<
        Array<
          { __typename?: 'ProductEntity' } & Pick<
            Types.ProductEntity,
            | 'avatarId'
            | 'createdAt'
            | 'deletedAt'
            | 'detail'
            | 'id'
            | 'isActive'
            | 'isFixedCost'
            | 'isNew'
            | 'name'
            | 'operatingNumber'
            | 'operatingUnit'
            | 'ordinalNumber'
            | 'partnerId'
            | 'quantity'
            | 'serialNumber'
            | 'type'
            | 'unitPrice'
            | 'updatedAt'
            | 'vehicleRegistrationPlate'
            | 'vinNumber'
            | 'yearOfManufacture'
          >
        >
      >;
    };
};

export const PartnerGetDiscountCodeDocument = gql`
  query partnerGetDiscountCode($id: String!) {
    partnerGetDiscountCode(id: $id) {
      createdAt
      deletedAt
      endDate
      id
      isActive
      limit
      limitPerAccount
      minOrderValue
      name
      products {
        avatarId
        createdAt
        deletedAt
        detail
        id
        isActive
        isFixedCost
        isNew
        name
        operatingNumber
        operatingUnit
        ordinalNumber
        partnerId
        quantity
        serialNumber
        type
        unitPrice
        updatedAt
        vehicleRegistrationPlate
        vinNumber
        yearOfManufacture
      }
      startDate
      unit
      updatedAt
      value
    }
  }
`;
export function usePartnerGetDiscountCodeQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerGetDiscountCodeQueryResponse, PartnerGetDiscountCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerGetDiscountCodeQueryResponse, PartnerGetDiscountCodeQueryVariables>(
    PartnerGetDiscountCodeDocument,
    options,
  );
}
export function usePartnerGetDiscountCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerGetDiscountCodeQueryResponse, PartnerGetDiscountCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerGetDiscountCodeQueryResponse, PartnerGetDiscountCodeQueryVariables>(
    PartnerGetDiscountCodeDocument,
    options,
  );
}
export type PartnerGetDiscountCodeQueryHookResult = ReturnType<typeof usePartnerGetDiscountCodeQuery>;
export type PartnerGetDiscountCodeLazyQueryHookResult = ReturnType<typeof usePartnerGetDiscountCodeLazyQuery>;
export type PartnerGetDiscountCodeQueryResult = Apollo.QueryResult<
  PartnerGetDiscountCodeQueryResponse,
  PartnerGetDiscountCodeQueryVariables
>;
