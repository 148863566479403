import { RouterProvider } from 'react-router-dom';
import { memo } from 'react';

import { useRouter } from './helpers';

export const AppRouter = memo(() => {
  const router = useRouter();

  return <RouterProvider router={router} />;
});
