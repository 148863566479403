import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Spin, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { useCategoriesQuery } from '../../../../graphql/queries/categories.generated';
import { CategoryTypeEnum, StatusEnum } from '../../../../graphql/type.interface';
import '../../style.less';
import { ModalCustomize } from '../../../../components';
import { usePartnerCancelBookingMutation } from '../../../../graphql/mutations/partnerCancelBooking.generated';
import { PartnerBookingDocument } from '../../../../graphql/queries/partnerBooking.generated';
import { PartnerBookingsDocument } from '../../../../graphql/queries/partnerBookings.generated';

type Props = {
  bookingId?: string;
  open: boolean;
  onCancel: () => void;
  onOk?: () => void;
};

export const CancelRepair = memo((props: Props) => {
  const { data, loading } = useCategoriesQuery({
    variables: { isActive: StatusEnum.ACTIVE, limit: 1000, type: CategoryTypeEnum.CANCEL_REASON_BY_PARTNER },
    fetchPolicy: 'cache-and-network',
  });
  const reasons = useMemo(() => data?.categories.items, [data]);

  const [cancelRequest, { loading: canceling }] = usePartnerCancelBookingMutation({
    onError(error) {
      notification.error({ message: 'Hủy YCSC thất bại', description: error?.message });
    },
    onCompleted() {
      notification.success({ message: 'Hủy YCSC thành công' });
      props.onOk?.();
    },
  });

  const [selectedReason, setSelectedReason] = useState<string[]>([]);
  const [note, setNote] = useState<string>('');

  useEffect(() => {
    if (reasons && reasons?.length >= 0) {
      const firstItem = reasons[0];
      if (firstItem) {
        setSelectedReason([firstItem.id]);
      }
    }
  }, [reasons]);

  const handleSelected = useCallback(
    (id: string) => {
      const exist = selectedReason.includes(id);

      if (!exist) {
        setSelectedReason([...selectedReason, id]);
        return;
      }
      setSelectedReason(selectedReason.filter((i) => i !== id));
    },
    [selectedReason],
  );

  const handleCancelByPartner = useCallback(
    async ({ note = '', reasons }: { note?: string; reasons: string[] }) => {
      if (props.bookingId)
        await cancelRequest({
          variables: {
            input: {
              bookingId: props.bookingId,
              reasons,
              note,
            },
          },
          refetchQueries: [PartnerBookingDocument, PartnerBookingsDocument],
        });
      props.onCancel();
    },
    [cancelRequest, props],
  );

  const handleChangeNote = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value), []);

  return (
    <ModalCustomize
      title="Chọn lý do từ chối"
      okText="Xác nhận từ chối"
      okButtonProps={{ disabled: selectedReason.length <= 0 }}
      {...props}
      onOk={() => handleCancelByPartner({ note, reasons: selectedReason })}
    >
      <Spin spinning={loading || canceling}>
        <div className="h-[440px] overflow-y-auto text-14px leading-18px ">
          <span className="text-[#676E72]">
            Từ chối yêu cầu quá nhiều lần có thể ảnh hưởng đến độ uy tín của Đại lý bạn.
          </span>
          {(reasons || []).map((reason, key: number) => (
            <div
              onClick={() => handleSelected(reason.id)}
              className={`px-16px py-12px mt-16px border border-[#EEEEEE] border-solid rounded mb-12px last:mb-0 cursor-pointer ${
                selectedReason.includes(reason.id) ? 'selected-reason' : ''
              }`}
              key={key}
            >
              {reason.name}
            </div>
          ))}
          <TextArea placeholder="Nhập lý do khác" value={note} maxLength={255} onChange={handleChangeNote} showCount />
        </div>
      </Spin>
    </ModalCustomize>
  );
});
