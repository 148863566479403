import { Avatar, Divider, Spin, Tabs } from 'antd';
import { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { CancelOrder, FormDataCancel, MasterTable, SubHeader } from '../../components';
import { FORMAT_TIME } from '../../constants';
import { OrderEmpty } from '../../assets/icon';
import { PartnerOrdersQueryVariables, usePartnerOrdersQuery } from '../../graphql/queries/partnerOrders.generated';
import { CategoryTypeEnum, OrderEntity, OrderStatusEnum } from '../../graphql/type.interface';
import { AppRoutes } from '../../helpers';
import { numberWithDots, showNotification } from '../../helpers/utils';
import { usePartnerCancelOrderMutation } from '../../graphql/mutations/partnerCancelOrder.generated';
import { usePartnerUpdateOrderStatusMutation } from '../../graphql/mutations/partnerUpdateOrderStatus.generated';

import { FormDataFilter } from './type';
import OrderFormFilter from './components/form-filter';

export const ORDER_ROUTERS = {
  waiting: {
    label: 'Chờ xác nhận',
    value: OrderStatusEnum.WAIT_FOR_CONFIRM,
  },
  shipping: {
    label: 'Đang giao',
    value: OrderStatusEnum.SHIPPING,
  },
  delivered: {
    label: 'Đã giao',
    value: OrderStatusEnum.DELIVERED,
  },
  complete: {
    label: 'Hoàn thành',
    value: OrderStatusEnum.COMPLETE,
  },
  cancel: {
    label: 'Đơn huỷ',
    value: OrderStatusEnum.CANCEL,
  },
};

const ORDER_TABS = [
  {
    label: ORDER_ROUTERS.waiting.label,
    key: ORDER_ROUTERS.waiting.value,
  },
  {
    label: ORDER_ROUTERS.shipping.label,
    key: ORDER_ROUTERS.shipping.value,
  },
  {
    label: ORDER_ROUTERS.delivered.label,
    key: ORDER_ROUTERS.delivered.value,
  },
  {
    label: ORDER_ROUTERS.complete.label,
    key: ORDER_ROUTERS.complete.value,
  },
  {
    label: ORDER_ROUTERS.cancel.label,
    key: ORDER_ROUTERS.cancel.value,
  },
];

const Order = memo(() => {
  const navigator = useNavigate();
  const location = useLocation();
  const [_, setParams] = useSearchParams();

  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [cancelOrderData, setCancelOrderData] = useState<OrderEntity | null>(null);

  const [activeTab, setActiveTab] = useState(ORDER_ROUTERS.waiting.value);

  const [filter, setFilter] = useState<PartnerOrdersQueryVariables>({
    limit: 10,
    page: 1,
    statuses: [activeTab],
  });

  const {
    data: dataOrders,
    loading,
    refetch,
  } = usePartnerOrdersQuery({ variables: filter, fetchPolicy: 'cache-and-network' });
  const orders = useMemo(() => dataOrders?.partnerOrders?.items, [dataOrders]);

  const handleFilter = useCallback(
    (values: FormDataFilter) => {
      const { time, search } = values;
      setFilter({ ...filter, search, startDate: time?.[0], endDate: time?.[1], page: 1 });
    },
    [filter],
  );

  const [cancelOrderMutation, { loading: canceling }] = usePartnerCancelOrderMutation({
    onError(error) {
      showNotification('error', 'Hủy đơn hàng thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', `Hủy đơn hàng thành công ${cancelOrderData?.code}`);
      refetch();
      setShowCancelOrder(false);
      setCancelOrderData(null);
    },
  });

  const handleCancelOrder = useCallback(
    (values: FormDataCancel) => {
      if (!cancelOrderData) return;
      const input = {
        orderId: cancelOrderData.id,
        ...values,
      };
      cancelOrderMutation({ variables: { input } });
    },
    [cancelOrderData, cancelOrderMutation],
  );

  const [updateStatusOrder, { loading: updating }] = usePartnerUpdateOrderStatusMutation({
    onError(error) {
      showNotification('error', 'Đã có lỗi xảy ra ', error?.message);
    },
    onCompleted() {
      refetch();
    },
  });

  const handleChangeStatusToShipping = useCallback(
    (record: OrderEntity) => {
      updateStatusOrder({
        variables: {
          input: {
            orderId: record?.id,
            status: OrderStatusEnum.SHIPPING,
          },
        },
      }).then(() => {
        showNotification('success', `Đơn hàng ${record?.code} đã chuyển sang trạng thái đang giao`);
      });
    },
    [updateStatusOrder],
  );

  const handleChangeStatusToShiped = useCallback(
    (record: OrderEntity) => {
      updateStatusOrder({
        variables: {
          input: {
            orderId: record?.id,
            status: OrderStatusEnum.DELIVERED,
          },
        },
      }).then(() => {
        showNotification('success', `Đơn hàng ${record?.code} đã chuyển sang trạng thái đã giao`);
      });
    },
    [updateStatusOrder],
  );

  const handleChangeStatusToCompleted = useCallback(
    (record: OrderEntity) => {
      updateStatusOrder({
        variables: {
          input: {
            orderId: record?.id,
            status: OrderStatusEnum.COMPLETE,
          },
        },
      }).then(() => {
        showNotification('success', `Đơn hàng ${record?.code} đã chuyển sang trạng thái hoàn thành`);
      });
    },
    [updateStatusOrder],
  );

  const defaultColumns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Mã đơn hàng',
        key: 'code',
        dataIndex: 'code',
        width: '10%',
      },
      {
        title: 'Sản phẩm',
        key: 'product',
        dataIndex: 'product',
        width: '30%',
        render: (products) => {
          return (products ?? []).map((it: any, idx: number) => (
            <div className="mb-8px" key={idx}>
              {it?.avatar?.fullThumbUrl && <Avatar shape="square" src={it?.avatar?.fullThumbUrl} />}
              <span className="pl-12px text-14px font-medium leading-20px">{it.name}</span>
            </div>
          ));
        },
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'product',
        align: 'right',
        width: '5%',
        render: (products) => {
          return (products ?? []).map((it: any) => (
            <div className="flex items-center justify-end w-full h-[40px] mb-8px" key={'quantity' + it?.id}>
              <span className="pl-12px text-14px font-medium leading-20px">{it.quantity}</span>
            </div>
          ));
        },
      },
      {
        title: 'Đơn giá',
        key: 'unitPrice',
        dataIndex: 'product',
        align: 'right',
        width: '15%',
        render: (products) => {
          return (products ?? []).map((it: any) => (
            <div className="flex items-center justify-end w-full h-[40px] mb-8px" key={'unitPrice' + it?.id}>
              <span className="pl-12px text-14px font-medium leading-20px">{numberWithDots(it?.unitPrice) + ' đ'}</span>
            </div>
          ));
        },
      },
      {
        title: 'Tổng cộng',
        key: 'total',
        dataIndex: 'total',
        align: 'right',
        width: '15%',
        render: (total) => <span className="font-semibold">{numberWithDots(total) + 'đ'}</span>,
      },
      {
        title: 'Thời gian đặt',
        key: 'createdAt',
        dataIndex: 'createdAt',
        align: 'center',
        width: '10%',
        render: (_createdAt) => dayjs(_createdAt).format(FORMAT_TIME),
      },
      {
        title: 'Tùy chọn',
        dataIndex: 'status',
        key: 'action',
        align: 'right',
        width: '10%',
        render: (status, _record) => {
          switch (status) {
            case OrderStatusEnum.WAIT_FOR_CONFIRM:
              return (
                <div>
                  <span
                    className="text-error hover:cursor-pointer"
                    onClick={() => {
                      setCancelOrderData(_record);
                      setShowCancelOrder(true);
                    }}
                  >
                    Hủy
                  </span>
                  <Divider type="vertical" />
                  <span
                    className="text-blue hover:cursor-pointer"
                    onClick={() => handleChangeStatusToShipping(_record)}
                  >
                    Xác Nhận
                  </span>
                  <Divider type="vertical" />
                  <p
                    className="text-primary hover:cursor-pointer"
                    onClick={() => navigator(AppRoutes.orders.detailId(_record?.id))}
                  >
                    Xem chi tiết
                  </p>
                </div>
              );
            case OrderStatusEnum.SHIPPING:
              return (
                <div>
                  <span className="text-blue hover:cursor-pointer" onClick={() => handleChangeStatusToShiped(_record)}>
                    Đã giao hàng
                  </span>
                  <Divider type="vertical" />
                  <p
                    className="text-primary hover:cursor-pointer"
                    onClick={() => navigator(AppRoutes.orders.detailId(_record?.id))}
                  >
                    Xem chi tiết
                  </p>
                </div>
              );
            case OrderStatusEnum.DELIVERED:
              return (
                <div>
                  <span
                    className="text-blue hover:cursor-pointer"
                    onClick={() => handleChangeStatusToCompleted(_record)}
                  >
                    Hoàn thành
                  </span>
                  <Divider type="vertical" />
                  <p
                    className="text-primary hover:cursor-pointer"
                    onClick={() => navigator(AppRoutes.orders.detailId(_record?.id))}
                  >
                    Xem chi tiết
                  </p>
                </div>
              );
            default:
              return (
                <p
                  className="text-primary hover:cursor-pointer"
                  onClick={() => navigator(AppRoutes.orders.detailId(_record?.id))}
                >
                  Xem chi tiết
                </p>
              );
          }
        },
      },
    ],
    [handleChangeStatusToCompleted, handleChangeStatusToShiped, handleChangeStatusToShipping, navigator],
  );

  const [columns, setColumns] = useState(defaultColumns);
  const handleChangeTabs = useCallback(
    (key: string) => {
      setActiveTab(key as OrderStatusEnum);
      setParams((p) => {
        p.set('status', key);
        return p;
      });
      setFilter({ ...filter, statuses: [key as OrderStatusEnum], page: 1 });
      switch (key) {
        case ORDER_ROUTERS.waiting.value:
        case ORDER_ROUTERS.shipping.value:
        case ORDER_ROUTERS.delivered.value:
          setColumns(defaultColumns);
          break;
        case ORDER_ROUTERS.complete.value:
          setColumns([
            {
              title: 'Mã đơn hàng',
              key: 'code',
              dataIndex: 'code',
              width: '10%',
            },
            {
              title: 'Sản phẩm',
              key: 'product',
              dataIndex: 'product',
              width: '30%',
              render: (products) => {
                return (products ?? []).map((it: any, idx: number) => (
                  <div className="mb-8px" key={idx}>
                    {it?.avatar?.fullThumbUrl && <Avatar shape="square" src={it?.avatar?.fullThumbUrl} />}
                    <span className="pl-12px text-14px font-medium leading-20px">{it.name}</span>
                  </div>
                ));
              },
            },
            {
              title: 'Số lượng',
              key: 'quantity',
              dataIndex: 'product',
              align: 'right',
              width: '5%',
              render: (products) => {
                return (products ?? []).map((it: any) => (
                  <div className="flex items-center justify-end w-full h-[40px] mb-8px" key={'quantity' + it?.id}>
                    <span className="pl-12px text-14px font-medium leading-20px">{it.quantity}</span>
                  </div>
                ));
              },
            },
            {
              title: 'Đơn giá',
              key: 'unitPrice',
              dataIndex: 'product',
              align: 'right',
              width: '10%',
              render: (products) => {
                return (products ?? []).map((it: any) => (
                  <div className="flex items-center justify-end w-full h-[40px] mb-8px" key={'unitPrice' + it?.id}>
                    <span className="pl-12px text-14px font-medium leading-20px">
                      {numberWithDots(it?.unitPrice) + ' đ'}
                    </span>
                  </div>
                ));
              },
            },
            {
              title: 'Tổng cộng',
              key: 'total',
              dataIndex: 'total',
              align: 'right',
              width: '13%',
              render: (total) => numberWithDots(total) + 'đ',
            },
            {
              title: 'Thời gian đặt',
              key: 'createdAt',
              dataIndex: 'createdAt',
              align: 'center',
              width: '10%',
              render: (_createdAt) => dayjs(_createdAt).format(FORMAT_TIME),
            },
            {
              title: 'Thời gian hoàn thành',
              key: 'statusDetail',
              dataIndex: 'statusDetail',
              width: '12%',
              render: (statusDetail) => dayjs(statusDetail?.createdAt).format(FORMAT_TIME),
            },
            {
              title: 'Tùy chọn',
              dataIndex: 'id',
              key: 'action',
              align: 'right',
              width: '7%',
              render: (_id, _record) => {
                return (
                  <p
                    className="text-primary hover:cursor-pointer"
                    onClick={() => navigator(AppRoutes.orders.detailId(_id))}
                  >
                    Xem chi tiết
                  </p>
                );
              },
            },
          ]);
          break;
        default:
          setColumns([
            {
              title: 'Mã đơn hàng',
              key: 'code',
              dataIndex: 'code',
              width: '10%',
            },
            {
              title: 'Sản phẩm',
              key: 'product',
              dataIndex: 'product',
              width: '30%',
              render: (products) => {
                return (products ?? []).map((it: any, idx: number) => (
                  <div className="mb-8px" key={idx}>
                    {it?.avatar?.fullThumbUrl && <Avatar shape="square" src={it?.avatar?.fullThumbUrl} />}
                    <span className="pl-12px text-14px font-medium leading-20px">{it.name}</span>
                  </div>
                ));
              },
            },
            {
              title: 'Số lượng',
              key: 'quantity',
              dataIndex: 'product',
              align: 'right',
              width: '5%',
              render: (products) => {
                return (products ?? []).map((it: any) => (
                  <div className="flex items-center justify-end w-full h-[40px] mb-8px" key={'quantity' + it?.id}>
                    <span className="pl-12px text-14px font-medium leading-20px">{it.quantity}</span>
                  </div>
                ));
              },
            },
            {
              title: 'Đơn giá',
              key: 'unitPrice',
              dataIndex: 'product',
              align: 'right',
              width: '10%',
              render: (products) => {
                return (products ?? []).map((it: any) => (
                  <div className="flex items-center justify-end w-full h-[40px] mb-8px" key={'unitPrice' + it?.id}>
                    <span className="pl-12px text-14px font-medium leading-20px">
                      {numberWithDots(it?.unitPrice) + ' đ'}
                    </span>
                  </div>
                ));
              },
            },
            {
              title: 'Tổng cộng',
              key: 'total',
              dataIndex: 'total',
              align: 'right',
              width: '13%',
              render: (total) => numberWithDots(total) + 'đ',
            },
            {
              title: 'Thời gian đặt',
              key: 'createdAt',
              dataIndex: 'createdAt',
              align: 'center',
              width: '10%',
              render: (_createdAt) => dayjs(_createdAt).format(FORMAT_TIME),
            },
            {
              title: 'Thời gian hủy',
              key: 'statusDetail',
              dataIndex: 'statusDetail',
              width: '10%',
              render: (statusDetail) => dayjs(statusDetail?.createdAt).format(FORMAT_TIME),
            },
            {
              title: 'Tùy chọn',
              dataIndex: 'id',
              key: 'action',
              align: 'right',
              width: '7%',
              render: (id) => {
                return (
                  <p
                    className="text-primary hover:cursor-pointer"
                    onClick={() => navigator(AppRoutes.orders.detailId(id))}
                  >
                    Xem chi tiết
                  </p>
                );
              },
            },
          ]);
          break;
      }
    },
    [defaultColumns, filter, navigator, setParams],
  );

  useEffect(() => {
    if (location?.search) {
      const url = location.search;
      const status = url.slice(8);
      if (status) {
        const isExistTab = ORDER_TABS.find((tab) => tab.key === status);
        if (isExistTab) {
          setActiveTab(isExistTab?.key);
          refetch({
            ...filter,
            statuses: [isExistTab?.key],
            page: 1,
          });
          return;
        }
      }
    }
    setActiveTab(ORDER_ROUTERS.waiting.value);
  }, [filter, location, refetch]);

  return (
    <Spin spinning={loading || updating || canceling}>
      <div>
        <SubHeader
          items={[
            {
              title: 'Trang chủ',
              to: '/',
            },
            {
              title: 'Đơn hàng',
            },
          ]}
        />
        <Tabs activeKey={activeTab} className="bg-white pl-24px" items={ORDER_TABS} onChange={handleChangeTabs} />
        <div className="m-20px p-20px bg-white">
          <OrderFormFilter onFilter={handleFilter} />

          <MasterTable
            title={(dataOrders?.partnerOrders?.meta?.totalItems ?? 0) + ' đơn hàng'}
            filter={filter}
            setFilter={setFilter}
            data={orders ?? []}
            columns={columns}
            total={dataOrders?.partnerOrders?.meta?.totalItems}
            expandable={{
              defaultExpandAllRows: true,
              showExpandColumn: true,
              columnWidth: '3%',
              expandedRowRender: (record) => (
                <div className="mb-20px">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Avatar src={record?.user?.avatar?.fullThumbUrl} className="mr-16px" />
                      <div>
                        <span className="text-blue text-14px font-normal leading-20px">{record?.user?.phone}</span>{' '}
                        {' - '}
                        <span className="text-14px font-normal leading-20px">{record?.user?.fullname}</span>
                        <p className="text-13px text-grayscale-gray font-normal">{record?.address?.mapAddress}</p>
                      </div>
                    </div>
                    <div>
                      <span className="text-grayscale-gray text-14px leading-20px">Mã đơn hàng: </span>
                      <span className="text-grayscale-black text-14px leading-20px">{record?.code}</span>
                    </div>
                  </div>
                  <div className="mt-12px">
                    <span className="text-grayscale-gray text-14px leading-20px">Ghi chú: </span>
                    <span className="text-grayscale-black text-14px leading-20px">{record?.note}</span>
                  </div>
                </div>
              ),
            }}
            emptyImage={<OrderEmpty />}
            emptyText="Chưa có Đơn hàng nào"
          />
        </div>
        {showCancelOrder && (
          <CancelOrder
            open={showCancelOrder}
            onCancel={() => setShowCancelOrder(false)}
            onCompleted={handleCancelOrder}
            categoryTypeEnum={CategoryTypeEnum.CANCEL_ORDER_REASON_BY_PARTNER}
            loading={canceling}
          />
        )}
      </div>
    </Spin>
  );
});
export default Order;
