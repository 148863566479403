import { gql } from '@apollo/client';

import type * as Types from '../type.interface';

export type CategoryFragmentFragment = { __typename?: 'CategoryEntity' } & Pick<
  Types.CategoryEntity,
  'id' | 'name' | 'type' | 'isActive'
>;

export const CategoryFragmentFragmentDoc = gql`
  fragment CategoryFragment on CategoryEntity {
    id
    name
    type
    isActive
  }
`;
