import { Badge, Button, Popover } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon, LogoIcon, NotificationIcon } from '../../assets/icon';
import { Notification } from '../../components';
import { useAuth } from '../../contexts';
import { AppRoutes } from '../../helpers';
import { usePartnerNotificationTypeUnSeenCountQuery } from '../../graphql/queries/partnerNotificationTypeUnSeenCount.generated';

export const Header = memo(() => {
  const navigation = useNavigate();
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);

  const { data: countNoti, loading: getting, refetch: refetchCount } = usePartnerNotificationTypeUnSeenCountQuery({});
  const countNotifications = useMemo(() => countNoti?.partnerNotificationTypeUnSeenCount, [countNoti]);

  const onClickLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className="h-full w-full leading-normal">
      <div className="px-24px py-10px flex justify-between items-center h-full">
        <LogoIcon />
        <div className="flex justify-between items-center">
          <Popover
            content={
              <div>
                <div className="hover:bg-[#f9f9f9]">
                  <Button type="link" onClick={() => navigation(AppRoutes.profile)}>
                    <span className="text-grayscale-black">Thông tin tài khoản</span>
                  </Button>
                </div>
                <div className="hover:bg-[#f9f9f9]">
                  <Button onClick={() => onClickLogout()} type="link">
                    <span className="text-grayscale-black">Đăng xuất</span>
                  </Button>
                </div>
              </div>
            }
            placement="bottom"
          >
            <div className="flex items-center cursor-pointer group">
              <div className="w-24px h-24px border-1 rounded-full border-blue-8 shadow">
                <img className="w-full h-full rounded-full" src={user?.avatar?.fullThumbUrl as string} alt="" />
              </div>
              <div className="flex flex-rows items-center">
                <p className="mx-12px text-inherit truncate p-0 m-0">{user?.fullname}</p>
                <ChevronDownIcon />
              </div>
            </div>
          </Popover>
          <Popover
            className="hover:cursor-pointer"
            content={
              <Notification
                hide={hide}
                countNotifications={countNotifications}
                loading={getting}
                refetchCount={refetchCount}
              />
            }
            placement="bottom"
            arrow={false}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <div className="ml-32px">
              <Badge count={countNotifications?.reduce((total, it) => (total += +it?.count), 0)}>
                <NotificationIcon />
              </Badge>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
});
