import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type PartnerBookingQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type PartnerBookingQueryResponse = { __typename?: 'Query' } & {
  partnerBooking: { __typename?: 'BookingEntity' } & Pick<
    Types.BookingEntity,
    | 'addressMoreInfo'
    | 'code'
    | 'createdAt'
    | 'deletedAt'
    | 'description'
    | 'id'
    | 'latitude'
    | 'longitude'
    | 'mapAddress'
    | 'partnerId'
    | 'problemTexts'
    | 'status'
    | 'technicianId'
    | 'transportDistance'
    | 'transportDuration'
    | 'transportFee'
    | 'updatedAt'
    | 'userId'
    | 'scheduleReason'
    | 'technicianCanReviewUser'
    | 'scheduleTime'
    | 'vehicleId'
  > & {
      medias: Array<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          'fileSize' | 'fullOriginalUrl' | 'fullThumbUrl' | 'id' | 'name' | 'originalUrl' | 'thumbUrl' | 'type'
        >
      >;
      partner: { __typename?: 'PartnerEntity' } & Pick<
        Types.PartnerEntity,
        | 'addressMoreInfo'
        | 'email'
        | 'fullname'
        | 'hotline'
        | 'id'
        | 'isActive'
        | 'isApproved'
        | 'latitude'
        | 'longitude'
        | 'mapAddress'
        | 'phone'
        | 'type'
        | 'updatedAt'
      > & {
          avatar?: Types.Maybe<
            { __typename?: 'Media' } & Pick<Types.Media, 'fileSize' | 'fullOriginalUrl' | 'fullThumbUrl' | 'id'>
          >;
          qualifications?: Types.Maybe<
            Array<
              { __typename?: 'CategoryEntity' } & Pick<
                Types.CategoryEntity,
                'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
              >
            >
          >;
        };
      problems?: Types.Maybe<
        Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>>
      >;
      technician?: Types.Maybe<
        { __typename?: 'PartnerEntity' } & Pick<
          Types.PartnerEntity,
          | 'addressMoreInfo'
          | 'email'
          | 'fullname'
          | 'hotline'
          | 'id'
          | 'longitude'
          | 'mapAddress'
          | 'phone'
          | 'type'
          | 'updatedAt'
        > & {
            avatar?: Types.Maybe<
              { __typename?: 'Media' } & Pick<Types.Media, 'fileSize' | 'fullOriginalUrl' | 'fullThumbUrl' | 'id'>
            >;
          }
      >;
      statusDetail?: Types.Maybe<
        { __typename?: 'BookingStatusEntity' } & Pick<
          Types.BookingStatusEntity,
          | 'bookingId'
          | 'createdAt'
          | 'id'
          | 'note'
          | 'partnerId'
          | 'scheduleReason'
          | 'scheduleTime'
          | 'status'
          | 'userId'
        > & {
            reasons?: Types.Maybe<
              Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>>
            >;
          }
      >;
      user: { __typename?: 'UserEntity' } & Pick<
        Types.UserEntity,
        'address' | 'email' | 'fullname' | 'id' | 'phone'
      > & {
          avatar?: Types.Maybe<
            { __typename?: 'Media' } & Pick<Types.Media, 'fullOriginalUrl' | 'fullThumbUrl' | 'id' | 'fileSize'>
          >;
        };
      vehicle: { __typename?: 'VehicleEntity' } & Pick<
        Types.VehicleEntity,
        | 'addressMoreInfo'
        | 'id'
        | 'latitude'
        | 'longitude'
        | 'mapAddress'
        | 'name'
        | 'updatedAt'
        | 'userId'
        | 'vehicleRegistrationPlate'
        | 'vinNumber'
        | 'yearOfManufacture'
      > & {
          avatar?: Types.Maybe<
            { __typename?: 'Media' } & Pick<Types.Media, 'fileSize' | 'fullOriginalUrl' | 'fullThumbUrl' | 'id'>
          >;
        };
      quotationAccepted?: Types.Maybe<{ __typename?: 'QuotationEntity' } & Pick<Types.QuotationEntity, 'id'>>;
      settlementAccepted?: Types.Maybe<{ __typename?: 'SettlementEntity' } & Pick<Types.SettlementEntity, 'id'>>;
      userReviewAgency?: Types.Maybe<{ __typename?: 'ReviewEntity' } & Pick<Types.ReviewEntity, 'star' | 'comment'>>;
      userReviewTechnician?: Types.Maybe<
        { __typename?: 'ReviewEntity' } & Pick<Types.ReviewEntity, 'star' | 'comment'>
      >;
    };
};

export const PartnerBookingDocument = gql`
  query partnerBooking($id: String!) {
    partnerBooking(id: $id) {
      addressMoreInfo
      code
      createdAt
      deletedAt
      description
      id
      latitude
      longitude
      mapAddress
      medias {
        fileSize
        fullOriginalUrl
        fullThumbUrl
        id
        name
        originalUrl
        thumbUrl
        type
      }
      partner {
        addressMoreInfo
        avatar {
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
        }
        email
        fullname
        hotline
        id
        isActive
        isApproved
        latitude
        longitude
        mapAddress
        phone
        qualifications {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        type
        updatedAt
      }
      partnerId
      problemTexts
      problems {
        id
        isActive
        name
        type
      }
      status
      technician {
        addressMoreInfo
        avatar {
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
        }
        email
        fullname
        hotline
        id
        longitude
        mapAddress
        phone
        type
        updatedAt
      }
      statusDetail {
        bookingId
        createdAt
        id
        note
        partnerId
        reasons {
          id
          isActive
          name
          type
        }
        scheduleReason
        scheduleTime
        status
        userId
      }
      technicianId
      transportDistance
      transportDuration
      transportFee
      updatedAt
      user {
        address
        avatar {
          fullOriginalUrl
          fullThumbUrl
          id
          fileSize
        }
        email
        fullname
        id
        phone
      }
      userId
      vehicle {
        addressMoreInfo
        avatar {
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
        }
        id
        latitude
        longitude
        mapAddress
        name
        updatedAt
        userId
        vehicleRegistrationPlate
        vinNumber
        yearOfManufacture
      }
      scheduleReason
      technicianCanReviewUser
      scheduleTime
      vehicleId
      quotationAccepted {
        id
      }
      settlementAccepted {
        id
      }
      userReviewAgency {
        star
        comment
      }
      userReviewTechnician {
        star
        comment
      }
    }
  }
`;
export function usePartnerBookingQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerBookingQueryResponse, PartnerBookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerBookingQueryResponse, PartnerBookingQueryVariables>(PartnerBookingDocument, options);
}
export function usePartnerBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerBookingQueryResponse, PartnerBookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerBookingQueryResponse, PartnerBookingQueryVariables>(
    PartnerBookingDocument,
    options,
  );
}
export type PartnerBookingQueryHookResult = ReturnType<typeof usePartnerBookingQuery>;
export type PartnerBookingLazyQueryHookResult = ReturnType<typeof usePartnerBookingLazyQuery>;
export type PartnerBookingQueryResult = Apollo.QueryResult<PartnerBookingQueryResponse, PartnerBookingQueryVariables>;
