import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CheckProductSerialExistQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  serialNumber: Types.Scalars['String'];
}>;

export type CheckProductSerialExistQueryResponse = { __typename?: 'Query' } & Pick<
  Types.Query,
  'checkProductSerialExist'
>;

export const CheckProductSerialExistDocument = gql`
  query checkProductSerialExist($id: String, $serialNumber: String!) {
    checkProductSerialExist(id: $id, serialNumber: $serialNumber)
  }
`;
export function useCheckProductSerialExistQuery(
  baseOptions: Apollo.QueryHookOptions<CheckProductSerialExistQueryResponse, CheckProductSerialExistQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckProductSerialExistQueryResponse, CheckProductSerialExistQueryVariables>(
    CheckProductSerialExistDocument,
    options,
  );
}
export function useCheckProductSerialExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckProductSerialExistQueryResponse,
    CheckProductSerialExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckProductSerialExistQueryResponse, CheckProductSerialExistQueryVariables>(
    CheckProductSerialExistDocument,
    options,
  );
}
export type CheckProductSerialExistQueryHookResult = ReturnType<typeof useCheckProductSerialExistQuery>;
export type CheckProductSerialExistLazyQueryHookResult = ReturnType<typeof useCheckProductSerialExistLazyQuery>;
export type CheckProductSerialExistQueryResult = Apollo.QueryResult<
  CheckProductSerialExistQueryResponse,
  CheckProductSerialExistQueryVariables
>;
