import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CheckProductPartNumberExistQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  partNumber: Types.Scalars['String'];
}>;

export type CheckProductPartNumberExistQueryResponse = { __typename?: 'Query' } & Pick<
  Types.Query,
  'checkProductPartNumberExist'
>;

export const CheckProductPartNumberExistDocument = gql`
  query checkProductPartNumberExist($id: String, $partNumber: String!) {
    checkProductPartNumberExist(id: $id, partNumber: $partNumber)
  }
`;
export function useCheckProductPartNumberExistQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckProductPartNumberExistQueryResponse,
    CheckProductPartNumberExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckProductPartNumberExistQueryResponse, CheckProductPartNumberExistQueryVariables>(
    CheckProductPartNumberExistDocument,
    options,
  );
}
export function useCheckProductPartNumberExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckProductPartNumberExistQueryResponse,
    CheckProductPartNumberExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckProductPartNumberExistQueryResponse, CheckProductPartNumberExistQueryVariables>(
    CheckProductPartNumberExistDocument,
    options,
  );
}
export type CheckProductPartNumberExistQueryHookResult = ReturnType<typeof useCheckProductPartNumberExistQuery>;
export type CheckProductPartNumberExistLazyQueryHookResult = ReturnType<typeof useCheckProductPartNumberExistLazyQuery>;
export type CheckProductPartNumberExistQueryResult = Apollo.QueryResult<
  CheckProductPartNumberExistQueryResponse,
  CheckProductPartNumberExistQueryVariables
>;
