import { AppRoutes, AppRoutesName } from '../../helpers';
import {
  MenuCourseIcon,
  MenuFeedbackIcon,
  MenuHomeIcon,
  MenuOrderIcon,
  MenuProductIcon,
  MenuProfileIcon,
  MenuPromotionIcon,
  MenuQuoteIcon,
  MenuReferenceIcon,
  MenuRequestRepairIcon,
  MenuRevenueIcon,
  MenuReviewIcon,
  MenuSettingIcon,
  MenuTechnicianIcon,
  MenuWarehouseIcon,
} from '../../assets/icon';
import { PartnerTypeEnum } from '../../graphql/type.interface';
import { REQUEST_REPAIR_KEYS } from '../../screens/request-repair';
import { ORDER_ROUTERS } from '../../screens/order';

export const tabRouters = {
  route: {
    routes: [
      {
        key: AppRoutes.home,
        path: AppRoutes.home,
        name: AppRoutesName.home,
        icon: <MenuHomeIcon />,
      },
      {
        key: AppRoutes.orders.index,
        path: AppRoutes.orders.index,
        name: AppRoutesName.orders,
        icon: <MenuOrderIcon />,
        children: [
          {
            key: AppRoutes.orders.waitingConfirm,
            path: AppRoutes.orders.waitingConfirm,
            name: ORDER_ROUTERS.waiting.label,
          },
          {
            key: AppRoutes.orders.shipping,
            path: AppRoutes.orders.shipping,
            name: ORDER_ROUTERS.shipping.label,
          },
          {
            key: AppRoutes.orders.delivered,
            path: AppRoutes.orders.delivered,
            name: ORDER_ROUTERS.delivered.label,
          },
          {
            key: AppRoutes.orders.complete,
            path: AppRoutes.orders.complete,
            name: ORDER_ROUTERS.complete.label,
          },
          {
            key: AppRoutes.orders.cancel,
            path: AppRoutes.orders.cancel,
            name: ORDER_ROUTERS.cancel.label,
          },
        ],
      },
      {
        key: AppRoutes.quotes.index,
        path: AppRoutes.quotes.index,
        name: AppRoutesName.quotes,
        icon: <MenuQuoteIcon />,
        authority: [PartnerTypeEnum.AGENCY],
        children: [
          {
            key: AppRoutes.quotes.notSend,
            path: AppRoutes.quotes.notSend,
            name: 'Chưa gửi',
          },
          {
            key: AppRoutes.quotes.sent,
            path: AppRoutes.quotes.sent,
            name: 'Đã gửi',
          },
        ],
      },
      {
        key: AppRoutes.products.index,
        path: AppRoutes.products.index,
        name: AppRoutesName.products,
        icon: <MenuProductIcon />,
        children: [
          {
            key: AppRoutes.products.list,
            path: AppRoutes.products.list,
            name: 'Tất cả sản phẩm',
          },
          {
            key: AppRoutes.products.create,
            path: AppRoutes.products.create,
            name: 'Thêm mới sản phẩm',
          },
        ],
      },
      {
        key: AppRoutes.promotions.index,
        path: AppRoutes.promotions.index,
        name: AppRoutesName.promotions,
        icon: <MenuPromotionIcon />,
        children: [
          {
            key: AppRoutes.promotions.list,
            path: AppRoutes.promotions.list,
            name: 'Mã giảm giá',
          },
          {
            key: AppRoutes.promotions.create,
            path: AppRoutes.promotions.create,
            name: 'Thêm mới khuyến mãi',
          },
        ],
      },
      {
        key: AppRoutes.warehouse.index,
        path: AppRoutes.warehouse.index,
        name: 'Kho hàng',
        icon: <MenuWarehouseIcon />,
        authority: [PartnerTypeEnum.AGENCY],
      },
      {
        key: AppRoutes.requestRepair.index,
        path: AppRoutes.requestRepair.index,
        name: AppRoutesName.requestRepair,
        icon: <MenuRequestRepairIcon />,
        children: [
          // AGENCY
          {
            key: AppRoutes.requestRepair.waiting,
            path: AppRoutes.requestRepair.waiting,
            name: REQUEST_REPAIR_KEYS.waiting.label,
            authority: [PartnerTypeEnum.AGENCY],
          },
          {
            key: AppRoutes.requestRepair.waiting_ktv,
            path: AppRoutes.requestRepair.waiting_ktv,
            name: REQUEST_REPAIR_KEYS.waiting_ktv.label,
            authority: [PartnerTypeEnum.AGENCY],
          },
          {
            key: AppRoutes.requestRepair.technician_arriving,
            path: AppRoutes.requestRepair.technician_arriving,
            name: REQUEST_REPAIR_KEYS.technician_arriving.label,
            authority: [PartnerTypeEnum.AGENCY],
          },
          // KTV
          {
            key: AppRoutes.requestRepair.waiting_ktv,
            path: AppRoutes.requestRepair.waiting_ktv,
            name: REQUEST_REPAIR_KEYS.waiting.label,
            authority: [PartnerTypeEnum.FREELANCER_TECHNICIAN, PartnerTypeEnum.TECHNICIAN],
          },
          {
            key: AppRoutes.requestRepair.technician_arriving,
            path: AppRoutes.requestRepair.technician_arriving,
            name: REQUEST_REPAIR_KEYS.ktv_receiving.label,
            authority: [PartnerTypeEnum.FREELANCER_TECHNICIAN, PartnerTypeEnum.TECHNICIAN],
          },
          //BOTH
          {
            key: AppRoutes.requestRepair.quotation,
            path: AppRoutes.requestRepair.quotation,
            name: REQUEST_REPAIR_KEYS.quotation.label,
          },
          {
            key: AppRoutes.requestRepair.settlement,
            path: AppRoutes.requestRepair.settlement,
            name: REQUEST_REPAIR_KEYS.settlement.label,
          },
          {
            key: AppRoutes.requestRepair.completed,
            path: AppRoutes.requestRepair.completed,
            name: 'Hoàn thành',
          },
          {
            key: AppRoutes.requestRepair.cancel,
            path: AppRoutes.requestRepair.cancel,
            name: 'Đã hủy',
          },
        ],
      },
      {
        key: AppRoutes.review.index,
        path: AppRoutes.review.index,
        name: AppRoutesName.review,
        icon: <MenuReviewIcon />,
        children: [
          {
            key: AppRoutes.review.store,
            path: AppRoutes.review.store,
            name: 'Gian hàng',
          },
          {
            key: AppRoutes.review.service,
            path: AppRoutes.review.service,
            name: 'Dịch vụ',
          },
        ],
      },
      {
        key: AppRoutes.technician.index,
        path: AppRoutes.technician.index,
        name: AppRoutesName.technician,
        icon: <MenuTechnicianIcon />,
        authority: [PartnerTypeEnum.AGENCY],
        children: [
          {
            key: AppRoutes.technician.list,
            path: AppRoutes.technician.list,
            name: 'Danh sách kỹ thuật viên',
          },
          {
            key: AppRoutes.technician.create,
            path: AppRoutes.technician.create,
            name: 'Thêm mới kỹ thuật viên',
          },
        ],
      },
      {
        key: AppRoutes.feedback.index,
        path: AppRoutes.feedback.index,
        icon: <MenuFeedbackIcon />,
        name: 'Phản hồi chất lượng dịch vụ',
        authority: [PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN],
      },
      {
        key: AppRoutes.courses.index,
        path: AppRoutes.courses.index,
        name: AppRoutesName.courses,
        icon: <MenuCourseIcon />,
        authority: [PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN],
      },
      {
        key: AppRoutes.survey.index,
        path: AppRoutes.survey.index,
        name: 'Khảo sát',
        icon: <MenuCourseIcon />,
        authority: [PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN],
      },
      {
        key: AppRoutes.revenue,
        path: AppRoutes.revenue,
        name: AppRoutesName.revenue,
        icon: <MenuRevenueIcon />,
      },
      {
        path: AppRoutes.references,
        name: AppRoutesName.references,
        icon: <MenuReferenceIcon />,
        authority: [PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN],
      },
      {
        path: AppRoutes.profile,
        name: AppRoutesName.profile,
        icon: <MenuSettingIcon />,
      },
      {
        path: AppRoutes.setting,
        name: AppRoutesName.setting,
        icon: <MenuProfileIcon />,
      },
    ],
  },
};
