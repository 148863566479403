import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CheckProductVehicleRegistrationPlateExistQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  vehicleRegistrationPlate: Types.Scalars['String'];
}>;

export type CheckProductVehicleRegistrationPlateExistQueryResponse = { __typename?: 'Query' } & Pick<
  Types.Query,
  'checkProductVehicleRegistrationPlateExist'
>;

export const CheckProductVehicleRegistrationPlateExistDocument = gql`
  query checkProductVehicleRegistrationPlateExist($id: String, $vehicleRegistrationPlate: String!) {
    checkProductVehicleRegistrationPlateExist(id: $id, vehicleRegistrationPlate: $vehicleRegistrationPlate)
  }
`;
export function useCheckProductVehicleRegistrationPlateExistQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckProductVehicleRegistrationPlateExistQueryResponse,
    CheckProductVehicleRegistrationPlateExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckProductVehicleRegistrationPlateExistQueryResponse,
    CheckProductVehicleRegistrationPlateExistQueryVariables
  >(CheckProductVehicleRegistrationPlateExistDocument, options);
}
export function useCheckProductVehicleRegistrationPlateExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckProductVehicleRegistrationPlateExistQueryResponse,
    CheckProductVehicleRegistrationPlateExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckProductVehicleRegistrationPlateExistQueryResponse,
    CheckProductVehicleRegistrationPlateExistQueryVariables
  >(CheckProductVehicleRegistrationPlateExistDocument, options);
}
export type CheckProductVehicleRegistrationPlateExistQueryHookResult = ReturnType<
  typeof useCheckProductVehicleRegistrationPlateExistQuery
>;
export type CheckProductVehicleRegistrationPlateExistLazyQueryHookResult = ReturnType<
  typeof useCheckProductVehicleRegistrationPlateExistLazyQuery
>;
export type CheckProductVehicleRegistrationPlateExistQueryResult = Apollo.QueryResult<
  CheckProductVehicleRegistrationPlateExistQueryResponse,
  CheckProductVehicleRegistrationPlateExistQueryVariables
>;
