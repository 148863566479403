import { Col, Rate, Row } from 'antd';
import { memo } from 'react';

import { BookingEntity } from '../../../../graphql/type.interface';

type Props = {
  booking: BookingEntity;
};

export const RequestRepairReview = memo(({ booking }: Props) => {
  return (
    <div className="m-20px p-20px">
      <Row gutter={20}>
        <Col span={12}>
          <div className="p-20px bg-white">
            <p className="text-16px font-semibold leading-24px mb-24px">Nhân viên</p>
            {booking?.userReviewTechnician ? (
              <>
                <Rate disabled value={booking?.userReviewTechnician?.star} />
                <p>{booking?.userReviewTechnician?.comment}</p>
              </>
            ) : (
              <div className="text-center">Chưa có đánh giá</div>
            )}
          </div>
        </Col>
        <Col span={12}>
          <div className="p-20px bg-white">
            <p className="text-16px font-semibold leading-24px mb-24px">Đại lý</p>
            {booking?.userReviewAgency ? (
              <>
                <Rate disabled value={booking?.userReviewAgency?.star} />
                <p>{booking?.userReviewAgency?.comment}</p>
              </>
            ) : (
              <div className="text-center">Chưa có đánh giá</div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
});
