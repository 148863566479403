import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { ProductFragmentFragmentDoc } from '../fragments/product.fragment.generated';

const defaultOptions = {} as const;
export type PartnerProductQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type PartnerProductQueryResponse = { __typename?: 'Query' } & {
  partnerProduct: { __typename?: 'ProductEntity' } & Pick<
    Types.ProductEntity,
    | 'avatarId'
    | 'createdAt'
    | 'deletedAt'
    | 'descriptionImageIds'
    | 'detail'
    | 'id'
    | 'isActive'
    | 'isFixedCost'
    | 'isNew'
    | 'name'
    | 'operatingNumber'
    | 'operatingUnit'
    | 'ordinalNumber'
    | 'partNumber'
    | 'quantity'
    | 'serialNumber'
    | 'type'
    | 'unitPrice'
    | 'vehicleRegistrationPlate'
    | 'vinNumber'
    | 'yearOfManufacture'
  > & {
      avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
      descriptionImages: Array<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
      manufacturer?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      model?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      origin?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      partOfProduct?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      productDevices: Array<
        { __typename?: 'ProductDevice' } & {
          manufacturer: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'isActive' | 'name' | 'type'
          >;
          model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>;
          vehicleType: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'isActive' | 'name' | 'type'
          >;
        }
      >;
      productType?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      productUnit?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
    };
};

export const PartnerProductDocument = gql`
  query partnerProduct($id: String!) {
    partnerProduct(id: $id) {
      ...ProductFragment
    }
  }
  ${ProductFragmentFragmentDoc}
`;
export function usePartnerProductQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerProductQueryResponse, PartnerProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerProductQueryResponse, PartnerProductQueryVariables>(PartnerProductDocument, options);
}
export function usePartnerProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerProductQueryResponse, PartnerProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerProductQueryResponse, PartnerProductQueryVariables>(
    PartnerProductDocument,
    options,
  );
}
export type PartnerProductQueryHookResult = ReturnType<typeof usePartnerProductQuery>;
export type PartnerProductLazyQueryHookResult = ReturnType<typeof usePartnerProductLazyQuery>;
export type PartnerProductQueryResult = Apollo.QueryResult<PartnerProductQueryResponse, PartnerProductQueryVariables>;
