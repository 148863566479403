import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Divider } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { MasterTable, ShowImage } from '../../../../components';
import { convertBookingStatus, serialColumnTable, showNotification } from '../../../../helpers/utils';
import { AppRoutes, showGraphqlMessageError } from '../../../../helpers';
import { useTechnicianGetSettlementsQuery } from '../../../../graphql/queries/technicianGetSettlements.generated';
import { FileType } from '../../../../graphql/type.interface';
import { usePartnerGetInvoiceLazyQuery } from '../../../../graphql/queries/partnerGetInvoice.generated';

export const Bill = memo(() => {
  const { id = '' } = useParams();

  const [params, setParams] = useState({
    filters: null,
    isActive: null,
    isApproved: null,
    search: '',
    limit: 10,
    page: 1,
  });

  const { data, loading } = useTechnicianGetSettlementsQuery({
    variables: {
      ...params,
      technicianId: id,
    },
    skip: !id,
  });

  const bills = useMemo(() => data?.technicianGetSettlements?.items ?? [], [data]);

  const [getInvoiceMutation, { data: bill, loading: gettingBill }] = usePartnerGetInvoiceLazyQuery({
    onError(error) {
      showNotification('error', 'Xem hóa đơn thất bại', showGraphqlMessageError(error, true));
    },
  });

  const handleLoadBill = useCallback(
    (settlementId: string) => {
      getInvoiceMutation({
        variables: {
          settlementId,
        },
      });
    },
    [getInvoiceMutation],
  );

  useEffect(() => {
    if (bill?.partnerGetInvoice?.fullOriginalUrl) {
      const link = document.createElement('a');
      link.download = bill?.partnerGetInvoice?.name;

      link.href = bill?.partnerGetInvoice?.fullOriginalUrl;

      link.click();
    }
  }, [bill]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'name',
      render: (_, __, index: number) => serialColumnTable(params?.page as number, index),
      align: 'center',
      width: '5%',
    },
    {
      title: 'Mã hóa đơn',
      key: 'invoiceId',
      dataIndex: 'invoiceId',
    },
    {
      title: 'Khách hàng',
      key: 'user',
      dataIndex: 'user',
      render(user) {
        return (
          <div className="flex gap-x-12px">
            <ShowImage
              fileType={FileType.IMAGE}
              fullThumbUrl={user?.avatar?.fullThumbUrl ?? ''}
              size={32}
              fullOriginalUrl={user?.avatar?.fullOriginalUrl ?? ''}
            />
            <div>
              <p className="line-clamp-1">{user?.fullname}</p>
              <p className="text-grayscale-gray text-13px leading-18px">{user?.phone}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      key: 'booking',
      dataIndex: 'booking',
      render(booking) {
        return convertBookingStatus(booking?.status);
      },
    },
    {
      title: 'Tùy chọn',
      key: 'bookingId',
      dataIndex: 'bookingId',
      align: 'center',
      width: '15%',
      render: (bookingId, record) => {
        return (
          <div className="flex items-center justify-center">
            <span className="text-blue hover:cursor-pointer" onClick={() => handleLoadBill(record?.id)}>
              Tải xuống
            </span>
            <Divider type="vertical" />
            <Link to={AppRoutes.technician.detailRepairId(id, bookingId)}>
              <span className="text-primary">Xem chi tiết</span>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white m-20px p-20px">
      <MasterTable
        columns={columns}
        data={bills}
        loading={loading || gettingBill}
        filter={params}
        total={data?.technicianGetSettlements?.meta?.totalItems}
        setFilter={setParams}
      />
    </div>
  );
});
