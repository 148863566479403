import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { OrderFragmentFragmentDoc } from '../fragments/order.fragement.generated';

const defaultOptions = {} as const;
export type PartnerOrdersQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  statuses?: Types.InputMaybe<Array<Types.OrderStatusEnum> | Types.OrderStatusEnum>;
}>;

export type PartnerOrdersQueryResponse = { __typename?: 'Query' } & {
  partnerOrders: { __typename?: 'OrderConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'OrderEntity' } & Pick<
          Types.OrderEntity,
          | 'code'
          | 'createdAt'
          | 'deletedAt'
          | 'id'
          | 'note'
          | 'partnerId'
          | 'shippingFee'
          | 'status'
          | 'total'
          | 'discount'
          | 'updatedAt'
          | 'userId'
        > & {
            address?: Types.Maybe<
              { __typename?: 'OrderAddressEntity' } & Pick<Types.OrderAddressEntity, 'addressDetail' | 'mapAddress'>
            >;
            partner?: Types.Maybe<{ __typename?: 'OrderPartner' } & Pick<Types.OrderPartner, 'id'>>;
            product: Array<
              { __typename?: 'OrderProductEntity' } & Pick<
                Types.OrderProductEntity,
                | 'avatarId'
                | 'createdAt'
                | 'deletedAt'
                | 'descriptionImageIds'
                | 'id'
                | 'isNew'
                | 'name'
                | 'operatingNumber'
                | 'operatingUnit'
                | 'orderId'
                | 'ordinalNumber'
                | 'partNumber'
                | 'productId'
                | 'quantity'
                | 'serialNumber'
                | 'type'
                | 'unitPrice'
                | 'updatedAt'
                | 'vehicleRegistrationPlate'
                | 'vinNumber'
                | 'yearOfManufacture'
              > & { avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'id' | 'fullThumbUrl'>> }
            >;
            user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'id' | 'phone'> & {
                userAddress?: Types.Maybe<{ __typename?: 'AddressEntity' } & Pick<Types.AddressEntity, 'mapAddress'>>;
                avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'id' | 'fullThumbUrl'>>;
              };
            statusDetail?: Types.Maybe<
              { __typename?: 'OrderStatusEntity' } & Pick<
                Types.OrderStatusEntity,
                'createdAt' | 'partnerId' | 'userId' | 'note'
              > & {
                  reasons?: Types.Maybe<
                    Array<
                      { __typename?: 'OrderStatusCategoryEntity' } & Pick<
                        Types.OrderStatusCategoryEntity,
                        'id' | 'name'
                      >
                    >
                  >;
                }
            >;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const PartnerOrdersDocument = gql`
  query partnerOrders(
    $endDate: String
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $startDate: String
    $statuses: [OrderStatusEnum!]
  ) {
    partnerOrders(
      endDate: $endDate
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      startDate: $startDate
      statuses: $statuses
    ) {
      items {
        ...OrderFragment
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export function usePartnerOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<PartnerOrdersQueryResponse, PartnerOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerOrdersQueryResponse, PartnerOrdersQueryVariables>(PartnerOrdersDocument, options);
}
export function usePartnerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerOrdersQueryResponse, PartnerOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerOrdersQueryResponse, PartnerOrdersQueryVariables>(PartnerOrdersDocument, options);
}
export type PartnerOrdersQueryHookResult = ReturnType<typeof usePartnerOrdersQuery>;
export type PartnerOrdersLazyQueryHookResult = ReturnType<typeof usePartnerOrdersLazyQuery>;
export type PartnerOrdersQueryResult = Apollo.QueryResult<PartnerOrdersQueryResponse, PartnerOrdersQueryVariables>;
