import { ReviewObjectEnum } from '../graphql/type.interface';
import { ORDER_ROUTERS } from '../screens/order';
import { REQUEST_REPAIR_KEYS } from '../screens/request-repair';

export const AppRoutes = {
  home: '/',
  dashboard: '/dashboard',
  orders: {
    index: '/orders',
    detail: '/orders/:id',
    detailId: (id: string) => `/orders/${id}`,
    waitingConfirm: `/orders?status=${ORDER_ROUTERS.waiting.value}`,
    shipping: `/orders?status=${ORDER_ROUTERS.shipping.value}`,
    delivered: `/orders?status=${ORDER_ROUTERS.delivered.value}`,
    complete: `/orders?status=${ORDER_ROUTERS.complete.value}`,
    cancel: `/orders?status=${ORDER_ROUTERS.cancel.value}`,
  },
  quotes: {
    index: '/quotes',
    notSend: '/quotes?status=notSend',
    sent: '/quotes?status=sent',
    detail: '/quotes/:id',
    detailId: (id: string) => `/quotes/${id}`,
  },
  products: {
    index: '/products',
    list: '/products/list',
    create: '/product/create',
    detail: '/product/:id',
    detailId: (id: string) => `/product/${id}`,
    edit: '/product/edit/:id',
    editId: (id: string) => `/product/edit/${id}`,
  },
  promotions: {
    index: '/promotions',
    list: '/promotions/list',
    create: '/promotions/create',
    detail: '/promotions/:id',
    detailId: (id: string) => `/promotions/${id}`,
    edit: '/promotions/:id/edit',
    editId: (id: string) => `/promotions/${id}/edit`,
  },
  warehouse: {
    index: '/warehouse',
    create: '/warehouse/create',
    detail: '/warehouse/:id',
    detailId: (id: string) => `/warehouse/${id}`,
  },
  requestRepair: {
    index: '/request-repair',
    waiting: `/request-repair?status=${REQUEST_REPAIR_KEYS.waiting.value}`,
    waiting_ktv: `/request-repair?status=${REQUEST_REPAIR_KEYS.waiting_ktv.value}`,
    technician_arriving: `/request-repair?status=${REQUEST_REPAIR_KEYS.technician_arriving.value}`,
    quotation: `/request-repair?status=${REQUEST_REPAIR_KEYS.quotation.value}`,
    settlement: `/request-repair?status=${REQUEST_REPAIR_KEYS.settlement.value}`,
    completed: `/request-repair?status=${REQUEST_REPAIR_KEYS.completed.value}`,
    cancel: `/request-repair?status=${REQUEST_REPAIR_KEYS.cancel.value}`,
    detail: '/request-repair/:id',
    detailId: (id: string) => `/request-repair/${id}`,
  },
  review: {
    index: '/review',
    store: `/review/${ReviewObjectEnum.ORDER}`,
    service: `/review/${ReviewObjectEnum.BOOKING}`,
  },
  technician: {
    index: '/technician',
    list: '/technician/list',
    create: '/technician/create',
    detail: '/technician/:id',
    detailId: (id: string) => `/technician/${id}`,
    detailRepair: '/technician/:id/request-repair/:requestId',
    detailRepairId: (technicianId: string, id: string) => `/technician/${technicianId}/request-repair/${id}`,
    edit: '/technician/edit/:id',
    editId: (id: string) => `/technician/edit/${id}`,
  },
  courses: {
    index: '/courses',
    detail: '/courses/:id',
    detailId: (id: string) => `/courses/${id}`,
  },
  feedback: {
    index: '/feedback',
    create: '/feedback/create',
    detail: '/feedback/:id',
    detailId: (id: string) => `/feedback/${id}`,
  },
  survey: {
    index: '/survey',
    detail: '/survey/:id',
    detailId: (id: string) => `/survey/${id}`,
  },
  news: {
    index: '/news',
    list: {
      label: 'Tin tức',
      value: '/news',
    },
    detail: {
      value: '/news/:id',
      id: (id: string) => `/news/${id}`,
    },
  },
  revenue: '/revenue',
  references: '/references',
  setting: '/setting',
  profile: '/profile',
  404: '/404',
  auth: {
    index: '/auth',
    login: '/auth/login',
    register: '/auth/register',
  },
};

export const AppRoutesName = {
  home: 'Trang chủ',
  dashboard: 'Trang chủ',
  orders: 'Đơn hàng',
  quotes: 'Báo giá',
  products: 'Sản phẩm',
  promotions: 'Khuyến mãi',
  requestRepair: 'Yêu cầu sửa chữa',
  requestRepairDetail: 'Chi tiết yêu cầu sửa chữa',
  review: 'Đánh giá',
  technician: 'Kỹ thuật viên',
  courses: 'Khóa học',
  revenue: 'Doanh thu',
  references: 'Tài liệu tham khảo',
  profile: 'Thiết lập hồ sơ',
  setting: 'Cài đặt gian hàng',
};
