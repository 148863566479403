import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadProps, message } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { memo } from 'react';

interface UploadFileProps {
  multiple?: boolean;
  files?: UploadFile[];
  disabled: boolean;
  onChange?: (files: UploadFile[]) => void;
  onChangeSinUpload?: (file: UploadFile) => void;
  onRemove?: UploadProps['onRemove'];
  maxFiles?: number;
}

export const UploadFilePdf = memo((props: UploadFileProps) => {
  const { multiple, files, onChangeSinUpload, onChange, onRemove, disabled, maxFiles = 10 } = props;

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    onChange?.(fileList);
    fileList.length !== 0 && onChangeSinUpload?.(fileList[0]);
  };

  const uploadProps: UploadProps = {
    listType: 'text',
    maxCount: multiple ? maxFiles : 1,
    accept: '.pdf',
    beforeUpload: (file: RcFile) => {
      const allowedTypes = ['application/pdf'];
      const isAllowedType = allowedTypes.includes(file.type);
      if (!isAllowedType) {
        message.error('Vui lòng chọn ảnh thuộc định dạng PDF !');
        return Upload.LIST_IGNORE;
      }
      const maxSizeInMB = 128;
      const isAllowedSize = file.size / 1024 / 1024 < maxSizeInMB;
      if (!isAllowedSize) {
        message.error(`Dung lượng ảnh tối đa là ${maxSizeInMB} MB`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onChange: handleChange,
    disabled,
    onRemove,
  };
  return (
    <Upload {...uploadProps} fileList={files}>
      <Button icon={<UploadOutlined />}>Tải lên</Button>
    </Upload>
  );
});
