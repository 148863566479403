import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { ProductFragmentFragmentDoc } from '../fragments/product.fragment.generated';

const defaultOptions = {} as const;
export type PartnerUpdateAccessaryMutationVariables = Types.Exact<{
  input: Types.PartnerUpdateAccessaryInput;
}>;

export type PartnerUpdateAccessaryMutationResponse = { __typename?: 'Mutation' } & {
  partnerUpdateAccessary: { __typename?: 'ProductEntity' } & Pick<
    Types.ProductEntity,
    | 'avatarId'
    | 'createdAt'
    | 'deletedAt'
    | 'descriptionImageIds'
    | 'detail'
    | 'id'
    | 'isActive'
    | 'isFixedCost'
    | 'isNew'
    | 'name'
    | 'operatingNumber'
    | 'operatingUnit'
    | 'ordinalNumber'
    | 'partNumber'
    | 'quantity'
    | 'serialNumber'
    | 'type'
    | 'unitPrice'
    | 'vehicleRegistrationPlate'
    | 'vinNumber'
    | 'yearOfManufacture'
  > & {
      avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
      descriptionImages: Array<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
      manufacturer?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      model?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      origin?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      partOfProduct?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      productDevices: Array<
        { __typename?: 'ProductDevice' } & {
          manufacturer: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'isActive' | 'name' | 'type'
          >;
          model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>;
          vehicleType: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'isActive' | 'name' | 'type'
          >;
        }
      >;
      productType?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      productUnit?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
    };
};

export const PartnerUpdateAccessaryDocument = gql`
  mutation partnerUpdateAccessary($input: PartnerUpdateAccessaryInput!) {
    partnerUpdateAccessary(input: $input) {
      ...ProductFragment
    }
  }
  ${ProductFragmentFragmentDoc}
`;
export function usePartnerUpdateAccessaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PartnerUpdateAccessaryMutationResponse,
    PartnerUpdateAccessaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PartnerUpdateAccessaryMutationResponse, PartnerUpdateAccessaryMutationVariables>(
    PartnerUpdateAccessaryDocument,
    options,
  );
}
export type PartnerUpdateAccessaryMutationHookResult = ReturnType<typeof usePartnerUpdateAccessaryMutation>;
export type PartnerUpdateAccessaryMutationResult = Apollo.MutationResult<PartnerUpdateAccessaryMutationResponse>;
export type PartnerUpdateAccessaryMutationOptions = Apollo.BaseMutationOptions<
  PartnerUpdateAccessaryMutationResponse,
  PartnerUpdateAccessaryMutationVariables
>;
