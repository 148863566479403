import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type PartnerProductsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  isNew?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  partnerId: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  type?: Types.InputMaybe<Types.ProductTypeEnum>;
}>;

export type PartnerProductsQueryResponse = { __typename?: 'Query' } & {
  partnerProducts: { __typename?: 'ProductConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'ProductEntity' } & Pick<
          Types.ProductEntity,
          | 'detail'
          | 'id'
          | 'isActive'
          | 'isFixedCost'
          | 'isNew'
          | 'name'
          | 'operatingNumber'
          | 'operatingUnit'
          | 'ordinalNumber'
          | 'partNumber'
          | 'partnerId'
          | 'quantity'
          | 'serialNumber'
          | 'type'
          | 'unitPrice'
          | 'updatedAt'
          | 'vehicleRegistrationPlate'
          | 'vinNumber'
          | 'yearOfManufacture'
        > & { avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl'>> }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const PartnerProductsDocument = gql`
  query partnerProducts(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $isNew: Boolean
    $limit: Int
    $page: Int
    $partnerId: String!
    $search: String
    $sort: SortInput
    $type: ProductTypeEnum
  ) {
    partnerProducts(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      isNew: $isNew
      limit: $limit
      page: $page
      partnerId: $partnerId
      search: $search
      sort: $sort
      type: $type
    ) {
      items {
        avatar {
          fullThumbUrl
        }
        detail
        id
        isActive
        isFixedCost
        isNew
        name
        operatingNumber
        operatingUnit
        ordinalNumber
        partNumber
        partnerId
        quantity
        serialNumber
        type
        unitPrice
        updatedAt
        vehicleRegistrationPlate
        vinNumber
        yearOfManufacture
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function usePartnerProductsQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerProductsQueryResponse, PartnerProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerProductsQueryResponse, PartnerProductsQueryVariables>(PartnerProductsDocument, options);
}
export function usePartnerProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerProductsQueryResponse, PartnerProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerProductsQueryResponse, PartnerProductsQueryVariables>(
    PartnerProductsDocument,
    options,
  );
}
export type PartnerProductsQueryHookResult = ReturnType<typeof usePartnerProductsQuery>;
export type PartnerProductsLazyQueryHookResult = ReturnType<typeof usePartnerProductsLazyQuery>;
export type PartnerProductsQueryResult = Apollo.QueryResult<
  PartnerProductsQueryResponse,
  PartnerProductsQueryVariables
>;
