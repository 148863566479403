import ProLayout, { MenuDataItem } from '@ant-design/pro-layout';
import { memo, ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './style.less';

import { useAuth } from '../../contexts';
import { PartnerTypeEnum } from '../../graphql/type.interface';

import { Header } from './header';
import { tabRouters } from './tab-routers';
import { MenuFooter } from './menu-footer';

interface LayoutProps {
  children: ReactNode;
  collapsible?: boolean;
}
type MenuDataItemWithAuthor = MenuDataItem & { authority?: PartnerTypeEnum[] };

export const MainLayout = memo(({ children, collapsible = true }: LayoutProps) => {
  const { user } = useAuth();
  const [collapsed, setCollapsed] = useState(collapsible ? false : true);

  const menuDataRender = (menuList: MenuDataItemWithAuthor[], userType: PartnerTypeEnum): MenuDataItem[] =>
    menuList.map((item) => {
      const localItem = {
        ...item,
        children: item.children ? menuDataRender(item.children, userType) : [],
      };
      if (item?.authority && item?.authority.length > 0) {
        if (item?.authority.includes(userType)) return localItem;
        return { ...localItem, hideInMenu: true };
      }
      return localItem;
    });

  return (
    <ProLayout
      // {...tabRouters}
      menuDataRender={() => menuDataRender(tabRouters?.route?.routes, user?.type as PartnerTypeEnum)}
      location={useLocation()}
      layout="mix"
      fixedHeader={true}
      fixSiderbar={true}
      collapsed={collapsed}
      onCollapse={(collapsed) => collapsible && setCollapsed(collapsed)}
      menuHeaderRender={false}
      menuItemRender={(item: any, dom: any) => <Link to={item.path!}>{dom}</Link>}
      menuFooterRender={() => <MenuFooter />}
      navTheme="light"
      headerRender={() => <Header />}
      siderWidth={260}
      hasSiderMenu={false}
      contentStyle={{ margin: collapsible ? undefined : 0 }}
      collapsedButtonRender={collapsible ? undefined : false}
    >
      <div className="flex-grow relative">{children}</div>
    </ProLayout>
  );
});
