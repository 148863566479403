import React, { memo, useCallback, useState, useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { MasterTable, ModalCustomize } from '../../../components';
import { numberWithDots, serialColumnTable } from '../../../helpers/utils';
import { OperatingUnitEnum, ProductEntity } from '../../../graphql/type.interface';
import {
  PartnerProductsQueryVariables,
  usePartnerProductsQuery,
} from '../../../graphql/queries/partnerProducts.generated';
import { useAuth } from '../../../contexts';

type Props = {
  open: boolean;
  setOpen: (v: boolean) => void;
  onFinish: (ids: string[], products: ProductEntity[]) => void;
  productIds?: string[];
  products: ProductEntity[];
};

export const ModalSelectProduct = memo(({ open, setOpen, onFinish, productIds, products: productsDefault }: Props) => {
  const { user } = useAuth();
  const [params, setParams] = useState<PartnerProductsQueryVariables>({
    limit: 10,
    page: 1,
    partnerId: user?.id as string,
  });
  const { data: products, loading: gettingProducts } = usePartnerProductsQuery({
    variables: params,
    skip: !user,
  });

  const [selectedProductIds, setSelectedProductIds] = useState<React.Key[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductEntity[]>([]);
  const handleSelectedProducts = useCallback(
    (id: string, row: ProductEntity) => {
      // handle check all
      const isChecked = selectedProductIds.includes(id);
      if (isChecked) {
        setSelectedProductIds(selectedProductIds.filter((ids) => ids !== id));
        setSelectedProducts(selectedProducts.filter((item) => item.id !== id));
        return;
      }
      setSelectedProductIds([...selectedProductIds, id]);
      setSelectedProducts([...selectedProducts, row]);
    },
    [selectedProductIds, selectedProducts],
  );

  const handleSearch = useCallback(
    (values: PartnerProductsQueryVariables) => {
      setParams({ ...params, ...values, page: 1 });
    },
    [params],
  );

  useEffect(() => {
    if (productIds && productIds.length > 0) {
      setSelectedProductIds([...productIds]);
    }
  }, [productIds]);

  useEffect(() => {
    if (productsDefault && productsDefault.length > 0) {
      setSelectedProducts([...productsDefault]);
    }
  }, [productsDefault]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'name',
      render: (text, item, index: number) => serialColumnTable(params?.page as number, index),
      align: 'center',
      width: 50,
    },
    {
      title: 'Mã sản phẩm',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Tên sản phẩm',
      key: 'name',
      dataIndex: 'name',
      render: (name, _) => (
        <div className="flex flex-row items-center break-words">
          <img src={_.avatar ? _.avatar.fullThumbUrl : ''} alt="" width={32} height={32} className="rounded" />
          <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
            <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
              {name}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Tình trạng',
      key: 'isNew',
      dataIndex: 'isNew',
      render(value, record) {
        return value
          ? 'Mới'
          : `Đã qua sử dụng ${
              record?.operatingNumber + (record?.operatingUnit === OperatingUnitEnum.HOURS ? ' giờ' : ' km')
            } `;
      },
    },
    {
      title: 'Đơn giá',
      key: 'unitPrice',
      dataIndex: 'unitPrice',
      render: (value, record) => (!record?.isFixedCost ? 'Thương lượng' : numberWithDots(value) + 'đ'),
    },
    {
      title: 'Tồn kho',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Đã bán',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Tùy chọn',
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (id, row) => (
        <Checkbox
          key={id}
          onChange={() => handleSelectedProducts(id, row)}
          checked={!!selectedProductIds.includes(id)}
        />
      ),
    },
  ];

  return (
    <ModalCustomize
      width={'85%'}
      open={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{
        loading: gettingProducts,
        disabled: gettingProducts,
      }}
      onOk={() => onFinish(selectedProductIds as string[], selectedProducts)}
      title={
        <div className="flex items-center justify-between">
          <div>
            <h2>Chọn sản phẩm</h2>
            <p className="text-grayscale-gray text-14px">
              Danh sách này không bao gồm các sản phẩm đã áp dụng khuyến mãi và đang ẩn
            </p>
          </div>
        </div>
      }
    >
      <Form className="mt-[32px]" onFinish={handleSearch}>
        <Row gutter={20} justify={'space-between'}>
          <Col span={20}>
            <Form.Item label="" name="search" normalize={(e) => e.trimStart()}>
              <Input className="w-full" placeholder="Tìm kiếm theo tên, mã sản phẩm" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button className="w-full" type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <MasterTable
        columns={columns}
        data={products?.partnerProducts?.items ?? []}
        filter={params}
        setFilter={setParams}
        loading={gettingProducts}
      />
    </ModalCustomize>
  );
});
