import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type TechnicianGetBookingsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  technicianId: Types.Scalars['String'];
}>;

export type TechnicianGetBookingsQueryResponse = { __typename?: 'Query' } & {
  technicianGetBookings: { __typename?: 'BookingConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'BookingEntity' } & Pick<
          Types.BookingEntity,
          | 'code'
          | 'createdAt'
          | 'id'
          | 'partnerId'
          | 'scheduleReason'
          | 'scheduleTime'
          | 'status'
          | 'transportDistance'
          | 'transportDuration'
          | 'transportFee'
          | 'updatedAt'
        > & {
            partner: { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'id'>;
            quotationAccepted?: Types.Maybe<{ __typename?: 'QuotationEntity' } & Pick<Types.QuotationEntity, 'id'>>;
            settlementAccepted?: Types.Maybe<
              { __typename?: 'SettlementEntity' } & Pick<Types.SettlementEntity, 'id' | 'total'>
            >;
            statusDetail?: Types.Maybe<
              { __typename?: 'BookingStatusEntity' } & Pick<
                Types.BookingStatusEntity,
                | 'bookingId'
                | 'createdAt'
                | 'id'
                | 'note'
                | 'partnerId'
                | 'scheduleReason'
                | 'scheduleTime'
                | 'status'
                | 'userId'
              >
            >;
            technician?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'id'>>;
            user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'id' | 'phone'> & {
                avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'fullOriginalUrl'>>;
              };
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const TechnicianGetBookingsDocument = gql`
  query technicianGetBookings(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $technicianId: String!
  ) {
    technicianGetBookings(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      technicianId: $technicianId
    ) {
      items {
        code
        createdAt
        id
        partner {
          id
        }
        partnerId
        quotationAccepted {
          id
        }
        scheduleReason
        scheduleTime
        settlementAccepted {
          id
          total
        }
        status
        statusDetail {
          bookingId
          createdAt
          id
          note
          partnerId
          scheduleReason
          scheduleTime
          status
          userId
        }
        technician {
          id
        }
        transportDistance
        transportDuration
        transportFee
        updatedAt
        user {
          fullname
          id
          phone
          avatar {
            fullThumbUrl
            fullOriginalUrl
          }
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useTechnicianGetBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<TechnicianGetBookingsQueryResponse, TechnicianGetBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TechnicianGetBookingsQueryResponse, TechnicianGetBookingsQueryVariables>(
    TechnicianGetBookingsDocument,
    options,
  );
}
export function useTechnicianGetBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TechnicianGetBookingsQueryResponse, TechnicianGetBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TechnicianGetBookingsQueryResponse, TechnicianGetBookingsQueryVariables>(
    TechnicianGetBookingsDocument,
    options,
  );
}
export type TechnicianGetBookingsQueryHookResult = ReturnType<typeof useTechnicianGetBookingsQuery>;
export type TechnicianGetBookingsLazyQueryHookResult = ReturnType<typeof useTechnicianGetBookingsLazyQuery>;
export type TechnicianGetBookingsQueryResult = Apollo.QueryResult<
  TechnicianGetBookingsQueryResponse,
  TechnicianGetBookingsQueryVariables
>;
