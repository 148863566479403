import { Col, Descriptions, Row } from 'antd';
import { memo } from 'react';

import { TabKeyRequestRepair } from '../../../../constants';
import { convertBookingStatus, numberWithDots } from '../../../../helpers/utils';
import {
  BookingEntity,
  OperatingUnitEnum,
  QuotationEntity,
  SettlementEntity,
} from '../../../../graphql/type.interface';
import { DetailExpense } from '../../../request-repair/components/detail-total-expense';

type Props = {
  booking: BookingEntity;
  quotation?: QuotationEntity;
  settlement?: SettlementEntity;
};

export const RequestRepairInfomation = memo(({ booking, quotation, settlement }: Props) => {
  return (
    <div className="m-20px">
      <Row gutter={20}>
        <Col span={16}>
          <div className="bg-white p-20px">
            {/* CODE & STATUS */}
            <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Mã yêu cầu" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {booking?.code}
              </Descriptions.Item>
              <Descriptions.Item label="Trạng thái" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {convertBookingStatus(booking?.status)}
              </Descriptions.Item>
            </Descriptions>
            {/*  */}
            <h3 className="text-16px font-semibold uppercase py-20px">I. THông tin chung</h3>
            <h4 className="text-16px font-semibold leading-24px mb-16px">1. Thông tin khách hàng</h4>
            <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Họ và tên" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {booking?.user?.fullname}
              </Descriptions.Item>
              <Descriptions.Item
                label="Số điện thoại"
                labelStyle={{ width: '50%' }}
                contentStyle={{ color: '#202C38' }}
              >
                {booking?.user?.phone}
              </Descriptions.Item>
            </Descriptions>
            <h4 className="text-16px font-semibold leading-24px mt-20px mb-16px">
              2. Thông tin Kỹ thuật viên phụ trách
            </h4>
            <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Họ và tên" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {booking?.technician?.fullname}
              </Descriptions.Item>
              <Descriptions.Item
                label="Số điện thoại"
                labelStyle={{ width: '50%' }}
                contentStyle={{ color: '#202C38' }}
              >
                {booking?.technician?.phone}
              </Descriptions.Item>
            </Descriptions>
            <h4 className="text-16px font-semibold leading-24px mt-20px mb-16px">3. Thông tin xe gặp sự cố</h4>
            <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Tên xe" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {booking?.vehicle?.name}
              </Descriptions.Item>
              <Descriptions.Item
                label="Biển kiểm soát"
                labelStyle={{ width: '50%' }}
                contentStyle={{ color: '#202C38' }}
              >
                {booking?.vehicle?.vehicleRegistrationPlate ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label="Vị trí xe" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {booking?.mapAddress}
              </Descriptions.Item>
            </Descriptions>
            <h4 className="text-16px font-semibold leading-24px mt-20px mb-16px">4. Chẩn đoán</h4>
            <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Đã vận hành" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
                {quotation?.operatingNumber} {quotation?.operatingUnit === OperatingUnitEnum.HOURS ? 'giờ' : 'km'}
              </Descriptions.Item>
              {(quotation?.diagnostics || []).map((diagnostic) => (
                <Descriptions.Item
                  key={diagnostic?.id}
                  label={diagnostic?.diagnosticCode}
                  labelStyle={{ width: '50%' }}
                  contentStyle={{ color: '#202C38' }}
                >
                  {numberWithDots(diagnostic?.expense)} đ
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
          <div className="bg-white p-20px mt-20px">
            <h3 className="text-16px font-semibold uppercase py-20px">II. THông tin Chi Phí</h3>
            <h4 className="text-16px font-semibold leading-24px mb-16px">1. Vật tư phụ tùng</h4>
            <div className="w-full flex items-center space-x-20px">
              <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                {(quotation?.accessories || []).map((e: any) => (
                  <Descriptions.Item
                    key={e.id}
                    label={e.name}
                    labelStyle={{ width: '50%' }}
                    contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}
                  >
                    <div>
                      <div>{numberWithDots(e.unitPrice)} đ</div>
                      <div>x{e.quantity + ' (' + e.unit + ')'}</div>
                    </div>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </div>
            <h4 className="text-16px font-semibold leading-24px mb-16px mt-20px">2. Chi phí công dịch vụ</h4>
            <div className="w-full flex items-center space-x-20px">
              <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                <Descriptions.Item
                  label="Phí di chuyển"
                  labelStyle={{ width: '50%' }}
                  contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}
                >
                  {numberWithDots(quotation?.transportFee ?? 0)} đ
                </Descriptions.Item>
                <Descriptions.Item
                  label="Phí chẩn đoán"
                  labelStyle={{ width: '50%' }}
                  contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}
                >
                  {numberWithDots(quotation?.diagnosisFee ?? 0)} đ
                </Descriptions.Item>
                <Descriptions.Item
                  label="Phí sửa chữa, thay thế"
                  labelStyle={{ width: '50%' }}
                  contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}
                >
                  {numberWithDots(quotation?.repairFee ?? 0)} đ
                </Descriptions.Item>
              </Descriptions>
            </div>
            <h4 className="text-16px font-semibold leading-24px mb-16px mt-20px">3. Chi phí phát sinh</h4>
            <div className="w-full flex items-center space-x-20px">
              <Descriptions className="w-full" bordered column={1}>
                {([...(quotation?.additionalFees || []), ...(settlement?.additionalFees ?? [])] ?? []).map((e: any) => (
                  <Descriptions.Item
                    span={8}
                    key={e.name}
                    label={e.name}
                    labelStyle={{ width: '50%' }}
                    contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}
                  >
                    {numberWithDots(e.amount)} đ
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="bg-white p-20px">
            <DetailExpense
              selectedTab={TabKeyRequestRepair.HISTORY_QUOTES}
              expenses={[
                {
                  label: 'Vật tư phụ tùng',
                  value: (quotation?.accessories || []).reduce(
                    (rs: any, item: any) => rs + item.quantity * item.unitPrice,
                    0,
                  ),
                },
                {
                  label: 'Công dịch vụ',
                  value: (quotation?.diagnosisFee ?? 0) + (quotation?.repairFee ?? 0) + (quotation?.transportFee ?? 0),
                },
                {
                  label: 'Phát sinhCông dịch vụ',
                  value: (quotation?.additionalFees || []).reduce((rs: any, item: any) => rs + item.amount, 0),
                },
              ]}
              total={settlement?.total}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});
