import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  names: string[];
  routesName: string[];
}

export const BreadCrumb = memo(({ names, routesName }: Props) => {
  const navigate = useNavigate();

  const onClickRoute = (route: string) => {
    return navigate(route);
  };

  return (
    <>
      <div className="w-full flex flex-rows items-center">
        {names.map((name, index) => (
          <div
            key={`breadcrumb_${name}`}
            onClick={() => onClickRoute(routesName[index])}
            className={`text-[12px] leading-[16px] cursor-pointer ${
              index === names.length - 1 ? 'text-yankees-blue' : 'text-spanish-gray'
            }`}
          >
            {name}
            {index < names.length - 1 ? (
              <span className="mx-[8px] text-[12px] text-metallic-silver leading-[16px]">/</span>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </>
  );
});
