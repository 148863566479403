import { memo } from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { CheckNotiIcon, CloseNotiIcon, OrderNotiIcon } from '../../assets/icon';
import { AppRoutes } from '../../helpers';

import { NotificationEmpty } from './notification-empty';
import { PropsNotificationChildren } from './notification';

export const NotificationOrder = memo(
  ({ items, handleLoadmore, onSeenNotification, hide }: PropsNotificationChildren) => {
    const navigation = useNavigate();

    const _hanleCheckType = (type: string) => {
      switch (type) {
        case 'success':
          return <CheckNotiIcon fill="#1BB045" />;
        default:
          return <CloseNotiIcon fill="#919699" />;
      }
    };

    return items && items.length > 0 ? (
      <div className="overflow-y-auto overflow-x-hidden max-h-[463px]" onScroll={handleLoadmore}>
        {items.map((item, idx) => {
          return (
            <Row
              key={idx}
              gutter={12}
              className="hover:cursor-pointer"
              onClick={() => {
                if (!item.seen) {
                  onSeenNotification(item?.id);
                } else {
                  hide();
                }
                navigation(AppRoutes.orders.detailId(item?.order?.id as string));
              }}
            >
              <Col span="2" className="relative pt-2">
                <OrderNotiIcon stroke="#202C38" width={20} height={20} />
                {/* <span className="absolute top-0 right-[-2px]">{hanleCheckType(item?.type)}</span> */}
              </Col>
              <Col span="22">
                <Row gutter={12} justify="space-between">
                  <Col span={20}>
                    <span
                      className={`block font-medium  text-sm leading-5 ${
                        item?.seen ? 'text-grayscale-light' : 'text-grayscale-black'
                      }`}
                    >
                      {item?.title}
                    </span>
                    <span
                      className={`block font-normal text-grayscale-gray text-xs pt-2 leading-5 ${
                        item?.seen ? 'text-grayscale-light' : 'text-grayscale-black'
                      }`}
                    >
                      {item?.body}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className="text-grayscale-light text-[10px] font-normal">
                      {dayjs(item?.createdAt).fromNow()}
                    </span>
                  </Col>
                </Row>
                {idx !== items.length - 1 ? (
                  <div className="bg-bright-gray my-5 h-[1px] border-b" />
                ) : (
                  <div className="bg-white my-10" />
                )}
              </Col>
            </Row>
          );
        })}
      </div>
    ) : (
      <NotificationEmpty />
    );
  },
);
