import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type PartnerUpdateDiscountCodeMutationVariables = Types.Exact<{
  input: Types.PartnerUpdateDiscountCodeInput;
}>;

export type PartnerUpdateDiscountCodeMutationResponse = { __typename?: 'Mutation' } & {
  partnerUpdateDiscountCode: { __typename?: 'DiscountCodeEntity' } & Pick<Types.DiscountCodeEntity, 'id'>;
};

export const PartnerUpdateDiscountCodeDocument = gql`
  mutation partnerUpdateDiscountCode($input: PartnerUpdateDiscountCodeInput!) {
    partnerUpdateDiscountCode(input: $input) {
      id
    }
  }
`;
export function usePartnerUpdateDiscountCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PartnerUpdateDiscountCodeMutationResponse,
    PartnerUpdateDiscountCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PartnerUpdateDiscountCodeMutationResponse, PartnerUpdateDiscountCodeMutationVariables>(
    PartnerUpdateDiscountCodeDocument,
    options,
  );
}
export type PartnerUpdateDiscountCodeMutationHookResult = ReturnType<typeof usePartnerUpdateDiscountCodeMutation>;
export type PartnerUpdateDiscountCodeMutationResult = Apollo.MutationResult<PartnerUpdateDiscountCodeMutationResponse>;
export type PartnerUpdateDiscountCodeMutationOptions = Apollo.BaseMutationOptions<
  PartnerUpdateDiscountCodeMutationResponse,
  PartnerUpdateDiscountCodeMutationVariables
>;
