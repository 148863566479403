import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CheckProductOrdinalNumberExistQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  ordinalNumber: Types.Scalars['Float'];
}>;

export type CheckProductOrdinalNumberExistQueryResponse = { __typename?: 'Query' } & Pick<
  Types.Query,
  'checkProductOrdinalNumberExist'
>;

export const CheckProductOrdinalNumberExistDocument = gql`
  query checkProductOrdinalNumberExist($id: String, $ordinalNumber: Float!) {
    checkProductOrdinalNumberExist(id: $id, ordinalNumber: $ordinalNumber)
  }
`;
export function useCheckProductOrdinalNumberExistQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckProductOrdinalNumberExistQueryResponse,
    CheckProductOrdinalNumberExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckProductOrdinalNumberExistQueryResponse, CheckProductOrdinalNumberExistQueryVariables>(
    CheckProductOrdinalNumberExistDocument,
    options,
  );
}
export function useCheckProductOrdinalNumberExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckProductOrdinalNumberExistQueryResponse,
    CheckProductOrdinalNumberExistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckProductOrdinalNumberExistQueryResponse, CheckProductOrdinalNumberExistQueryVariables>(
    CheckProductOrdinalNumberExistDocument,
    options,
  );
}
export type CheckProductOrdinalNumberExistQueryHookResult = ReturnType<typeof useCheckProductOrdinalNumberExistQuery>;
export type CheckProductOrdinalNumberExistLazyQueryHookResult = ReturnType<
  typeof useCheckProductOrdinalNumberExistLazyQuery
>;
export type CheckProductOrdinalNumberExistQueryResult = Apollo.QueryResult<
  CheckProductOrdinalNumberExistQueryResponse,
  CheckProductOrdinalNumberExistQueryVariables
>;
