import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { ProductFragmentFragmentDoc } from '../fragments/product.fragment.generated';

const defaultOptions = {} as const;
export type PartnerCreateProductVehicleMutationVariables = Types.Exact<{
  input: Types.PartnerCreateProductVehicleInput;
}>;

export type PartnerCreateProductVehicleMutationResponse = { __typename?: 'Mutation' } & {
  partnerCreateProductVehicle: { __typename?: 'ProductEntity' } & Pick<
    Types.ProductEntity,
    | 'avatarId'
    | 'createdAt'
    | 'deletedAt'
    | 'descriptionImageIds'
    | 'detail'
    | 'id'
    | 'isActive'
    | 'isFixedCost'
    | 'isNew'
    | 'name'
    | 'operatingNumber'
    | 'operatingUnit'
    | 'ordinalNumber'
    | 'partNumber'
    | 'quantity'
    | 'serialNumber'
    | 'type'
    | 'unitPrice'
    | 'vehicleRegistrationPlate'
    | 'vinNumber'
    | 'yearOfManufacture'
  > & {
      avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
      descriptionImages: Array<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
      manufacturer?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      model?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      origin?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      partOfProduct?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      productDevices: Array<
        { __typename?: 'ProductDevice' } & {
          manufacturer: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'isActive' | 'name' | 'type'
          >;
          model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>;
          vehicleType: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'isActive' | 'name' | 'type'
          >;
        }
      >;
      productType?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
      productUnit?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
      >;
    };
};

export const PartnerCreateProductVehicleDocument = gql`
  mutation partnerCreateProductVehicle($input: PartnerCreateProductVehicleInput!) {
    partnerCreateProductVehicle(input: $input) {
      ...ProductFragment
    }
  }
  ${ProductFragmentFragmentDoc}
`;
export function usePartnerCreateProductVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PartnerCreateProductVehicleMutationResponse,
    PartnerCreateProductVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PartnerCreateProductVehicleMutationResponse, PartnerCreateProductVehicleMutationVariables>(
    PartnerCreateProductVehicleDocument,
    options,
  );
}
export type PartnerCreateProductVehicleMutationHookResult = ReturnType<typeof usePartnerCreateProductVehicleMutation>;
export type PartnerCreateProductVehicleMutationResult =
  Apollo.MutationResult<PartnerCreateProductVehicleMutationResponse>;
export type PartnerCreateProductVehicleMutationOptions = Apollo.BaseMutationOptions<
  PartnerCreateProductVehicleMutationResponse,
  PartnerCreateProductVehicleMutationVariables
>;
