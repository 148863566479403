import { gql } from '@apollo/client';

import type * as Types from '../type.interface';

export type ProductFragmentFragment = { __typename?: 'ProductEntity' } & Pick<
  Types.ProductEntity,
  | 'avatarId'
  | 'createdAt'
  | 'deletedAt'
  | 'descriptionImageIds'
  | 'detail'
  | 'id'
  | 'isActive'
  | 'isFixedCost'
  | 'isNew'
  | 'name'
  | 'operatingNumber'
  | 'operatingUnit'
  | 'ordinalNumber'
  | 'partNumber'
  | 'quantity'
  | 'serialNumber'
  | 'type'
  | 'unitPrice'
  | 'vehicleRegistrationPlate'
  | 'vinNumber'
  | 'yearOfManufacture'
> & {
    avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
    descriptionImages: Array<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id' | 'name'>>;
    manufacturer?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
    >;
    model?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
    >;
    origin?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
    >;
    partOfProduct?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
    >;
    productDevices: Array<
      { __typename?: 'ProductDevice' } & {
        manufacturer: { __typename?: 'CategoryEntity' } & Pick<
          Types.CategoryEntity,
          'id' | 'isActive' | 'name' | 'type'
        >;
        model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>;
        vehicleType: { __typename?: 'CategoryEntity' } & Pick<
          Types.CategoryEntity,
          'id' | 'isActive' | 'name' | 'type'
        >;
      }
    >;
    productType?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
    >;
    productUnit?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
    >;
  };

export const ProductFragmentFragmentDoc = gql`
  fragment ProductFragment on ProductEntity {
    avatar {
      fullThumbUrl
      id
      name
    }
    avatarId
    createdAt
    deletedAt
    descriptionImageIds
    descriptionImages {
      fullThumbUrl
      id
      name
    }
    detail
    id
    isActive
    isFixedCost
    isNew
    manufacturer {
      id
      isActive
      name
      type
    }
    model {
      id
      isActive
      name
      type
    }
    name
    operatingNumber
    operatingUnit
    ordinalNumber
    origin {
      id
      isActive
      name
      type
    }
    partNumber
    partOfProduct {
      id
      isActive
      name
      type
    }
    productDevices {
      manufacturer {
        id
        isActive
        name
        type
      }
      model {
        id
        isActive
        name
        type
      }
      vehicleType {
        id
        isActive
        name
        type
      }
    }
    productType {
      id
      isActive
      name
      type
    }
    productUnit {
      id
      isActive
      name
      type
    }
    quantity
    serialNumber
    type
    unitPrice
    vehicleRegistrationPlate
    vinNumber
    yearOfManufacture
  }
`;
