import { memo, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Link, useParams } from 'react-router-dom';

import { MasterTable, ShowImage } from '../../../../components';
import { convertBookingStatus, numberWithDots, serialColumnTable } from '../../../../helpers/utils';
import { AppRoutes } from '../../../../helpers';
import { useTechnicianGetBookingsQuery } from '../../../../graphql/queries/technicianGetBookings.generated';
import { FileType } from '../../../../graphql/type.interface';

export const History = memo(() => {
  const { id = '' } = useParams();
  const [params, setParams] = useState({
    filters: null,
    isActive: null,
    isApproved: null,
    search: '',
    limit: 10,
    page: 1,
  });

  const { data, loading } = useTechnicianGetBookingsQuery({
    variables: {
      ...params,
      technicianId: id,
    },
    skip: !id,
  });

  const bookings = useMemo(() => data?.technicianGetBookings?.items ?? [], [data]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'name',
      render: (_, __, index: number) => serialColumnTable(params?.page as number, index),
      align: 'center',
      width: '5%',
    },
    {
      title: 'Mã yêu cầu',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Khách hàng',
      key: 'user',
      dataIndex: 'user',
      render(user) {
        return (
          <div className="flex gap-x-12px">
            <ShowImage
              fileType={FileType.IMAGE}
              fullThumbUrl={user?.avatar?.fullThumbUrl ?? ''}
              size={32}
              fullOriginalUrl={user?.avatar?.fullOriginalUrl ?? ''}
            />
            <div>
              <p className="line-clamp-1">{user?.fullname}</p>
              <p className="text-grayscale-gray text-13px leading-18px">{user?.phone}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      render(status) {
        return convertBookingStatus(status);
      },
    },
    {
      title: 'Chi phí di chuyển',
      key: 'transportFee',
      dataIndex: 'transportFee',
      render: (transportFee) => numberWithDots(transportFee ?? 0) + ' đ',
    },
    {
      title: 'Tổng chi phí',
      key: 'settlementAccepted',
      dataIndex: 'settlementAccepted',
      render: (settlementAccepted) => numberWithDots(settlementAccepted?.total ?? 0) + ' đ',
    },
    {
      title: 'Tùy chọn',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      width: '10%',
      render: (requestId) => (
        <Link to={AppRoutes.technician.detailRepairId(id, requestId)}>
          <span className="text-primary">Xem chi tiết</span>
        </Link>
      ),
    },
  ];

  return (
    <div className="bg-white m-20px p-20px">
      <MasterTable
        columns={columns}
        data={bookings}
        loading={loading}
        filter={params}
        total={data?.technicianGetBookings?.meta?.totalItems}
        setFilter={setParams}
      />
    </div>
  );
});
