import { memo, useMemo, useState, useCallback } from 'react';
import { Button, Col, DatePicker, Form, Row, Spin, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import '../../style.less';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';

import { FORMAT_DATE, FORMAT_TIME } from '../../../../constants';
import { ModalCustomize } from '../../../../components';
import { validationMessages } from '../../../../helpers';
import { PartnerBookingDocument, usePartnerBookingQuery } from '../../../../graphql/queries/partnerBooking.generated';
import { usePartnerRescheduleBookingMutation } from '../../../../graphql/mutations/partnerRescheduleBooking.generated';
import { PartnerBookingsDocument } from '../../../../graphql/queries/partnerBookings.generated';

type Props = {
  bookingId?: string;
  open: boolean;
  onCancel: () => void;
  onOk?: () => void;
};

type FormData = {
  scheduleTime: Date;
  reason: string;
};

const HOURS = [
  {
    label: 'Sáng',
    values: [
      {
        label: '7:00',
        value: 7,
      },
      {
        label: '8:00',
        value: 8,
      },
      {
        label: '9:00',
        value: 9,
      },
      {
        label: '10:00',
        value: 10,
      },
      {
        label: '11:00',
        value: 11,
      },
    ],
  },
  {
    label: 'Chiều',
    values: [
      {
        label: '13:00',
        value: 13,
      },
      {
        label: '14:00',
        value: 14,
      },
      {
        label: '15:00',
        value: 15,
      },
      {
        label: '16:00',
        value: 16,
      },
      {
        label: '17:00',
        value: 17,
      },
    ],
  },
  {
    label: 'Tối',
    values: [
      {
        label: '18:00',
        value: 18,
      },
      {
        label: '19:00',
        value: 19,
      },
      {
        label: '20:00',
        value: 20,
      },
      {
        label: '21:00',
        value: 21,
      },
      {
        label: '22:00',
        value: 22,
      },
    ],
  },
];

export const ScheduleRepair = memo(({ bookingId, ...props }: Props) => {
  const [active, setActive] = useState('Sáng');
  const [hour, setHour] = useState(7);

  const [form] = useForm();
  const reason = Form.useWatch('reason', form);

  const { data: result, loading } = usePartnerBookingQuery({
    variables: { id: bookingId as string },
    fetchPolicy: 'cache-and-network',
    skip: !bookingId,
  });
  const data = useMemo(() => result?.partnerBooking, [result]);

  const [technicianRescheduleApi, { loading: updating }] = usePartnerRescheduleBookingMutation({
    onError(error) {
      notification.error({
        message: 'Hẹn ngày đến thất bại',
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: 'Đã hẹn lại ngày đến',
        description: data?.code,
      });
      if (props.onOk) {
        props.onOk();
      }
    },
  });

  const onFinish = useCallback(
    (values: FormData) => {
      const scheduleTime = dayjs(values?.scheduleTime).hour(hour).minute(0);
      if (!bookingId) return;
      technicianRescheduleApi({
        variables: {
          input: {
            bookingId,
            scheduleTime,
            scheduleReason: values.reason,
          },
        },
        refetchQueries: [PartnerBookingDocument, PartnerBookingsDocument],
      });
    },
    [bookingId, hour, technicianRescheduleApi],
  );

  const handleChangeActive = (value: string) => {
    setActive(value);
    const values = HOURS.find((h) => h.label === value)?.values;
    if (values) {
      setHour(values[0].value);
    }
  };

  const handleSetSchedule = (value: number) => {
    setHour(value);
  };

  const handleSetReason = () => {
    form.setFieldsValue({
      reason: 'Xin lỗi, Tôi có thể hẹn lại ngày đến được không?',
    });
  };

  if (!data) return null;
  return (
    <ModalCustomize
      title="Hẹn ngày đến"
      okText="Xác nhận"
      open={props.open}
      onCancel={props?.onCancel}
      okButtonProps={{ htmlType: 'submit', form: 'form-reschedule', disabled: updating || loading }}
    >
      <Spin spinning={loading || updating} className="text-14px leading-18px">
        <div className="text-center">
          <p className="text-[16px] font-semibold text-grayscale-black">{data?.code || ''}</p>
          <p className="text-[12px] font-normal text-grayscale-light">
            Đặt lúc: {data?.createdAt && dayjs(data?.createdAt).format(FORMAT_TIME)}
          </p>
        </div>
        <div className="w-full h-[6px] my-[16px] bg-[#EEEEEE]"></div>
        <Form
          form={form}
          id="form-reschedule"
          labelAlign="left"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Thời gian hẹn đến sửa chữa"
            name="scheduleTime"
            rules={[{ required: true, message: validationMessages.required }]}
          >
            <DatePicker
              className="w-full"
              format={FORMAT_DATE}
              disabledDate={(current) => current && current < dayjs().add(-1, 'day')}
            />
          </Form.Item>
          <div>
            <Row className="my-[16px]">
              {HOURS.map((i, idx) => {
                return (
                  <Col key={idx} span={24 / HOURS.length}>
                    <Button
                      className="w-full"
                      type={i.label === active ? 'primary' : 'dashed'}
                      onClick={() => handleChangeActive(i.label)}
                    >
                      {i.label}
                    </Button>
                  </Col>
                );
              })}
            </Row>
            <div className="flex flex-wrap items-center justify-around">
              {HOURS.find((h) => h.label === active)?.values.map((v, idx) => {
                return (
                  <Button
                    className={`${v.value === hour ? 'border-grayscale-black' : ''}`}
                    onClick={() => handleSetSchedule(v.value)}
                    key={idx}
                  >
                    {v.label}
                  </Button>
                );
              })}
            </div>
          </div>
          <Form.Item
            label="Lý do"
            name="reason"
            rules={[{ required: true, message: validationMessages.required }]}
            normalize={(e) => e.trimStart()}
          >
            <TextArea maxLength={1000} showCount placeholder="Nhập lý do..." />
          </Form.Item>
          <button
            onClick={handleSetReason}
            disabled={!!reason}
            className="disabled:cursor-no-drop hover:cursor-pointer hover:border-yellow outline-none border border-solid border-[#EEEEEE] rounded-lg bg-white w-full py-[8px] text-center my-[16px]"
          >
            Xin lỗi, Tôi có thể hẹn lại ngày đến được không?
          </button>
        </Form>
      </Spin>
    </ModalCustomize>
  );
});
