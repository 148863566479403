import { memo } from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts';
import { PartnerTypeEnum } from '../../graphql/type.interface';

type Props = {
  children: JSX.Element;
  authority?: PartnerTypeEnum[];
};

export const PrivateContent = memo(({ children, authority = [] }: Props) => {
  const navigation = useNavigate();
  const { user } = useAuth();

  if (!authority.includes(user?.type as PartnerTypeEnum))
    return (
      <Result
        status="403"
        title="403"
        subTitle="Xin lỗi, bạn không có quyền truy cập vào trang này! :(("
        extra={
          <Button type="primary" onClick={() => navigation('/')}>
            Trang chủ
          </Button>
        }
      />
    );
  return <>{children}</>;
});
