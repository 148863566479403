import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spin, Tabs, TabsProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { SubHeader } from '../../components';
import { AppRoutes, AppRoutesName, showGraphqlMessageError } from '../../helpers';
import { useAgencyGetDetailTechnicianQuery } from '../../graphql/queries/agencyGetDetailTechnician.generated';
import { PartnerEntity } from '../../graphql/type.interface';
import { useDialog } from '../../contexts';
import { useAgencyDeleteTechnicianMutation } from '../../graphql/mutations/agencyDeleteTechnician.generated';
import { showNotification } from '../../helpers/utils';

import { Bill, History, Review, TechnicianForm } from './components';

type Props = {
  isEdit?: boolean;
};

const tabsKey = {
  infomation: 'infomation',
  history: 'history',
  bill: 'bill',
  review: 'review',
};

const items: TabsProps['items'] = [
  {
    key: tabsKey.infomation,
    label: `Thông tin chi tiết`,
    children: '',
  },
  {
    key: tabsKey.history,
    label: `Lịch sử sửa chữa`,
    children: '',
  },
  {
    key: tabsKey.bill,
    label: `Hóa đơn`,
    children: '',
  },
  {
    key: tabsKey.review,
    label: `Đánh giá`,
    children: '',
  },
];

export const TechnicianDetail = memo(({ isEdit = false }: Props) => {
  const { showDialog } = useDialog();
  const navigate = useNavigate();

  const { id = '' } = useParams();

  const { data, loading } = useAgencyGetDetailTechnicianQuery({ variables: { id }, skip: !id });
  const technician = useMemo(() => data?.agencyGetDetailTechnician, [data]);

  const [editing, setEditing] = useState(false);

  const [tabActive, setTabActive] = useState(tabsKey.infomation);

  const onChange = (key: string) => {
    setTabActive(key);
  };

  const handleChangeEditing = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const [removeTechnicianApi, { loading: removing }] = useAgencyDeleteTechnicianMutation({
    onError(error) {
      showNotification('error', 'Xóa kỹ thuật viên thất bại', showGraphqlMessageError(error, true));
    },
    onCompleted() {
      showNotification('error', 'Xóa kỹ thuật viên thành công');
      navigate(AppRoutes.technician.list);
    },
  });
  const handleDelete = useCallback(() => {
    removeTechnicianApi({ variables: { input: { id: technician?.id as string } } });
  }, [removeTechnicianApi, technician?.id]);

  useEffect(() => {
    setEditing(isEdit);
  }, [isEdit]);

  if (!technician) return null;
  return (
    <Spin spinning={loading || removing}>
      <SubHeader
        title={technician?.fullname as string}
        items={[
          { title: AppRoutesName.home, to: AppRoutes.home },
          { title: AppRoutesName.technician, to: AppRoutes.technician.list },
          { title: 'Thông tin chi tiết' },
        ]}
        rightContent={
          tabActive === tabsKey.infomation && (
            <>
              <Button
                className="border border-solid border-red rounded mr-16px"
                onClick={() =>
                  showDialog({
                    title: `Bạn chắc chắn muốn xóa kỹ thuật viên ${technician?.fullname}?`,
                    content: 'Sau khi xoá bạn mọi thông tin về kỹ thuật viên sẽ biến mất',
                    cancelText: 'Hủy',
                    confirmText: 'Xóa',
                    onOK: async () => await handleDelete(),
                  })
                }
              >
                <span className="text-red">Xóa</span>
              </Button>
              {!editing && (
                <Button type="primary" onClick={handleChangeEditing}>
                  Sửa thông tin
                </Button>
              )}
            </>
          )
        }
      />
      <div className="px-24px py-0px bg-white">
        <Tabs activeKey={tabActive} items={items} onChange={onChange} />
      </div>
      {tabActive === tabsKey.infomation && (
        <TechnicianForm technician={technician as PartnerEntity} setEditing={handleChangeEditing} editing={editing} />
      )}
      {tabActive === tabsKey.history && <History />}
      {tabActive === tabsKey.bill && <Bill />}
      {tabActive === tabsKey.review && <Review />}
    </Spin>
  );
});

export default TechnicianDetail;
