import { memo, useState, ReactNode } from 'react';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Divider, Modal, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  titleDelete?: string;
  urlDetail?: (id: string) => string;
  urlEdit?: (id: string) => string;
  onDelete?: (id: string) => void;
  showStatusActionIcon?: boolean;
  content?: string | ReactNode;
};

export const ActionsTable = memo(
  ({ urlDetail, urlEdit, id, titleDelete, onDelete, showStatusActionIcon = true, content = '' }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      onDelete?.(id);
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <div className="flex-center">
        {urlDetail && (
          <Link to={urlDetail(id)} className="action-group text-black">
            {showStatusActionIcon ? (
              <Tooltip title="Xem chi tiết">
                <Tag color="gold">
                  <EyeOutlined />
                </Tag>
              </Tooltip>
            ) : (
              <span className="text-primary hover:cursor-pointer">Xem chi tiết</span>
            )}
          </Link>
        )}
        {!showStatusActionIcon && <Divider className="mx-8px" type="vertical" />}
        {urlEdit && (
          <Link to={urlEdit(id)} className="text-black">
            {showStatusActionIcon ? (
              <Tooltip title="Cập nhật">
                <Tag color="gold">
                  <EditOutlined />
                </Tag>
              </Tooltip>
            ) : (
              <span className="text-vivid-cerulean hover:cursor-pointer">Sửa</span>
            )}
          </Link>
        )}
        {!showStatusActionIcon && <Divider className="mx-8px" type="vertical" />}
        {onDelete && (
          <div onClick={showModal}>
            {showStatusActionIcon ? (
              <Tooltip title="Xóa">
                <Tag color="#f50" className="hover:cursor-pointer">
                  <DeleteOutlined />
                </Tag>
              </Tooltip>
            ) : (
              <span className="text-vermilion hover:cursor-pointer">Xóa</span>
            )}
          </div>
        )}
        <Modal
          centered
          title={titleDelete}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Xóa"
          cancelText="Hủy"
        >
          {content}
        </Modal>
      </div>
    );
  },
);
