import { useApolloClient } from '@apollo/client';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { PartnerEntity } from '../graphql/type.interface';
import { useMePartnerQuery } from '../graphql/queries/mePartner.generated';
import { useLogoutMutation } from '../graphql/mutations/logout.generated';

import { useFullscreenLoading } from './fullscreen-loading-context';

type ContextProps = {
  isLoggedIn: boolean;
  isCheckedMe: boolean;
  user?: PartnerEntity;
  logout: () => Promise<void>;
  login: (data: PartnerEntity) => Promise<void> | void;
  updateInfoUser: (data: PartnerEntity) => void;
};

const AuthContext = createContext<ContextProps>({
  isLoggedIn: false,
  isCheckedMe: false,
  login() {
    throw new Error('not-ready');
  },
  logout() {
    throw new Error('not-ready');
  },
  updateInfoUser() {
    throw new Error('not-ready');
  },
});

export const useAuth = () => useContext(AuthContext);

type Props = PropsWithChildren;

export const AuthProvider = ({ children }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [deviceId] = useState<string>();

  const [user, setUser] = useState<PartnerEntity>();

  // const { mutateAsync: getFirebaseDeviceToken } = useGetFirebaseDeviceTokenMutation({
  //   onSuccess: async (token) => {
  //     await AsyncStorage.setItem(DEVICE_ID, token);
  //     setDeviceId(token);
  //   },
  // });

  useEffect(() => {
    // getFirebaseDeviceToken();
  }, [deviceId]);

  const { loading, called } = useMePartnerQuery({
    variables: {
      // deviceId,
    },
    onCompleted: (res) => {
      setUser(res.mePartner as PartnerEntity);
      setIsLoggedIn(true);
    },
  });

  const login = useCallback((data: PartnerEntity) => {
    setUser(data);
    setIsLoggedIn(true);
  }, []);

  const updateInfoUser = useCallback((data: PartnerEntity) => {
    setUser(data);
  }, []);

  const [partnerLogout, { loading: loggingOut }] = useLogoutMutation();

  useFullscreenLoading(loggingOut);

  const client = useApolloClient();

  const logout = useCallback(async () => {
    try {
      await partnerLogout({
        variables: {
          deviceId,
        },
      });
    } catch (error) {
      // Do nothing
    }

    await client.cache.reset();
    localStorage.clear();
    setIsLoggedIn(false);
  }, [client, deviceId, partnerLogout]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, isCheckedMe: !loading && called, login, logout, user, updateInfoUser }}>
      {children}
    </AuthContext.Provider>
  );
};
