import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const Page: React.FC = () => {
  const navigation = useNavigate();
  return (
    <Result
      status="500"
      title="TÍNH NĂNG ĐANG XÂY DỰNG"
      subTitle="Tính năng đang xây dựng! Chúng tôi đang nỗ lực để mang đến cho bạn trải nghiệm tốt nhất!"
      extra={
        <Button type="primary" onClick={() => navigation('/')}>
          Về trang chủ
        </Button>
      }
    />
  );
};

export const ComingSoon = React.memo(Page);
