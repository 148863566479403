import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import PACKAGE from '../../package.json';
import { STORAGE_KEY } from '../constants/key';

const httpLink = new HttpLink({
  uri: `/graphql`,
  includeExtensions: true,
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    process.env.REACT_APP_SUBSCRIPTION_URI ?? `${PACKAGE.proxy.replace(/http|https/, 'ws')}/graphql`,
    {
      connectionParams: {
        authorization: `Bearer ${localStorage.getItem(STORAGE_KEY.accessToken)}`,
      },
      reconnect: true,
    },
  ),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  name: 'mobile',
  link: splitLink,
  cache: new InMemoryCache(),

  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
  },
});
