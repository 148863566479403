import { Button, Spin, notification } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Address, Calendar, TimeIcon } from '../../assets/icon';
import { SubHeader } from '../../components';
import { AppRoutes, showGraphqlMessageError } from '../../helpers';
import { usePartnerGetCourseQuery } from '../../graphql/queries/partnerGetCourse.generated';
import { useEnrollCourseMutation } from '../../graphql/mutations/enrollCourse.generated';
import { numberWithDots } from '../../helpers/utils';
import { FORMAT_DATE } from '../../constants';

const CourseDetail = () => {
  const { id } = useParams();

  const { data, loading, refetch } = usePartnerGetCourseQuery({ variables: { id: id as string }, skip: !id });
  const course = useMemo(() => data?.partnerGetCourse, [data]);

  const [enrollCourse, { loading: loadingEnrollCourse }] = useEnrollCourseMutation({
    onCompleted: () => {
      notification.success({ message: 'Ghi danh thành công' });
      refetch();
    },
    onError: (error) => showGraphqlMessageError(error),
  });

  const handleEnrollCourse = async () => {
    await enrollCourse({ variables: { id: id as string } });
  };

  return (
    <div>
      <SubHeader
        items={[
          {
            title: 'Trang chủ',
            to: '/',
          },
          {
            title: 'Khóa học',
            to: `${AppRoutes.courses.index}`,
          },
          {
            title: 'Chi tiết khóa học',
          },
        ]}
      />

      <Spin spinning={loading}>
        <div className="m-5 p-10 flex  gap-5">
          <div className="w-[55%] flex flex-col gap-5">
            <div>
              <h2 className="font-semibold mb-1.5 text-[28px]">{course?.name}</h2>
              <span className="text-base">{course?.content}</span>
            </div>
            <div className="flex gap-4 ">
              <div className="flex flex-col  ">
                <h2 className="text-base font-medium">{course?.teacher}</h2>
                <span className="text-base text-[#676E72] h-5 ">{course?.teacherDescription}</span>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <TimeIcon></TimeIcon>
                <span>
                  Thời gian học:{' '}
                  {`${dayjs(course?.startDate).format(FORMAT_DATE)} - ${dayjs(course?.endDate).format(FORMAT_DATE)}`}
                </span>
              </div>
              <div className="flex gap-2">
                <Calendar></Calendar>
                <span>Ngày khai giảng: {dayjs(course?.openDate).format('DD/MM/YYYY')}</span>
              </div>
              <div className="flex gap-2">
                <Address></Address>
                <span>Địa điểm học: {course?.address}</span>
              </div>
              <div className="flex gap-2">
                <Address></Address>
                <p>Video giới thiệu khóa học:</p>
              </div>
              <a href={course?.videoUrl as string} target="_blank" rel="noreferrer">
                {course?.videoUrl}
              </a>
            </div>
            <span className="font-semibold text-2xl">{numberWithDots(course?.price ?? 0)} đ</span>
            <Button
              loading={loadingEnrollCourse}
              type="primary"
              className="w-[140px]"
              onClick={handleEnrollCourse}
              disabled={course?.isEnrolled == true || loadingEnrollCourse}
            >
              Ghi danh
            </Button>
          </div>
          <div className="w-[45%]">
            <img className="w-full object-cover" src={`${course?.banner?.fullOriginalUrl}`} alt="" />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default CourseDetail;
