import { memo, useMemo } from 'react';
import { Form, Rate, Spin, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import '../../style.less';

import { UserCard } from '../../../../components/user-card';
import { ModalCustomize } from '../../../../components';
import { validationMessage } from '../../../../constants';
import { usePartnerBookingQuery } from '../../../../graphql/queries/partnerBooking.generated';
import { usePartnerCreateReviewMutation } from '../../../../graphql/mutations/partnerCreateReview.generated';

type TProps = {
  bookingId: string;
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
};

type FormData = {
  star: number;
  comment: string;
};

export const ReviewCustomer = memo(({ bookingId, onOk, ...props }: TProps) => {
  const { data, loading } = usePartnerBookingQuery({
    variables: { id: bookingId },
    fetchPolicy: 'cache-and-network',
    skip: !bookingId,
  });
  const booking = useMemo(() => data?.partnerBooking, [data]);

  const [CreateReviewApi, { loading: reviewing }] = usePartnerCreateReviewMutation({
    onError(error) {
      notification.error({
        message: 'Gửi đánh giá thất bại',
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: 'Gửi đánh giá thành công',
        description: `Cảm ơn bạn đã đánh giá khách hàng ${booking?.user?.fullname}`,
      });
      onOk();
    },
  });

  const onFinish = (values: FormData) => {
    if (!booking) return;
    CreateReviewApi({
      variables: {
        input: {
          ...values,
          bookingId,
          personEvaluatedId: booking?.userId,
        },
      },
    });
    //
  };

  if (!booking || !booking?.user) return null;
  return (
    <ModalCustomize
      title="Đánh giá khách hàng"
      {...props}
      okText="Gửi đánh giá"
      okButtonProps={{
        form: 'review-customer',
        htmlType: 'submit',
        disabled: reviewing,
      }}
    >
      <Spin spinning={loading || reviewing}>
        <Form
          size="small"
          id="review-customer"
          initialValues={{
            star: 5,
          }}
          onFinish={onFinish}
          layout="vertical"
        >
          <div className="mt-24px">
            <UserCard
              userInfo={{
                name: booking?.user?.fullname as string,
                avatar: booking?.user?.avatar ? (booking?.user?.avatar.fullThumbUrl as string) : '',
                phoneNumber: booking?.user?.phone as string,
              }}
            />
          </div>
          <Form.Item
            className="mt-12px"
            label={<span className="text-14px leading-20px font-semibold">Độ thân thiện</span>}
            name={'star'}
            rules={[
              {
                validator(rule, value) {
                  if (!value) return Promise.reject(new Error('Vui lòng đánh giá độ thân thiện.'));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Rate className="text-[0px]" style={{ height: 30 }} />
          </Form.Item>
          <Form.Item
            className="mt-12px"
            label=""
            name="comment"
            rules={[
              {
                required: true,
                message: validationMessage.required,
              },
            ]}
            normalize={(e) => e.trimStart()}
          >
            <TextArea placeholder="Nhận xét về khách hàng này..." maxLength={1000} showCount />
          </Form.Item>
        </Form>
      </Spin>
    </ModalCustomize>
  );
});
