import { createBrowserRouter } from 'react-router-dom';

import {
  Login,
  References,
  ListRequestRepair,
  Setting,
  UIComponent,
  DetailRequestRepair,
  Home,
  AccountScreen,
  Technicians,
  TechnicianCreate,
  Products,
  ProductCreate,
  Profile,
  Promition,
  Order,
  DetailOrder,
  Reviews,
  Courses,
  Feedback,
  FeedbackCreate,
  FeedbackDetail,
  Revenua,
  Survey,
  SurveyDetail,
  Quote,
  QuoteDetail,
  Warehouse,
  WarehouseDetail,
  TechnicianRequestRepairDetail,
  News,
  NewsDetail,
} from '../screens';
import { PrivateRoute } from '../components';
import { MainLayout } from '../layouts';
import { AuthLayout } from '../layouts/auth-layout';
import TechnicianDetail from '../screens/technician/detai';
import ProductForm from '../screens/products/form';
import { ComingSoon } from '../layouts/coming-soon';
import { PromitionForm } from '../screens/promotion/form';
import { PartnerTypeEnum } from '../graphql/type.interface';
import { PrivateContent } from '../components/private-content';
import CourseDetail from '../screens/courses/detail';

import { AppRoutes } from './app-routes';

export const useRouter = () => {
  return createBrowserRouter([
    // {
    //   path: '/',
    //   element: <Home />,
    // },
    {
      path: AppRoutes.auth.index,
      element: <PrivateRoute layout={AuthLayout} isAuthRoute />,
      children: [
        {
          path: AppRoutes.auth.login,
          element: <Login />,
        },
        // {
        //   path: AppRoutes.auth.register,
        //   element: <Register />,
        // },
      ],
    },
    {
      path: AppRoutes.home,
      element: <PrivateRoute layout={MainLayout} isPrivate />,
      children: [
        {
          path: AppRoutes.home,
          element: <Home />,
        },
        {
          path: AppRoutes.dashboard,
          element: <Home />,
        },
        {
          path: AppRoutes.requestRepair.index,
          element: <ListRequestRepair />,
        },
        {
          path: AppRoutes.requestRepair.detail,
          element: <DetailRequestRepair />,
        },
        {
          path: AppRoutes.profile,
          element: <Profile />,
        },
        {
          path: AppRoutes.setting,
          element: <Setting />,
        },
        {
          path: AppRoutes.references,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.FREELANCER_TECHNICIAN, PartnerTypeEnum.TECHNICIAN]}>
              <References />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.profile,
          element: <AccountScreen />,
        },
        {
          path: AppRoutes.technician.list,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <Technicians />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.technician.detail,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <TechnicianDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.technician.edit,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <TechnicianDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.technician.create,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <TechnicianCreate />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.technician.detailRepair,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <TechnicianRequestRepairDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.products.list,
          element: <Products />,
        },
        {
          path: AppRoutes.products.create,
          element: <ProductCreate />,
        },
        {
          path: AppRoutes.products.detail,
          element: <ProductForm isDetail />,
        },
        {
          path: AppRoutes.products.edit,
          element: <ProductForm isEdit />,
        },
        {
          path: AppRoutes.orders.index,
          element: <Order />,
        },
        {
          path: AppRoutes.orders.detail,
          element: <DetailOrder />,
        },
        {
          path: AppRoutes.quotes.index,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <Quote />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.quotes.detail,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <QuoteDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.promotions.list,
          element: <Promition />,
        },
        {
          path: AppRoutes.promotions.create,
          element: <PromitionForm />,
        },
        {
          path: AppRoutes.promotions.detail,
          element: <PromitionForm isDetail />,
        },
        {
          path: AppRoutes.promotions.edit,
          element: <PromitionForm isEdit />,
        },
        {
          path: AppRoutes.promotions.create,
          element: <ComingSoon />,
        },
        {
          path: AppRoutes.review.store,
          element: <Reviews />,
        },
        {
          path: AppRoutes.review.service,
          element: <Reviews />,
        },
        // COURSES
        {
          path: AppRoutes.courses.index,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN]}>
              <Courses />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.courses.detail,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN]}>
              <CourseDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.feedback.index,
          element: <Feedback />,
        },
        {
          path: AppRoutes.feedback.create,
          element: <FeedbackCreate />,
        },
        {
          path: AppRoutes.feedback.detail,
          element: <FeedbackDetail />,
        },
        {
          path: AppRoutes.revenue,
          element: <Revenua />,
        },
        {
          path: AppRoutes.survey.index,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN]}>
              <Survey />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.survey.detail,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.TECHNICIAN, PartnerTypeEnum.FREELANCER_TECHNICIAN]}>
              <SurveyDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.warehouse.index,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <Warehouse />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.warehouse.detail,
          element: (
            <PrivateContent authority={[PartnerTypeEnum.AGENCY]}>
              <WarehouseDetail />
            </PrivateContent>
          ),
        },
        {
          path: AppRoutes.news.list.value,
          element: <News />,
        },
        {
          path: AppRoutes.news.detail.value,
          element: <NewsDetail />,
        },
      ],
    },
    {
      path: '/ui-components',
      element: <UIComponent />,
    },
  ]);
};
