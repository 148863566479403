import { memo } from 'react';

import { AppRouter } from './app-router';
import { FullscreenLoading } from './components';
import { useAuth } from './contexts';

export const AppContainer = memo(() => {
  const { isCheckedMe } = useAuth();

  if (!isCheckedMe) {
    return <FullscreenLoading />;
  }

  return <AppRouter />;
});
