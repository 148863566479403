export const validationMessage = {
  required: 'Trường này là bắt buộc',
  email: {
    notValid: 'Email không đúng định dạng',
  },
  phone: {
    notValid: 'Số điện thoại không đúng định dạng',
  },
  cccd: {
    notValid: 'Số CMND/CCCD không đúng định dạng',
  },
  cardNumber: {
    notValid: 'Số thẻ không đúng định dạng',
  },
};
