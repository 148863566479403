import { PropsWithChildren } from 'react';

export const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="w-screen h-screen min-w-[1280px]">
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-2 overflow-hidden">
          <img src={'/img/auth-banner.png'} alt="authentication-banner" className="w-full h-full object-cover" />
        </div>
        <div className="col-span-3 h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};
