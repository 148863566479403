import { Suspense, lazy } from 'react';

import { ProgressLoading } from '../components';

const LazyLayout = (importStatement: () => Promise<any>) => {
  const Component = lazy(importStatement);

  return (
    <Suspense fallback={<ProgressLoading />}>
      <Component />
    </Suspense>
  );
};

export const Home = () => LazyLayout(() => import('./home'));
export const Login = () => LazyLayout(() => import('./login'));
export const Register = () => LazyLayout(() => import('./register'));
export const UIComponent = () => LazyLayout(() => import('./ui-components'));
export const Dashboard = () => LazyLayout(() => import('./dashboard'));
export const ListRequestRepair = () => LazyLayout(() => import('./request-repair'));
export const DetailRequestRepair = () => LazyLayout(() => import('./request-repair/detail'));
export const Setting = () => LazyLayout(() => import('./setting'));
export const References = () => LazyLayout(() => import('./references'));
export const AccountScreen = () => LazyLayout(() => import('./account'));
export const Technicians = () => LazyLayout(() => import('./technician'));
export const TechnicianCreate = () => LazyLayout(() => import('./technician/create'));
export const TechnicianRequestRepairDetail = () =>
  LazyLayout(() => import('./technician/request-repair-technician-detail'));
export const Products = () => LazyLayout(() => import('./products'));
export const ProductCreate = () => LazyLayout(() => import('./products/create-product'));
export const Profile = () => LazyLayout(() => import('./profile'));
export const Promition = () => LazyLayout(() => import('./promotion'));
export const Order = () => LazyLayout(() => import('./order'));
export const DetailOrder = () => LazyLayout(() => import('./order/detail'));
export const Reviews = () => LazyLayout(() => import('./reviews'));
export const Courses = () => LazyLayout(() => import('./courses'));
export const Feedback = () => LazyLayout(() => import('./feedback'));
export const FeedbackDetail = () => LazyLayout(() => import('./feedback/detail'));
export const FeedbackCreate = () => LazyLayout(() => import('./feedback/create'));
export const Revenua = () => LazyLayout(() => import('./revenue'));
export const Survey = () => LazyLayout(() => import('./survey'));
export const SurveyDetail = () => LazyLayout(() => import('./survey/detail'));
export const Quote = () => LazyLayout(() => import('./quote'));
export const QuoteDetail = () => LazyLayout(() => import('./quote/detail'));
export const Warehouse = () => LazyLayout(() => import('./warehouse'));
export const WarehouseDetail = () => LazyLayout(() => import('./warehouse/detail'));
export const News = () => LazyLayout(() => import('./news'));
export const NewsDetail = () => LazyLayout(() => import('./news/detail'));
