import { Modal } from 'antd';
import { PropsWithChildren, ReactNode, createContext, memo, useCallback, useContext, useState } from 'react';

type DialogOptions = {
  title: string | ReactNode;
  content: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  onOK?: () => Promise<any> | void;
  onCancel?: () => Promise<any> | void;
};

type ContextProps = {
  showDialog(options: DialogOptions): void;
};

const DialogContext = createContext<ContextProps>({
  showDialog() {
    throw new Error('not-ready');
  },
});

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = memo(({ children }: PropsWithChildren) => {
  const [dialog, setDialog] = useState<DialogOptions | undefined>();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const showDialog = useCallback((options: DialogOptions) => {
    setDialog(options);
    setDialogVisible(true);
  }, []);

  const resetShowdiaLog = useCallback(() => {
    setDialog(undefined);
    setDialogVisible(false);
  }, []);

  return (
    <DialogContext.Provider value={{ showDialog }}>
      {children}
      <Modal
        open={dialogVisible}
        title={dialog?.title}
        okText={dialog?.confirmText}
        onOk={async () => {
          await dialog?.onOK?.();
          resetShowdiaLog();
        }}
        onCancel={async () => {
          await dialog?.onCancel?.();
          resetShowdiaLog();
        }}
        cancelText={dialog?.cancelText}
      >
        {dialog?.content}
      </Modal>
    </DialogContext.Provider>
  );
});
