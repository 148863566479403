import { Spin } from 'antd';
import { PropsWithChildren, createContext, memo, useCallback, useContext, useState } from 'react';

type ContextProps = {
  showFullscreenLoading(val: boolean): void;
};

const FullScreenLoadingContext = createContext<ContextProps>({
  showFullscreenLoading() {
    //
  },
});

export const FullScreenLoadingProvider = memo(({ children }: PropsWithChildren) => {
  const [visible, setVisible] = useState(false);

  const showFullscreenLoading = useCallback((val: boolean) => setVisible(val), []);

  return (
    <FullScreenLoadingContext.Provider value={{ showFullscreenLoading }}>
      <Spin spinning={visible} className="min-h-screen" rootClassName="bg-black bg-opacity-70">
        {children}
      </Spin>
    </FullScreenLoadingContext.Provider>
  );
});

export const useFullscreenLoading = (loading?: boolean) => {
  const context = useContext(FullScreenLoadingContext);

  context.showFullscreenLoading(loading === true);

  return context;
};
