import { Divider } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { NoRequestRepairIcon } from '../../../assets/icon';
import {
  convertBookingStatus,
  getNameCategoriesEntity,
  numberWithDots,
  serialColumnTable,
} from '../../../helpers/utils';
import { AppRoutes } from '../../../helpers';
import { BookingStatusEnum } from '../../../graphql/type.interface';
import {
  PartnerBookingsDocument,
  PartnerBookingsQueryResponse,
  usePartnerBookingsQuery,
} from '../../../graphql/queries/partnerBookings.generated';
import { FORMAT_TIME } from '../../../constants';
import { MasterTable } from '../../../components';
import { useTechnicianArrivingBookingMutation } from '../../../graphql/mutations/technicianArrivingBooking.generated';
import { PartnerBookingDocument } from '../../../graphql/queries/partnerBooking.generated';
import { REQUEST_REPAIR_KEYS } from '..';

import ModalGroup from './modal/modal-group';

type Props = {
  title?: string;
  activeKey: string;
  isAgency?: boolean;
};

export const TYPE_MODAL = {
  CANCEL_REPAIR: 'CANCEL_REPAIR',
  ASSIGN_TECHNICIAN: 'ASSIGN_TECHNICIAN',
  RESCHEDULE: 'RESCHEDULE',
  REVIEW: 'REVIEW',
};

export const TableRequest = memo(({ activeKey, title, isAgency }: Props) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    filters: null,
    isActive: null,
    page: 1,
    limit: 10,
    statuses: [BookingStatusEnum.WAIT_FOR_CONFIRM, BookingStatusEnum.ASSIGNED_TECHNICIAN],
  });
  const { data: rs, loading } = usePartnerBookingsQuery({
    variables: filter,
    fetchPolicy: 'cache-and-network',
  });
  const data = useMemo(() => (rs?.partnerBookings?.items || []).map((item) => ({ ...item, key: item.id })), [rs]);

  const [technicianArrivingApi, { loading: arriving }] = useTechnicianArrivingBookingMutation({});
  const technicianArriving = useCallback(
    (bookingId: string) => {
      technicianArrivingApi({
        variables: { input: { bookingId } },
        refetchQueries: [PartnerBookingDocument, PartnerBookingsDocument],
      });
    },
    [technicianArrivingApi],
  );

  const [bookingId, setBookingId] = useState<string | undefined>();
  const [typeModal, setTypeModal] = useState<string | undefined>();

  const handleOpenForm = useCallback((type: string, value: string | PartnerBookingsQueryResponse) => {
    setBookingId(value as string);
    setTypeModal(type);
  }, []);

  const columnsWaiting: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'STT',
        key: 'index',
        dataIndex: 'index',
        align: 'center',
        width: '5%',
        render: (_id, _recode, index) => serialColumnTable(filter.page, index),
      },
      {
        title: 'Mã YCSC',
        dataIndex: 'code',
        key: 'code',
        width: '10%',
        render: (code, row) => <Link to={AppRoutes.requestRepair.detailId(row.id)}>{code}</Link>,
      },
      {
        title: 'Thiết bị gặp sự cố',
        dataIndex: 'vehicle',
        key: 'vehicle',
        width: '25%',
        ellipsis: true,
        render: (item) => (
          <div className="flex flex-row items-center break-words">
            <img src={item.avatar ? item.avatar.fullThumbUrl : ''} alt="" width={32} height={32} className="rounded" />
            <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
              <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                {item.name}
              </div>
              <div className="mt-[4px] text-[13px] leading-[18px] text-grayscale-gray whitespace-nowrap text-ellipsis overflow-hidden">
                {item.mapAddress}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Hiện tượng hư hỏng',
        dataIndex: 'problems',
        key: 'problems',
        width: '15%',
        ellipsis: true,
        render: (problems) => getNameCategoriesEntity(problems),
      },
      {
        title: 'Khách hàng',
        dataIndex: 'user',
        key: 'user',
        width: '20%',
        render: (record) => (
          <div className="flex flex-row items-center break-words">
            <img
              src={record.avatar ? record.avatar.fullThumbUrl : ''}
              width={32}
              height={32}
              className="rounded-full"
            />
            <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
              <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                {record.fullname}
              </div>
              <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                {record.phone}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Phí di chuyển',
        dataIndex: 'transportFee',
        key: 'price',
        width: '10%',
        render: (_, record) => {
          return (
            <div>
              <div className="leading-[20px] text-yankees-blue">{record.transportDistance} KM</div>
              <div className="font-semibold leading-[20px] text-yankees-blue">{numberWithDots(_)} đ</div>
            </div>
          );
        },
      },
      {
        title: 'Tùy chọn',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: isAgency ? '10%' : '20%',
        render: (id: string, record) => {
          if (isAgency) {
            return (
              <div className="flex flex-row items-center justify-between">
                <span
                  className="hover:cursor-pointer text-vermilion text-[14px] leading-[20px]"
                  onClick={() => {
                    handleOpenForm(TYPE_MODAL.CANCEL_REPAIR, id);
                  }}
                >
                  Từ chối
                </span>
                <Divider type="vertical" />
                {record?.status !== BookingStatusEnum.ASSIGNED_TECHNICIAN ? (
                  <span
                    className="hover:cursor-pointer text-vivid-cerulean text-[14px] leading-[20px]"
                    onClick={() => {
                      handleOpenForm(TYPE_MODAL.ASSIGN_TECHNICIAN, id);
                    }}
                  >
                    Nhận
                  </span>
                ) : (
                  <span
                    className="hover:cursor-pointer text-vivid-cerulean text-[14px] leading-[20px]"
                    onClick={() => {
                      navigate(AppRoutes.requestRepair.detailId(record.id));
                    }}
                  >
                    Xem chi tiết
                  </span>
                )}
              </div>
            );
          }
          return (
            <div className="flex flex-row items-center justify-between">
              <span
                className="hover:cursor-pointer text-vermilion text-[14px] leading-[20px]"
                onClick={() => {
                  handleOpenForm(TYPE_MODAL.CANCEL_REPAIR, id);
                }}
              >
                Từ chối
              </span>
              <Divider type="vertical" />
              <span
                className="hover:cursor-pointer text-vivid-cerulean text-[14px] leading-[20px]"
                onClick={() => {
                  handleOpenForm(TYPE_MODAL.RESCHEDULE, id);
                }}
              >
                Hẹn ngày đến
              </span>
              <Divider type="vertical" />
              <span
                className="hover:cursor-pointer text-primary text-[14px] leading-[20px]"
                onClick={() => technicianArriving(id)}
              >
                Đến ngay
              </span>
            </div>
          );
        },
      },
    ],
    [filter, handleOpenForm, isAgency, navigate, technicianArriving],
  );
  const column_quotation_settlement: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '5%',
        render: (id, recode, index) => serialColumnTable(filter.page, index),
      },
      {
        title: 'Mã YCSC',
        dataIndex: 'code',
        key: 'code',
        width: '10%',

        render: (code, row) => <Link to={AppRoutes.requestRepair.detailId(row.id)}>{code}</Link>,
      },
      {
        title: 'Thiết bị gặp sự cố',
        dataIndex: 'vehicle',
        key: 'vehicle',
        width: '25%',
        ellipsis: true,
        render: (_) => {
          if (!_) return null;
          return (
            <div className="flex flex-row items-center break-words">
              <img src={_.avatar ? _.avatar.fullThumbUrl : ''} alt="" width={32} height={32} className="rounded" />
              <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                  {_.name}
                </div>
                <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                  {_.mapAddress}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Hiện tượng hư hỏng',
        dataIndex: 'problems',
        key: 'problems',
        width: '15%',
        ellipsis: true,
        render: (problems) => getNameCategoriesEntity(problems),
      },
      {
        title: 'Khách hàng',
        dataIndex: 'user',
        key: 'user',
        width: '15%',
        render: (record) => (
          <div className="flex flex-row items-center break-words">
            <img src={_.get(record, 'avatar.fullThumUrl', '')} alt="" width={32} height={32} className="rounded-full" />
            <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
              <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                {record.fullname}
              </div>
              <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                {record.phone}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Kỹ thuật viên',
        dataIndex: 'technician',
        key: 'technician',
        width: '15%',
        render: (record) => {
          if (!record) return null;
          return (
            <div className="flex flex-row items-center break-words">
              <img
                src={record?.avatar ? record?.avatar?.fullThumbUrl : ''}
                alt=""
                width={32}
                height={32}
                className="rounded-full"
              />
              <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                  {record.fullname}
                </div>
                <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                  {record.phone}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Phí di chuyển',
        dataIndex: 'transportFee',
        key: 'price',
        width: '10%',
        render: (_, record) => {
          return (
            <div>
              <div className="leading-[20px] text-yankees-blue">{record.transportDistance} KM</div>
              <div className="font-semibold leading-[20px] text-yankees-blue">{numberWithDots(_)} đ</div>
            </div>
          );
        },
      },
      {
        title: 'Tùy chọn',
        dataIndex: 'option',
        key: 'option',
        width: '10%',
        render: (_, record) => (
          <div className="flex flex-row items-center justify-between">
            <Link to={AppRoutes.requestRepair.detailId(record.id)}>
              <span className="text-yellow text-[14px] leading-[20px]">Xem chi tiết</span>
            </Link>
          </div>
        ),
      },
    ],
    [filter],
  );

  const [column, setColumn] = useState(columnsWaiting);
  useEffect(() => {
    switch (activeKey) {
      // AGENCY CHO NHAN
      case REQUEST_REPAIR_KEYS.waiting.value:
        setFilter({
          ...filter,
          statuses: [REQUEST_REPAIR_KEYS.waiting.value],
        });
        setColumn(columnsWaiting);
        break;

      // KTV CHO KTV NHAN
      case REQUEST_REPAIR_KEYS.waiting_ktv.value:
        setFilter({
          ...filter,
          statuses: [BookingStatusEnum.ASSIGNED_TECHNICIAN, BookingStatusEnum.WAIT_FOR_CONFIRM],
        });
        setColumn(columnsWaiting);
        break;

      //  Agency: KTV DANG DEN
      //  KTV : DANG NHAN
      case REQUEST_REPAIR_KEYS.technician_arriving.value: {
        setFilter({
          ...filter,
          statuses: [BookingStatusEnum.TECHNICIAN_ARRIVING, BookingStatusEnum.RESCHEDULED],
        });
        const column_technician_arriving: ColumnsType<any> = [
          {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '60px',
            align: 'center',
            render: (id, recode, index) => serialColumnTable(filter.page, index),
          },
          {
            title: 'Mã YCSC',
            dataIndex: 'code',
            key: 'code',
            width: '10%',
            render: (code, row) => <Link to={AppRoutes.requestRepair.detailId(row.id)}>{code}</Link>,
          },
          {
            title: 'Thiết bị gặp sự cố',
            dataIndex: 'vehicle',
            key: 'vehicle',
            width: '22%',
            ellipsis: true,
            render: (_) => {
              if (!_) return null;
              return (
                <div className="flex flex-row items-center break-words">
                  <img src={_.avatar ? _.avatar.fullThumbUrl : ''} alt="" width={32} height={32} className="rounded" />
                  <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                    <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                      {_.name}
                    </div>
                    <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                      {_.mapAddress}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Hiện tượng hư hỏng',
            dataIndex: 'problems',
            key: 'problems',
            width: '15%',
            ellipsis: true,
            render: (problems) => getNameCategoriesEntity(problems),
          },
          {
            title: 'Khách hàng',
            dataIndex: 'user',
            key: 'user',
            width: '14%',
            render: (record) => (
              <div className="flex flex-row items-center break-words">
                <img
                  src={record.avatar ? record.avatar.fullThumbUrl : ''}
                  alt=""
                  width={32}
                  height={32}
                  className="rounded-full"
                />
                <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                  <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.fullname}
                  </div>
                  <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.phone}
                  </div>
                </div>
              </div>
            ),
          },
          {
            title: 'Kỹ thuật viên',
            dataIndex: 'technician',
            key: 'technician',
            width: '14%',
            render: (record) => {
              if (!record) return null;
              return (
                <div className="flex flex-row items-center break-words">
                  <img
                    src={record?.avatar ? record?.avatar?.fullThumbUrl : ''}
                    alt=""
                    width={32}
                    height={32}
                    className="rounded-full"
                  />
                  <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                    <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                      {record.fullname}
                    </div>
                    <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                      {record.phone}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Phí di chuyển',
            dataIndex: 'transportFee',
            key: 'price',
            width: '10%',
            render: (_, record) => {
              return (
                <div>
                  <div className="leading-[20px] text-yankees-blue">{record.transportDistance} KM</div>
                  <div className="font-semibold leading-[20px] text-yankees-blue">{numberWithDots(_)} đ</div>
                </div>
              );
            },
          },
          {
            title: 'Tùy chọn',
            dataIndex: 'option',
            key: 'option',
            width: isAgency ? 100 : 180,
            render: (_, row) => {
              if (isAgency) {
                return (
                  <div className="flex flex-row items-center justify-between">
                    <Link to={AppRoutes.requestRepair.detailId(row.id)}>
                      <span className="text-yellow text-[14px] leading-[20px]">Xem chi tiết</span>
                    </Link>
                  </div>
                );
              }
              return (
                <div className="flex flex-row items-center justify-between">
                  <span
                    className="hover:cursor-pointer text-vermilion text-[14px] leading-[20px]"
                    onClick={() => {
                      handleOpenForm(TYPE_MODAL.CANCEL_REPAIR, row.id);
                    }}
                  >
                    Từ chối
                  </span>
                  <Divider type="vertical" />
                  <Link to={AppRoutes.requestRepair.detailId(row.id)}>
                    <span className="text-yellow text-[14px] leading-[20px]">Xem chi tiết</span>
                  </Link>
                </div>
              );
            },
          },
        ];
        setColumn(column_technician_arriving);
        break;
      }

      // KIEM TRA VA BAO GIA
      case REQUEST_REPAIR_KEYS.quotation.value: {
        setFilter({
          ...filter,
          statuses: [
            BookingStatusEnum.TECHNICIAN_ARRIVED,
            BookingStatusEnum.QUOTATION_ACCEPTED,
            BookingStatusEnum.QUOTATION_REJECTED,
            BookingStatusEnum.QUOTATION_REQUESTED,
          ],
        });
        setColumn(column_quotation_settlement);
        break;
      }

      // DANG BAN GIAO
      case REQUEST_REPAIR_KEYS.settlement.value: {
        setFilter({
          ...filter,
          statuses: [
            BookingStatusEnum.SETTLEMENT_ACCEPTED,
            BookingStatusEnum.SETTLEMENT_REJECTED,
            BookingStatusEnum.SETTLEMENT_REQUESTED,
          ],
        });
        setColumn(column_quotation_settlement);
        break;
      }

      // HOAN THANH
      case REQUEST_REPAIR_KEYS.completed.value: {
        setFilter({
          ...filter,
          statuses: [BookingStatusEnum.COMPLETE],
        });
        const column_complete: ColumnsType<any> = [
          {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            align: 'center',
            render: (_id, _recode, index) => (filter?.page === 1 ? index + 1 : (filter?.page - 1) * 10 + (index + 1)),
          },
          {
            title: 'Mã YCSC',
            dataIndex: 'code',
            key: 'code',
            width: '10%',
            render: (code, row) => <Link to={AppRoutes.requestRepair.detailId(row.id)}>{code}</Link>,
          },
          {
            title: 'Thời gian đặt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => {
              return dayjs(createdAt).format(FORMAT_TIME);
            },
            width: '10%',
          },
          {
            title: 'Thời gian hoàn thành',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_value) => {
              return dayjs().format(FORMAT_TIME);
            },
            width: '10%',
          },
          {
            title: 'Thiết bị gặp sự cố',
            dataIndex: 'vehicle',
            key: 'vehicle',
            width: '25%',
            ellipsis: true,
            render: (_) => {
              if (!_) return null;
              return (
                <div className="flex flex-row items-center break-words">
                  <img src={_.avatar ? _.avatar.fullThumbUrl : ''} alt="" width={32} height={32} className="rounded" />
                  <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                    <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                      {_.name}
                    </div>
                    <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                      {_.mapAddress}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Khách hàng',
            dataIndex: 'user',
            key: 'user',
            width: '15%',
            render: (record) => (
              <div className="flex flex-row items-center break-words">
                <img
                  src={_.get(record, 'avatar.fullThumUrl', '')}
                  alt=""
                  width={32}
                  height={32}
                  className="rounded-full"
                />
                <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                  <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.fullname}
                  </div>
                  <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.phone}
                  </div>
                </div>
              </div>
            ),
          },
          {
            title: 'Kỹ thuật viên',
            dataIndex: 'technician',
            key: 'technician',
            width: '15%',
            render: (record) => {
              if (!record) return null;
              return (
                <div className="flex flex-row items-center break-words">
                  <img
                    src={record?.avatar ? record?.avatar?.fullThumbUrl : ''}
                    alt=""
                    width={32}
                    height={32}
                    className="rounded-full"
                  />
                  <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                    <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                      {record.fullname}
                    </div>
                    <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                      {record.phone}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Tổng chi phí',
            dataIndex: 'settlementAccepted',
            key: 'settlementAccepted',
            width: '10%',
            render: (settlementAccepted) => (
              <span className="font-semibold">{numberWithDots(settlementAccepted?.total) + ' đ'}</span>
            ),
          },
          {
            title: 'Tùy chọn',
            dataIndex: 'option',
            key: 'option',
            width: '10%',
            render: (_, row) => (
              <div className="flex flex-row items-center justify-between">
                <Link to={AppRoutes.requestRepair.detailId(row.id)}>
                  <span className="text-yellow text-[14px] leading-[20px]">Xem chi tiết</span>
                </Link>
              </div>
            ),
          },
        ];
        setColumn(column_complete);
        break;
      }

      case REQUEST_REPAIR_KEYS.home_receiving.value: {
        setFilter({
          ...filter,
          statuses: isAgency
            ? [
                BookingStatusEnum.RESCHEDULED,
                BookingStatusEnum.ASSIGNED_TECHNICIAN,
                BookingStatusEnum.QUOTATION_ACCEPTED,
                BookingStatusEnum.QUOTATION_REJECTED,
                BookingStatusEnum.QUOTATION_REQUESTED,
                BookingStatusEnum.SETTLEMENT_ACCEPTED,
                BookingStatusEnum.SETTLEMENT_REJECTED,
                BookingStatusEnum.SETTLEMENT_REQUESTED,
                BookingStatusEnum.TECHNICIAN_ARRIVED,
                BookingStatusEnum.TECHNICIAN_ARRIVING,
              ]
            : [
                BookingStatusEnum.RESCHEDULED,
                BookingStatusEnum.QUOTATION_ACCEPTED,
                BookingStatusEnum.QUOTATION_REJECTED,
                BookingStatusEnum.QUOTATION_REQUESTED,
                BookingStatusEnum.SETTLEMENT_ACCEPTED,
                BookingStatusEnum.SETTLEMENT_REJECTED,
                BookingStatusEnum.SETTLEMENT_REQUESTED,
                BookingStatusEnum.TECHNICIAN_ARRIVED,
                BookingStatusEnum.TECHNICIAN_ARRIVING,
              ],
        });
        const column_home_receiving: ColumnsType<any> = [
          {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '60px',
            align: 'center',
            render: (id, recode, index) => serialColumnTable(filter.page, index),
          },
          {
            title: 'Mã YCSC',
            dataIndex: 'code',
            key: 'code',
            render: (code, row) => <Link to={AppRoutes.requestRepair.detailId(row.id)}>{code}</Link>,
          },
          {
            title: 'Thời gian đặt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createAt) => dayjs(createAt).format(FORMAT_TIME),
          },
          {
            title: 'Thiết bị gặp sự cố',
            dataIndex: 'vehicle',
            key: 'vehicle',
            width: '22%',
            ellipsis: true,
            render: (_) => {
              if (!_) return null;
              return (
                <div className="flex flex-row items-center break-words">
                  <img src={_.avatar ? _.avatar.fullThumbUrl : ''} alt="" width={32} height={32} className="rounded" />
                  <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                    <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                      {_.name}
                    </div>
                    <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                      {_.mapAddress}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Khách hàng',
            dataIndex: 'user',
            key: 'user',
            width: '14%',
            render: (record) => (
              <div className="flex flex-row items-center break-words">
                <img
                  src={record.avatar ? record.avatar.fullThumbUrl : ''}
                  alt=""
                  width={32}
                  height={32}
                  className="rounded-full"
                />
                <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                  <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.fullname}
                  </div>
                  <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.phone}
                  </div>
                </div>
              </div>
            ),
          },
          {
            title: 'Kỹ thuật viên phụ trách',
            dataIndex: 'technician',
            key: 'technician',
            width: '14%',
            render: (record) => {
              if (!record) return null;
              return (
                <div className="flex flex-row items-center break-words">
                  <img
                    src={record?.avatar ? record?.avatar?.fullThumbUrl : ''}
                    alt=""
                    width={32}
                    height={32}
                    className="rounded-full"
                  />
                  <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                    <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                      {record.fullname}
                    </div>
                    <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                      {record.phone}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render(status) {
              return convertBookingStatus(status, isAgency);
            },
          },
          {
            title: 'Tùy chọn',
            dataIndex: 'option',
            key: 'option',
            width: 100,
            render: (_, row) => {
              return (
                <div className="flex flex-row items-center justify-between">
                  <Link to={AppRoutes.requestRepair.detailId(row.id)}>
                    <span className="text-blue text-[14px] leading-[20px]">Xem chi tiết</span>
                  </Link>
                </div>
              );
            },
          },
        ];
        setColumn(column_home_receiving);
        break;
      }

      // DA HUY
      default: {
        setFilter({ ...filter, statuses: [BookingStatusEnum.CANCEL] });
        const column_cancel: ColumnsType<any> = [
          {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            align: 'center',
            render: (_id, _recode, index) => (filter?.page === 1 ? index + 1 : (filter?.page - 1) * 10 + (index + 1)),
          },
          {
            title: 'Mã YCSC',
            dataIndex: 'code',
            key: 'code',
            width: '10%',
            render: (code, row) => <Link to={AppRoutes.requestRepair.detailId(row.id)}>{code}</Link>,
          },
          {
            title: 'Thời gian đặt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => {
              return dayjs(createdAt).format(FORMAT_TIME);
            },
            width: '10%',
          },
          {
            title: 'Thời gian hủy',
            dataIndex: 'statusDetail',
            key: 'statusDetail',
            render: (statusDetail) => {
              return statusDetail?.createdAt && dayjs(statusDetail?.createdAt).format(FORMAT_TIME);
            },
            width: '10%',
          },
          {
            title: 'Thiết bị gặp sự cố',
            dataIndex: 'vehicle',
            key: 'vehicle',
            width: '25%',
            ellipsis: true,
            render: (_, record) => (
              <div className="flex flex-row items-center break-words">
                {record.avatar && record.avatar.fullThumbUrl && (
                  <img src={record.avatar.fullThumbUrl} alt="" width={32} height={32} className="rounded" />
                )}
                <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                  <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.name}
                  </div>
                  <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.description}
                  </div>
                </div>
              </div>
            ),
          },
          {
            title: 'Khách hàng',
            dataIndex: 'user',
            key: 'user',
            width: '15%',
            render: (record) => (
              <div className="flex flex-row items-center break-words">
                <img
                  src={record.avatar ? record.avatar.fullThumbUrl : ''}
                  alt=""
                  width={32}
                  height={32}
                  className="rounded-full"
                />
                <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                  <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.fullname}
                  </div>
                  <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.phone}
                  </div>
                </div>
              </div>
            ),
          },
          {
            title: 'Kỹ thuật viên',
            dataIndex: 'technician',
            key: 'technician',
            width: '15%',
            render: (record) => (
              <div className="flex flex-row items-center break-words">
                {record.avatar && record.avatar.fullThumbUrl && (
                  <img src={record.avatar.fullThumbUrl} alt="" width={32} height={32} className="rounded-full" />
                )}
                <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                  <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.fullname}
                  </div>
                  <div className="mt-[4px] text-[13px] leading-[18px] text-dim-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    {record.phone}
                  </div>
                </div>
              </div>
            ),
          },
          {
            title: 'Tùy chọn',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (id) => (
              <div className="flex flex-row items-center justify-between">
                <span
                  onClick={() => navigate(AppRoutes.requestRepair.detailId(id))}
                  className="text-yellow text-[14px] leading-[20px] hover:cursor-pointer"
                >
                  Xem chi tiết
                </span>
              </div>
            ),
          },
        ];
        setColumn(column_cancel);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, isAgency]);

  const renderEmpty = (
    <div className="mt-[20px] mb-[80px] w-full flex flex-col items-center justify-center">
      <NoRequestRepairIcon />
      <div>Chưa có Yêu cầu sửa chữa nào đang nhận</div>
    </div>
  );

  return (
    <div>
      <div className="font-semibold text-[16px] leading-[24px] text-yankees-blue pb-20px">
        {title ? title : `${data.length} Yêu cầu`}
      </div>
      {!data || (data && data.length === 0) ? (
        renderEmpty
      ) : (
        <div>
          <MasterTable
            bordered={false}
            loading={loading || arriving}
            data={data}
            columns={column}
            total={rs?.partnerBookings?.meta?.totalItems}
            filter={filter}
            setFilter={setFilter}
          />
          {bookingId && typeModal && (
            <ModalGroup
              bookingId={bookingId}
              type_modal={typeModal}
              setBookingId={setBookingId}
              setTypeModal={setTypeModal}
            />
          )}
        </div>
      )}
    </div>
  );
});
