import React, { useState, useEffect } from 'react';

import { TYPE_MODAL } from '../table';

import { CancelRepair } from './cancel-request-repair';
import { ScheduleRepair } from './schedule-request-reapair';
import { ReviewCustomer } from './review-customer';
import { AssignTechnician } from './assign-technician';

type Props = {
  bookingId: string;
  type_modal: string;
  setBookingId?: (value: string | undefined) => void;
  setTypeModal: (value: string | undefined) => void;
  onCompleted?: () => void;
};

export const ModalGroup = ({ bookingId, type_modal, setTypeModal, setBookingId, onCompleted }: Props) => {
  const [isOpenFormReject, setIsOpenFormReject] = useState<boolean>(false);
  const [isOpenSchedule, setIsOpenSchedule] = useState<boolean>(false);
  const [reviewCustomer, setReviewCustomer] = useState<boolean>(false);
  const [isOpenFormAssign, setIsOpenFormAssign] = useState<boolean>(false);

  const resetState = () => {
    setBookingId?.(undefined);
    setTypeModal(undefined);
  };

  const handleCloseModal = (type: string) => {
    resetState();
    onCompleted?.();

    if (type === TYPE_MODAL.CANCEL_REPAIR) return setIsOpenFormReject(false);
    if (type === TYPE_MODAL.REVIEW) return setReviewCustomer(false);
    if (type === TYPE_MODAL.ASSIGN_TECHNICIAN) return setIsOpenFormAssign(false);
    setIsOpenSchedule(false);
  };

  const handleOpenModal = (type: string) => {
    if (type === TYPE_MODAL.CANCEL_REPAIR) return setIsOpenFormReject(true);
    if (type === TYPE_MODAL.REVIEW) return setReviewCustomer(true);
    if (type === TYPE_MODAL.ASSIGN_TECHNICIAN) return setIsOpenFormAssign(true);
    setIsOpenSchedule(true);
  };

  useEffect(() => {
    handleOpenModal(type_modal);
  }, [type_modal]);

  return (
    <div>
      {isOpenFormReject && (
        <CancelRepair
          bookingId={bookingId}
          open={isOpenFormReject}
          onCancel={() => handleCloseModal(TYPE_MODAL.CANCEL_REPAIR)}
          onOk={() => handleCloseModal(TYPE_MODAL.CANCEL_REPAIR)}
        />
      )}
      {isOpenSchedule && (
        <ScheduleRepair
          bookingId={bookingId}
          open={isOpenSchedule}
          onCancel={() => handleCloseModal(TYPE_MODAL.RESCHEDULE)}
          onOk={() => handleCloseModal(TYPE_MODAL.RESCHEDULE)}
        />
      )}
      {reviewCustomer && (
        <ReviewCustomer
          bookingId={bookingId}
          open={reviewCustomer}
          onCancel={() => handleCloseModal(TYPE_MODAL.REVIEW)}
          onOk={() => handleCloseModal(TYPE_MODAL.REVIEW)}
        />
      )}
      {isOpenFormAssign && (
        <AssignTechnician
          bookingId={bookingId}
          open={isOpenFormAssign}
          onCancel={() => handleCloseModal(TYPE_MODAL.ASSIGN_TECHNICIAN)}
          onOk={() => handleCloseModal(TYPE_MODAL.ASSIGN_TECHNICIAN)}
        />
      )}
    </div>
  );
};

export default ModalGroup;
