import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';
import type { SelectProps } from 'antd';

import { useSearchPlacesAutocompleteLazyQuery } from '../../graphql/queries/searchPlacesAutocomplete.generated';
import { useGetPlaceDetailLazyQuery } from '../../graphql/queries/getPlaceDetail.generated';

export type Address = { latitude: number; longitude: number; mapAddress: string };

export type AddressFull = Address & { addressMoreInfo: string };

type Props = {
  defaultValues?: AddressFull;
  onChange?: (value: AddressFull) => void;
};

export const SearchAddress = memo(({ defaultValues, onChange }: Props) => {
  const searchTemp = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [value, setValue] = useState<any>(undefined);
  const [addressMapInfo, setAddressMapInfo] = useState<Address | undefined>();
  const [addressMoreInfo, setAddressMoreInfo] = useState<string>('');

  const [searchAsync, { loading: searching }] = useSearchPlacesAutocompleteLazyQuery({});
  const [getPlaceAsync, { loading: gettingPlace }] = useGetPlaceDetailLazyQuery({});

  const handleChange = useCallback(
    async (place_id: string, options: any) => {
      setValue(options?.label);
      const data = await getPlaceAsync({ variables: { place_id } });
      const place = data?.data?.getPlaceDetail;
      if (!place) return;
      const { lat, lng, address } = place;
      onChange?.({ longitude: lng, latitude: lat, mapAddress: address, addressMoreInfo });
      setAddressMapInfo({ longitude: lng, latitude: lat, mapAddress: address });
    },
    [addressMoreInfo, getPlaceAsync, onChange],
  );

  const handleSearch = useCallback(
    (keyword: string) => {
      if (!keyword) return;
      if (searchTemp.current) {
        clearTimeout(searchTemp.current);
        searchTemp.current = null;
      }
      searchTemp.current = setTimeout(async () => {
        const rs = await searchAsync({ variables: { input: { keyword } } });
        const data = rs?.data?.searchPlacesAutocomplete;
        setOptions(data?.map((item) => ({ label: item.address, value: item.place_id })));
      }, 300);
    },
    [searchAsync],
  );

  const handleChangeAddressMoreInfo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddressMoreInfo(e.target.value);
      onChange?.({
        longitude: addressMapInfo?.latitude as number,
        latitude: addressMapInfo?.latitude as number,
        mapAddress: addressMapInfo?.mapAddress as string,
        addressMoreInfo: e.target.value,
      });
    },
    [addressMapInfo, onChange],
  );

  useEffect(() => {
    if (defaultValues) {
      const addressInitial = {
        longitude: defaultValues?.latitude as number,
        latitude: defaultValues?.latitude as number,
        mapAddress: defaultValues?.mapAddress as string,
      };
      setAddressMapInfo({
        ...addressInitial,
      });
      setValue(defaultValues?.mapAddress);
      setAddressMoreInfo(defaultValues?.addressMoreInfo as string);
      onChange?.({
        ...addressInitial,
        addressMoreInfo: defaultValues?.addressMoreInfo as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={20} align={'middle'}>
      <Col lg={12} sm={24}>
        <Select
          showSearch
          loading={searching || gettingPlace}
          value={value}
          placeholder="Tỉnh/Thành phố, Quận/Huyện, Phường/Xã"
          maxLength={255}
          defaultActiveFirstOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          filterOption={false}
          options={options}
        />
      </Col>
      <Col lg={12} sm={24}>
        <Input
          maxLength={255}
          placeholder="Tên đường, Tòa nhà, Số nhà"
          value={addressMoreInfo}
          onChange={handleChangeAddressMoreInfo}
        />
      </Col>
    </Row>
  );
});
