import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { CategoryFragmentFragmentDoc } from '../fragments/category.fragment.generated';

const defaultOptions = {} as const;
export type PartnerBookingsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  statuses?: Types.InputMaybe<Array<Types.BookingStatusEnum> | Types.BookingStatusEnum>;
}>;

export type PartnerBookingsQueryResponse = { __typename?: 'Query' } & {
  partnerBookings: { __typename?: 'BookingConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'BookingEntity' } & Pick<
          Types.BookingEntity,
          | 'addressMoreInfo'
          | 'code'
          | 'createdAt'
          | 'deletedAt'
          | 'description'
          | 'id'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'partnerId'
          | 'problemTexts'
          | 'status'
          | 'technicianId'
          | 'transportDistance'
          | 'transportDuration'
          | 'transportFee'
          | 'updatedAt'
          | 'userId'
          | 'vehicleId'
        > & {
            partner: { __typename?: 'PartnerEntity' } & Pick<
              Types.PartnerEntity,
              'addressMoreInfo' | 'avatarId' | 'bank' | 'email' | 'fullname' | 'hotline' | 'id' | 'phone'
            > & {
                avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id'>>;
                qualifications?: Types.Maybe<
                  Array<
                    { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>
                  >
                >;
              };
            problems?: Types.Maybe<
              Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>>
            >;
            technician?: Types.Maybe<
              { __typename?: 'PartnerEntity' } & Pick<
                Types.PartnerEntity,
                'addressMoreInfo' | 'avatarId' | 'email' | 'fullname' | 'hotline' | 'id' | 'phone'
              > & {
                  avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl'>>;
                  qualifications?: Types.Maybe<
                    Array<
                      { __typename?: 'CategoryEntity' } & Pick<
                        Types.CategoryEntity,
                        'id' | 'isActive' | 'name' | 'type'
                      >
                    >
                  >;
                }
            >;
            user: { __typename?: 'UserEntity' } & Pick<
              Types.UserEntity,
              'address' | 'avatarId' | 'email' | 'fullname' | 'id' | 'phone'
            > & { avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl' | 'id'>> };
            statusDetail?: Types.Maybe<
              { __typename?: 'BookingStatusEntity' } & Pick<Types.BookingStatusEntity, 'status' | 'createdAt'>
            >;
            vehicle: { __typename?: 'VehicleEntity' } & Pick<
              Types.VehicleEntity,
              | 'addressMoreInfo'
              | 'avatarId'
              | 'createdAt'
              | 'deletedAt'
              | 'detail'
              | 'hidden'
              | 'id'
              | 'latitude'
              | 'longitude'
              | 'mapAddress'
              | 'name'
              | 'operatingNumber'
              | 'operatingUnit'
              | 'ordinalNumber'
              | 'serialNumber'
              | 'updatedAt'
              | 'userId'
              | 'vehicleRegistrationPlate'
              | 'vinNumber'
              | 'yearOfManufacture'
            > & {
                avatar?: Types.Maybe<
                  { __typename?: 'Media' } & Pick<
                    Types.Media,
                    | 'createdAt'
                    | 'fileSize'
                    | 'fullOriginalUrl'
                    | 'fullThumbUrl'
                    | 'id'
                    | 'isDeleted'
                    | 'mimeType'
                    | 'name'
                    | 'originalUrl'
                    | 'ownerId'
                    | 'thumbUrl'
                    | 'type'
                    | 'updatedAt'
                    | 'videoUrl'
                  >
                >;
                manufacturer?: Types.Maybe<
                  { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
                >;
                model?: Types.Maybe<
                  { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
                >;
                origin?: Types.Maybe<
                  { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
                >;
              };
            settlementAccepted?: Types.Maybe<
              { __typename?: 'SettlementEntity' } & Pick<Types.SettlementEntity, 'id' | 'total'>
            >;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const PartnerBookingsDocument = gql`
  query partnerBookings(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $statuses: [BookingStatusEnum!]
  ) {
    partnerBookings(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      statuses: $statuses
    ) {
      items {
        addressMoreInfo
        code
        createdAt
        deletedAt
        description
        id
        latitude
        longitude
        mapAddress
        partner {
          addressMoreInfo
          avatar {
            fullThumbUrl
            id
          }
          avatarId
          bank
          email
          fullname
          hotline
          id
          phone
          qualifications {
            id
            isActive
            name
            type
          }
        }
        partnerId
        problemTexts
        problems {
          id
          isActive
          name
          type
        }
        status
        technician {
          addressMoreInfo
          avatar {
            fullThumbUrl
          }
          avatarId
          email
          fullname
          hotline
          id
          phone
          qualifications {
            id
            isActive
            name
            type
          }
        }
        technicianId
        transportDistance
        transportDuration
        transportFee
        updatedAt
        user {
          address
          avatar {
            fullThumbUrl
            id
          }
          avatarId
          email
          fullname
          id
          phone
        }
        statusDetail {
          status
          createdAt
        }
        userId
        vehicle {
          addressMoreInfo
          avatar {
            createdAt
            fileSize
            fullOriginalUrl
            fullThumbUrl
            id
            isDeleted
            mimeType
            name
            originalUrl
            ownerId
            thumbUrl
            type
            updatedAt
            videoUrl
          }
          avatarId
          createdAt
          deletedAt
          detail
          hidden
          id
          latitude
          longitude
          manufacturer {
            ...CategoryFragment
          }
          mapAddress
          model {
            ...CategoryFragment
          }
          name
          operatingNumber
          operatingUnit
          ordinalNumber
          origin {
            ...CategoryFragment
          }
          serialNumber
          updatedAt
          userId
          vehicleRegistrationPlate
          vinNumber
          yearOfManufacture
        }
        vehicleId
        settlementAccepted {
          id
          total
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
  ${CategoryFragmentFragmentDoc}
`;
export function usePartnerBookingsQuery(
  baseOptions?: Apollo.QueryHookOptions<PartnerBookingsQueryResponse, PartnerBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerBookingsQueryResponse, PartnerBookingsQueryVariables>(PartnerBookingsDocument, options);
}
export function usePartnerBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerBookingsQueryResponse, PartnerBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerBookingsQueryResponse, PartnerBookingsQueryVariables>(
    PartnerBookingsDocument,
    options,
  );
}
export type PartnerBookingsQueryHookResult = ReturnType<typeof usePartnerBookingsQuery>;
export type PartnerBookingsLazyQueryHookResult = ReturnType<typeof usePartnerBookingsLazyQuery>;
export type PartnerBookingsQueryResult = Apollo.QueryResult<
  PartnerBookingsQueryResponse,
  PartnerBookingsQueryVariables
>;
