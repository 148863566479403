import { memo, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import { Rate } from 'antd';
import dayjs from 'dayjs';

import { MasterTable } from '../../../../components';
import { serialColumnTable } from '../../../../helpers/utils';
import { useReviewsOfPartnerQuery } from '../../../../graphql/queries/reviewsOfPartner.generated';
import { ReviewTypeEnum } from '../../../../graphql/type.interface';
import { FORMAT_TIME } from '../../../../constants';

export const Review = memo(() => {
  const { id = '' } = useParams();
  const [params, setParams] = useState({
    filters: null,
    isActive: null,
    isApproved: null,
    search: '',
    limit: 10,
    page: 1,
  });

  const { data, loading } = useReviewsOfPartnerQuery({
    variables: {
      partnerId: id,
      ...params,
      type: ReviewTypeEnum.CLIENT_TECHNICIAN,
    },
    skip: !id,
  });
  const reviews = useMemo(() => data?.reviewsOfPartner?.items ?? [], [data]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'name',
      render: (_, __, index: number) => serialColumnTable(params?.page as number, index),
      align: 'center',
      width: '5%',
    },
    {
      title: 'Mã yêu cầu',
      key: 'bookingId',
      dataIndex: 'bookingId',
    },
    {
      title: 'Thời gian',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => dayjs(value).format(FORMAT_TIME),
    },
    {
      title: 'Sao',
      key: 'star',
      dataIndex: 'star',
      render(star) {
        return <Rate disabled value={star} />;
      },
    },
    {
      title: 'Nhận xét',
      key: 'comment',
      dataIndex: 'comment',
    },
  ];

  return (
    <div className="bg-white m-20px p-20px">
      <MasterTable
        columns={columns}
        data={reviews}
        loading={loading}
        filter={params}
        total={data?.reviewsOfPartner?.meta?.totalItems}
        setFilter={setParams}
      />
    </div>
  );
});
