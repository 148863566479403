import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './button.less';
import { memo } from 'react';

export interface AppButtonProps extends Omit<ButtonProps, 'type'> {
  type?:
    | '_1C3F49'
    | '_EEEEEE'
    | '_F5F5F5'
    | '_00BEEB'
    | '_0E7E99'
    | '_888888'
    | '_42DABF'
    | '_DDDDDD'
    | '_FFFFFF'
    | '_EEEEEE_D'
    | '_707070'
    | '_BD0034'
    | '_FCC103'
    | '_925DD8'
    | '_EE3554'
    | '_404DFF'
    | '_FF8EA1'
    | '_5CA5FF'
    | '_6A5F00'
    | '_000000'
    | '_008704'
    | '_FF7700'
    | '_FFDD00'
    | '_7A4A25'
    | '_D649C4'
    | '_989834'
    | '_D63120'
    | '_03A1FA'
    | '_BE7944'; // 3
}

export const AppButton = memo(({ type, ...props }: AppButtonProps) => {
  return (
    <div className="imp-wrapper inline-block leading-none select-none">
      <Button type={type as any} {...props} />
    </div>
  );
});
