import { PropsWithChildren, memo } from 'react';
import classNames from 'classnames';

import { CheckedCircleIcon, ErrorIcon, InfoCircle } from '../../assets/icon';

type Props = PropsWithChildren<{
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  info?: boolean;
  gray?: boolean;
  dashed?: boolean;
  className?: string;
}>;

export const ResponseMessage = memo(({ gray, error, success, children, warning, dashed, info, className }: Props) => {
  if (!children) return null;
  return (
    <div
      className={classNames(
        `px-[16px] py-[12px] rounded flex flex-row items-center text-[13px] text-yankees-blue 
        ${error && 'bg-linen'} 
        ${warning && 'bg-primarys-lighter'}
        ${info && 'bg-blue-light'}
        ${dashed && 'bg-[#EEEEEE]'}
        ${gray && 'bg-grayscale-border'}
        ${success && 'bg-bright-green-gray'}`,
        className,
      )}
    >
      {error && <ErrorIcon className="mr-[12px]" />}
      {success && <CheckedCircleIcon className="mr-[12px]" />}
      {warning && <InfoCircle fill="#FF9D0A" className="mr-[12px]" />}
      {info && <InfoCircle fill="#03A1FA" className="mr-[12px]" />}
      {dashed && <InfoCircle fill="#ccc" className="mr-[12px]" />}
      {gray && <InfoCircle fill="#676E72" className="mr-[12px]" />}
      {children}
    </div>
  );
});
