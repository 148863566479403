import React, { memo } from 'react';
import { Col, Form, Row, Select } from 'antd';

import { ModalCustomize } from '../../components';
import { validationMessage } from '../../constants';
import { CategoryEntity } from '../../graphql/type.interface';

import { AccessaryInput } from './form';

type Props = {
  openSelectAccessary: boolean;
  setOpenSelectAccessary: (value: boolean) => void;
  onFinish: (values: AccessaryInput) => void;
  vehicleTypesData?: CategoryEntity[];
  modelsData?: CategoryEntity[];
  manufacturersData?: CategoryEntity[];
};

const ModalSelectDevice = memo(
  ({
    openSelectAccessary,
    setOpenSelectAccessary,
    onFinish,
    vehicleTypesData,
    modelsData,
    manufacturersData,
  }: Props) => {
    return (
      <ModalCustomize
        open={openSelectAccessary}
        title="Dùng cho (các) thiết bị"
        width={800}
        okButtonProps={{
          form: 'add-accessary',
          htmlType: 'submit',
        }}
        onCancel={() => setOpenSelectAccessary(false)}
      >
        <Form
          labelAlign="left"
          id="add-accessary"
          layout="vertical"
          className="w-full h-[360px] relative modal-accessary"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                label="Chủng loại máy"
                name="vehicleTypeId"
                rules={[{ required: true, message: validationMessage.required }]}
              >
                <Select
                  open={true}
                  showSearch
                  allowClear
                  placeholder="Chọn chủng loại"
                  options={(vehicleTypesData ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  className="w-[220px]"
                  popupClassName="dropdown-modal-accesaary"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Hãng sản xuất"
                name="manufacturerId"
                rules={[{ required: true, message: validationMessage.required }]}
              >
                <Select
                  open={true}
                  showSearch
                  allowClear
                  placeholder="Chọn hãng sản xuất"
                  options={(manufacturersData ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  className="w-[220px]"
                  popupClassName="dropdown-modal-accesaary"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Model" name="modelId" rules={[{ required: true, message: validationMessage.required }]}>
                <Select
                  open={true}
                  showSearch
                  allowClear
                  placeholder="Chọn model."
                  options={(modelsData ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  className="w-[220px]"
                  popupClassName="dropdown-modal-accesaary"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalCustomize>
    );
  },
);

export default ModalSelectDevice;
