import { gql } from '@apollo/client';

import type * as Types from '../type.interface';

import { MediaFragmentFragmentDoc } from './media.fragment.generated';
import { CategoryFragmentFragmentDoc } from './category.fragment.generated';
export type PartnerFragmentFragment = { __typename?: 'PartnerEntity' } & Pick<
  Types.PartnerEntity,
  | 'description'
  | 'avatarId'
  | 'citizenId'
  | 'email'
  | 'fullname'
  | 'addressMoreInfo'
  | 'hotline'
  | 'id'
  | 'isActive'
  | 'phone'
  | 'type'
  | 'latitude'
  | 'longitude'
  | 'mapAddress'
  | 'isApproved'
  | 'bank'
  | 'birthday'
  | 'cardNumber'
  | 'parentId'
  | 'countTechnician'
  | 'countProduct'
  | 'star'
> & {
    avatar?: Types.Maybe<
      { __typename?: 'Media' } & Pick<
        Types.Media,
        | 'createdAt'
        | 'fileSize'
        | 'fullOriginalUrl'
        | 'fullThumbUrl'
        | 'id'
        | 'isDeleted'
        | 'mimeType'
        | 'name'
        | 'originalUrl'
        | 'ownerId'
        | 'thumbUrl'
        | 'type'
        | 'updatedAt'
      >
    >;
    qualifications?: Types.Maybe<
      Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>>
    >;
    education?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
    >;
    level?: Types.Maybe<
      { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
    >;
    reviewSummary?: Types.Maybe<{ __typename?: 'ReviewSummary' } & Pick<Types.ReviewSummary, 'total'>>;
    starInfo: Array<{ __typename?: 'StarInfo' } & Pick<Types.StarInfo, 'total'>>;
  };

export const PartnerFragmentFragmentDoc = gql`
  fragment PartnerFragment on PartnerEntity {
    avatar {
      ...MediaFragment
    }
    description
    avatarId
    citizenId
    email
    fullname
    addressMoreInfo
    hotline
    id
    isActive
    phone
    qualifications {
      ...CategoryFragment
    }
    type
    education {
      ...CategoryFragment
    }
    latitude
    longitude
    mapAddress
    isApproved
    bank
    birthday
    cardNumber
    citizenId
    parentId
    level {
      ...CategoryFragment
    }
    reviewSummary {
      total
    }
    countTechnician
    countProduct
    star
    starInfo {
      total
    }
  }
  ${MediaFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
`;
