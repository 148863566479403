import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Spin, Switch, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CategoryEntity,
  CategoryTypeEnum,
  OperatingUnitEnum,
  ProductTypeEnum,
  StatusEnum,
} from '../../graphql/type.interface';
import { InputNumberFormat, SubHeader, UploadImage, UploadImageRef } from '../../components';
import { AppRoutes, AppRoutesName } from '../../helpers';
import { useCategoriesQuery } from '../../graphql/queries/categories.generated';
import { validationMessage } from '../../constants';
import { usePartnerCreateProductVehicleMutation } from '../../graphql/mutations/partnerCreateProductVehicle.generated';
import { usePartnerCreateProductAccessaryMutation } from '../../graphql/mutations/partnerCreateProductAccessary.generated';
import { AppButton } from '../../components/button';
import { useDialog } from '../../contexts';
import { AddIcon } from '../../assets/icon';
import { showNotification } from '../../helpers/utils';
import { usePartnerProductQuery } from '../../graphql/queries/partnerProduct.generated';
import { usePartnerUpdateProductStatusMutation } from '../../graphql/mutations/partnerUpdateProductStatus.generated';
import { usePartnerRemoveProductMutation } from '../../graphql/mutations/partnerRemoveProduct.generated';
import { usePartnerUpdateProductVehicleMutation } from '../../graphql/mutations/partnerUpdateProductVehicle.generated';
import { usePartnerUpdateAccessaryMutation } from '../../graphql/mutations/partnerUpdateAccessary.generated';
import { useCheckProductVehicleRegistrationPlateExistLazyQuery } from '../../graphql/queries/checkProductVehicleRegistrationPlateExist.generated';
import { useCheckProductSerialExistLazyQuery } from '../../graphql/queries/checkProductSerialExist.generated';
import { useCheckProductVinExistLazyQuery } from '../../graphql/queries/checkProductVinExist.generated';
import { useCheckProductOrdinalNumberExistLazyQuery } from '../../graphql/queries/checkProductOrdinalNumberExist.generated';
import { useCheckProductPartNumberExistLazyQuery } from '../../graphql/queries/checkProductPartNumberExist.generated';

import ModalSelectDevice from './modal-select-device';
import './styles.css';

type Props = {
  type?: ProductTypeEnum;
  isEdit?: boolean;
  isDetail?: boolean;
};

type FormData = {
  descriptionImageIds?: string[];
  detail?: string;
  isFixedCost: boolean;
  isNew: boolean;
  isActive: boolean;
  name: string;
  operatingNumber?: number;
  ordinalNumber?: number;
  operatingUnit?: OperatingUnitEnum;
  manufacturerId: string;
  modelId?: string;
  originId?: string;
  productUnitId: string;
  quantity: number;
  vehicleTypeId: string;
  partId: string;
  serialNumber?: string;
  unitPrice?: number;
  vehicleRegistrationPlate?: string;
  vinNumber: string;
  yearOfManufacture?: number;
  partNumber?: string;
};

export type AccessaryInput = { vehicleTypeId: string; manufacturerId: string; modelId: string };

const filters = {
  limit: 1000,
  page: 1,
  isActive: StatusEnum.ACTIVE,
};

const showMessageAddError = (err: Error) => {
  showNotification('error', 'Thêm sản phẩm thất bại', err?.message);
};

const showMessageAddSuccess = () => {
  showNotification('success', 'Thêm sản phẩm thành công');
};

const ProductForm = memo(({ type: productType, isEdit = false, isDetail = false }: Props) => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [isVehicle, setIsVehicle] = useState(productType === ProductTypeEnum.VEHICLE);
  const [form] = useForm();
  const ref = useRef<UploadImageRef>(null);
  const refDescImages = useRef<UploadImageRef>(null);

  const [edit, setEdit] = useState(false);
  const [openSelectAccessary, setOpenSelectAccessary] = useState(false);

  const { showDialog } = useDialog();

  const { data, loading: gettingProduct } = usePartnerProductQuery({ variables: { id: id as string }, skip: !id });
  const product = useMemo(() => data?.partnerProduct, [data?.partnerProduct]);

  const { data: vehicleTypesData, loading: vehicleTypesLoading } = useCategoriesQuery({
    variables: { ...filters, type: CategoryTypeEnum.VEHICLE_TYPE },
  });

  const { data: manufacturersData, loading: manufacturersLoading } = useCategoriesQuery({
    variables: { ...filters, type: CategoryTypeEnum.MANUFACTURER },
  });

  const { data: modelsData, loading: modelsLoading } = useCategoriesQuery({
    variables: { ...filters, type: CategoryTypeEnum.MODEL },
  });

  const { data: originsData, loading: originsLoading } = useCategoriesQuery({
    variables: { ...filters, type: CategoryTypeEnum.ORIGIN },
  });

  const { data: productUnitsData, loading: productUnitsLoading } = useCategoriesQuery({
    variables: { ...filters, type: CategoryTypeEnum.PRODUCT_UNIT },
  });

  const { data: partOfNumberData, loading: partOfNumberLoading } = useCategoriesQuery({
    variables: { ...filters, type: CategoryTypeEnum.PART_OF_PRODUCT },
  });

  const [createProductVehicle, { loading: creatingVehicle }] = usePartnerCreateProductVehicleMutation({
    onError(error) {
      showMessageAddError(error);
    },
    onCompleted() {
      showMessageAddSuccess();
      navigate(AppRoutes.products.list);
    },
  });

  const [createProductAcssesary, { loading: creatingAcessary }] = usePartnerCreateProductAccessaryMutation({
    onError(error) {
      showMessageAddError(error);
    },
    onCompleted() {
      showMessageAddSuccess();
      navigate(AppRoutes.products.list);
    },
  });

  const [updateStatusMutation, { loading: updating }] = usePartnerUpdateProductStatusMutation({
    onError(error) {
      showNotification('error', 'Cập nhật trạng thái sản phẩm thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Cập nhật trạng thái sản phẩm thành công');
      navigate(AppRoutes.products.list);
    },
  });

  const [updateVehicleMutation, { loading: updatingVehicle }] = usePartnerUpdateProductVehicleMutation({
    onError(error) {
      showNotification('error', 'Lưu sản phẩm thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Lưu sản phẩm thành công');
      navigate(AppRoutes.products.list);
    },
  });

  const [updateAccessaryMutation, { loading: updatingAccessary }] = usePartnerUpdateAccessaryMutation({
    onError(error) {
      showNotification('error', 'Lưu sản phẩm thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Lưu sản phẩm thành công');
      navigate(AppRoutes.products.list);
    },
  });

  const [removeProductMutation, { loading: removing }] = usePartnerRemoveProductMutation({
    onError(error) {
      showNotification('error', 'Xóa sản phẩm thất bại', error?.message);
    },
    onCompleted() {
      showNotification('success', 'Xóa sản phẩm thành công');
      navigate(AppRoutes.products.list);
    },
  });

  const [CheckProductVehicleRegistrationPlateExist] = useCheckProductVehicleRegistrationPlateExistLazyQuery();
  const [CheckProductSerialExist] = useCheckProductSerialExistLazyQuery();
  const [CheckProductVinExist] = useCheckProductVinExistLazyQuery();
  const [CheckProductOrdinalNumberExist] = useCheckProductOrdinalNumberExistLazyQuery();
  const [CheckProductPartNumberExist] = useCheckProductPartNumberExistLazyQuery();

  const loading = useMemo(
    () =>
      vehicleTypesLoading ||
      manufacturersLoading ||
      modelsLoading ||
      originsLoading ||
      productUnitsLoading ||
      creatingVehicle ||
      creatingAcessary ||
      partOfNumberLoading ||
      gettingProduct ||
      removing ||
      updating ||
      updatingAccessary ||
      updatingVehicle,
    [
      vehicleTypesLoading,
      manufacturersLoading,
      modelsLoading,
      originsLoading,
      productUnitsLoading,
      creatingVehicle,
      creatingAcessary,
      partOfNumberLoading,
      gettingProduct,
      removing,
      updating,
      updatingAccessary,
      updatingVehicle,
    ],
  );

  const onFinish = useCallback(
    async (values: FormData) => {
      // create vehicle
      const avatarId = await ref.current?.upload();
      const descriptionImageIds = await refDescImages.current?.uploads();

      if (!isEdit && isVehicle) {
        createProductVehicle({
          variables: {
            input: {
              ...values,
              type: ProductTypeEnum.VEHICLE,
              yearOfManufacture: Number(dayjs(values?.yearOfManufacture).format('YYYY')),
              avatarId: avatarId as string,
              descriptionImageIds,
            },
          },
        });
        return;
      }

      // create accessary
      if (!isEdit && !isVehicle) {
        const avatarId = await ref.current?.upload();
        createProductAcssesary({
          variables: {
            input: {
              ...values,
              type: ProductTypeEnum.ACCESSARY,
              avatarId: avatarId as string,
              descriptionImageIds,
            },
          },
        });
        return;
      }

      // edit vehicle
      if (isEdit && isVehicle && product) {
        updateVehicleMutation({
          variables: {
            input: {
              id: product?.id,
              ...values,
              yearOfManufacture: Number(dayjs(values?.yearOfManufacture).format('YYYY')),
              avatarId: avatarId as string,
              descriptionImageIds,
            },
          },
        });
      }
      // edit accessary
      if (isEdit && !isVehicle && product) {
        updateAccessaryMutation({
          variables: {
            input: {
              id: product?.id,
              ...values,
              avatarId: avatarId as string,
              descriptionImageIds,
            },
          },
        });
      }
    },
    [
      createProductAcssesary,
      createProductVehicle,
      isEdit,
      isVehicle,
      product,
      updateAccessaryMutation,
      updateVehicleMutation,
    ],
  );

  const handleChangeStatus = useCallback(() => {
    if (!product?.id) return;
    updateStatusMutation({ variables: { input: { id: product?.id, isActive: !product?.isActive } } });
  }, [product?.id, product?.isActive, updateStatusMutation]);

  const handleRemoveProduct = useCallback(() => {
    if (!product?.id) return;
    removeProductMutation({ variables: { id: product?.id } });
  }, [product?.id, removeProductMutation]);

  const productDevices = Form.useWatch('productDevices', form);
  const handleSelectAccessary = useCallback(
    (values: AccessaryInput) => {
      if (values) {
        const productDevicesPrev: AccessaryInput[] = [];
        (productDevices || []).forEach((item: AccessaryInput) => {
          if (item?.manufacturerId && item?.modelId && item?.vehicleTypeId) {
            productDevicesPrev.push(item);
          }
        });

        form.setFieldsValue({
          productDevices: [...productDevicesPrev, values],
        });
        setOpenSelectAccessary(false);
      }
    },
    [productDevices, form],
  );

  useEffect(() => {
    setEdit(isEdit);
  }, [isEdit]);

  useEffect(() => {
    if (product) {
      const { yearOfManufacture, ...withoutProduct } = product;
      form.setFieldsValue({
        ...withoutProduct,
        yearOfManufacture: yearOfManufacture ? dayjs(yearOfManufacture.toString()) : undefined,
        manufacturerId: product?.manufacturer?.id,
        modelId: product?.model?.id,
        originId: product?.origin?.id,
        productUnitId: product?.productUnit?.id,
        vehicleTypeId: product?.productType?.id,
        partId: product?.partOfProduct?.id,
        productDevices: (product?.productDevices ?? []).map((device) => ({
          manufacturerId: device?.manufacturer?.id,
          modelId: device?.model?.id,
          vehicleTypeId: device?.vehicleType?.id,
        })),
      });

      if (product?.type === ProductTypeEnum.VEHICLE) {
        setIsVehicle(true);
      }
    }
  }, [product, form]);

  return (
    <Spin spinning={loading}>
      <SubHeader
        title={product ? product?.name : `Thêm mới ${isVehicle ? 'xe' : 'phụ tùng'}`}
        items={[
          { title: AppRoutesName.home, to: AppRoutes.home },
          { title: AppRoutesName.products, to: AppRoutes.products.list },
        ]}
        rightContent={
          product &&
          !edit && (
            <div className="flex items-center">
              <Button
                type="default"
                onClick={() =>
                  showDialog({
                    content: 'Sản phẩm sẽ không hiển thị ở kênh người mua',
                    title: `Bạn có chắc muốn thay đổi trạng thái của sản phẩm ${product?.name} hay không?`,
                    confirmText: product?.isActive ? 'Ẩn' : 'Hiện',
                    onOK: () => handleChangeStatus(),
                  })
                }
              >
                {product?.isActive ? 'Ẩn' : 'Hiện'}
              </Button>
              <AppButton
                type="_D63120"
                className="mx-12px"
                onClick={() =>
                  showDialog({
                    content: 'Sau khi xoá bạn không thể khôi phục sản phẩm',
                    title: `Bạn chắc chắn muốn xóa sản phẩm  ${product?.name}`,
                    confirmText: 'Xóa',
                    onOK: () => handleRemoveProduct(),
                  })
                }
              >
                Xóa
              </AppButton>
              <Button type="primary" onClick={() => setEdit(true)}>
                Chỉnh sửa
              </Button>
            </div>
          )
        }
      />
      <Form
        form={form}
        labelAlign="left"
        id="create-product"
        layout="vertical"
        initialValues={{
          isFixedCost: true,
          isNew: false,
          isActive: true,
          yearOfManufacture: !isDetail && !isEdit ? dayjs('1970') : undefined,
        }}
        disabled={!isDetail && !isEdit ? false : !edit}
        className="w-[868px] bg-white mx-auto mt-20px mb-[76px]"
        onFinish={onFinish}
      >
        <div className="p-20px">
          <h2 className="text-16px leading-24px font-semibold mb-8px">Thông tin sản phẩm</h2>
          <Form.Item
            label={
              <span>
                <span className="text-error">* </span>
                {isVehicle ? 'Ảnh sản phẩm' : 'Ảnh phụ tùng'}
              </span>
            }
            name="avatarId"
            rules={[
              {
                validator: (rule, value) => {
                  if (!value) return Promise.reject(validationMessage.required);
                  return Promise.resolve();
                },
              },
            ]}
          >
            <UploadImage
              ref={ref}
              files={
                product?.avatar?.fullThumbUrl
                  ? ([
                      {
                        uid: product?.avatar?.id,
                        name: product?.avatar?.name,
                        status: 'done',
                        url: product?.avatar?.fullThumbUrl,
                      },
                    ] as UploadFile[])
                  : undefined
              }
            />
          </Form.Item>
          <Form.Item
            label={<span>Ảnh mô tả</span>}
            name="descriptionImageIds"
            rules={[
              {
                validator: (rule, values) => {
                  if (values && values.length > 10) return Promise.reject('Chỉ được phép tải lên tối đa 10 ảnh');
                  return Promise.resolve();
                },
              },
            ]}
          >
            <UploadImage
              multiple
              ref={refDescImages}
              files={
                product?.descriptionImages
                  ? ([
                      ...(product?.descriptionImages ?? []).map((image) => ({
                        uid: image?.id,
                        name: image?.name,
                        status: 'done',
                        url: image?.fullThumbUrl,
                      })),
                    ] as UploadFile[])
                  : undefined
              }
            />
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={`Tên ${isVehicle ? 'máy' : 'phụ tùng'}`}
                name="name"
                normalize={(e) => e.trimStart()}
                rules={[{ required: true, message: validationMessage?.required }]}
              >
                <Input placeholder={`Vui lòng nhập tên ${isVehicle ? 'máy' : 'phụ tùng'}.`} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12}>
              {!isVehicle ? (
                <Form.Item
                  label="Ký hiệu/Model"
                  name="serialNumber"
                  normalize={(e) => e.trimStart()}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (value) {
                          const res = await CheckProductSerialExist({
                            variables: { serialNumber: value, id: product?.id },
                          });
                          if (res.data?.checkProductSerialExist) return Promise.reject('Ký hiệu/Model đã tồn tại');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={`Vui lòng nhập Ký hiệu/Model. `} maxLength={255} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Biển số"
                  name="vehicleRegistrationPlate"
                  normalize={(e) => e.trimStart()}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (value) {
                          const res = await CheckProductVehicleRegistrationPlateExist({
                            variables: { vehicleRegistrationPlate: value, id: product?.id },
                          });
                          if (res.data?.checkProductVehicleRegistrationPlateExist)
                            return Promise.reject('Biển số đã tồn tại');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={`Vui lòng nhập biển số.`} maxLength={255} />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              {!isVehicle ? (
                <Form.Item
                  label="Serial/Part-number"
                  name="partNumber"
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (value) {
                          const res = await CheckProductPartNumberExist({
                            variables: { partNumber: value, id: product?.id },
                          });
                          if (res.data?.checkProductPartNumberExist)
                            return Promise.reject('Serial/Part-number đã tồn tại');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={`Vui lòng nhập Serial/Part-number. `} maxLength={255} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Số thứ tự"
                  name="ordinalNumber"
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (value) {
                          const res = await CheckProductOrdinalNumberExist({
                            variables: { ordinalNumber: value, id: product?.id },
                          });
                          if (res?.data?.checkProductOrdinalNumberExist) {
                            return Promise.reject('Số thứ tự đã tồn tại');
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber placeholder="Vui lòng nhập số thứ tự." style={{ width: '100%' }} maxLength={255} />
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              {!isVehicle ? (
                <div>
                  <Form.Item
                    label={
                      <span>
                        <span className="text-red">* </span>Tình trạng
                      </span>
                    }
                    name="isNew"
                  >
                    <Radio.Group>
                      <Radio value={true}>Mới</Radio>
                      <Radio value={false}>Đã qua sử dụng</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, cur) => prev.isNew !== cur.isNew}>
                    {({ getFieldValue }) =>
                      !getFieldValue('isNew') ? (
                        <Form.Item label={<span />} colon={false}>
                          <Row align="middle" gutter={20}>
                            <Col span={12}>
                              <Form.Item
                                name="operatingNumber"
                                rules={[{ required: true, message: validationMessage?.required }]}
                              >
                                <InputNumberFormat min={1} placeholder="Nhập số" className="w-full" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name="operatingUnit"
                                rules={[{ required: true, message: validationMessage?.required }]}
                              >
                                <Select
                                  placeholder="Chọn thời gian"
                                  className="w-full"
                                  options={[
                                    { label: 'Giờ', value: OperatingUnitEnum.HOURS },
                                    { label: 'Km', value: OperatingUnitEnum.KM },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                </div>
              ) : (
                <Form.Item
                  label="Chủng loại máy"
                  name="vehicleTypeId"
                  rules={[{ required: true, message: validationMessage.required }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn chủng loại"
                    options={((vehicleTypesData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                    }
                  />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row gutter={20} className="mb-8px">
            <Col span={12}>
              {!isVehicle ? (
                <Form.Item label="Xuất xứ" name="originId">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn xuất xứ."
                    options={((originsData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Hãng sản xuất"
                  name="manufacturerId"
                  rules={[{ required: true, message: validationMessage.required }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn hãng sản xuất"
                    options={((manufacturersData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                    }
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              {!isVehicle ? (
                <Form.Item
                  label="Dùng cho bộ phận"
                  name="partId"
                  rules={[{ required: true, message: validationMessage.required }]}
                >
                  <Select
                    placeholder="Chọn bộ phận"
                    options={((partOfNumberData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Model"
                  name="modelId"
                  rules={[{ required: true, message: validationMessage.required }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn model."
                    options={((modelsData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                    }
                  />
                </Form.Item>
              )}
            </Col>
          </Row>

          {!isVehicle ? (
            <div>
              <h2 className="text-14px font-semibold leading-[20px] mb-8px">Lắp cho các thiết bị</h2>
              <Form.List name="productDevices">
                {(fields, { remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <div
                            key={key}
                            className="border border-solid rounded border-[#EEEEEE] px-20px pb-20px mb-20px"
                          >
                            <Row gutter={20} align={'middle'} className="mt-8px">
                              <Col span={20}>
                                <Row gutter={20}>
                                  <Col span={8}>
                                    <Form.Item
                                      {...restField}
                                      label="Thiết bị"
                                      name={[name, 'vehicleTypeId']}
                                      rules={[{ required: true, message: validationMessage.required }]}
                                    >
                                      <Select
                                        placeholder="Chọn chủng loại"
                                        options={((vehicleTypesData?.categories.items as CategoryEntity[]) ?? []).map(
                                          (el) => ({
                                            label: el.name,
                                            value: el.id,
                                          }),
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item {...restField} name={[name, 'manufacturerId']} label=" ">
                                      <Select
                                        placeholder="Chọn hãng sản xuất"
                                        options={((manufacturersData?.categories.items as CategoryEntity[]) ?? []).map(
                                          (el) => ({
                                            label: el.name,
                                            value: el.id,
                                          }),
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item {...restField} name={[name, 'modelId']} label=" ">
                                      <Select
                                        placeholder="Chọn model."
                                        options={((modelsData?.categories.items as CategoryEntity[]) ?? []).map(
                                          (el) => ({
                                            label: el.name,
                                            value: el.id,
                                          }),
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>

                              <Col span={4}>
                                <Form.Item label=" " name="">
                                  <AppButton
                                    style={{ borderColor: '#D63120', color: '#D63120' }}
                                    onClick={() => remove(name)}
                                  >
                                    Xóa
                                  </AppButton>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <Form.Item>
                        <div className="flex justify-end">
                          <Button
                            type="primary"
                            className="mb-[8px]"
                            onClick={() => {
                              setOpenSelectAccessary(true);
                            }}
                          >
                            <div className="flex items-center">
                              <AddIcon fill="#202C38" />
                              <span>Thêm thiết bị</span>
                            </div>
                          </Button>
                        </div>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>
          ) : (
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label="Số Serial"
                  name="serialNumber"
                  normalize={(e) => e.trimStart()}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (value) {
                          const res = await CheckProductSerialExist({
                            variables: { serialNumber: value, id: product?.id },
                          });
                          if (res.data?.checkProductSerialExist) return Promise.reject('Số Serial đã tồn tại');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={`Vui lòng nhập số serial. `} style={{ width: '100%' }} maxLength={255} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Số VIN"
                  name="vinNumber"
                  rules={[
                    { required: true, message: 'Số VIN là trường bắt buộc.' },
                    {
                      validator: async (rule, value) => {
                        if (value) {
                          const res = await CheckProductVinExist({
                            variables: { vinNumber: value, id: product?.id },
                          });
                          if (res.data?.checkProductVinExist) return Promise.reject('Số vin đã tồn tại');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={`Vui lòng nhập số VIN.`} style={{ width: '100%' }} maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {!isVehicle ? (
            <Form.Item
              label="Chi tiết"
              name="detail"
              normalize={(e) => e.trimStart()}
              rules={[{ required: true, message: validationMessage?.required }]}
            >
              <TextArea placeholder={`Nhập chi tiết.`} rows={4} maxLength={1000} showCount />
            </Form.Item>
          ) : (
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label="Xuất xứ" name="originId">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn xuất xứ."
                    options={((originsData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Năm sản xuất" name="yearOfManufacture">
                  <DatePicker
                    className="w-full"
                    disabledDate={(v) => dayjs(v).unix() > dayjs().unix()}
                    picker="year"
                    placeholder="Chọn năm sản xuất"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {isVehicle && (
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      <span className="text-red">* </span>Tình trạng
                    </span>
                  }
                  name="isNew"
                >
                  <Radio.Group>
                    <Radio value={true}>Mới</Radio>
                    <Radio value={false}>Đã qua sử dụng</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prev, cur) => prev.isNew !== cur.isNew}>
                  {({ getFieldValue }) =>
                    !getFieldValue('isNew') ? (
                      <Form.Item label={<span />} colon={false}>
                        <Row align="middle" gutter={20}>
                          <Col span={12}>
                            <Form.Item
                              name="operatingNumber"
                              rules={[{ required: true, message: validationMessage?.required }]}
                            >
                              <InputNumberFormat min={1} placeholder="Nhập số" className="w-full" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="operatingUnit"
                              rules={[{ required: true, message: validationMessage?.required }]}
                            >
                              <Select
                                placeholder="Chọn thời gian"
                                className="w-full"
                                options={[
                                  { label: 'Giờ', value: OperatingUnitEnum.HOURS },
                                  { label: 'Km', value: OperatingUnitEnum.KM },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Chi tiết"
                  name="detail"
                  normalize={(e) => e.trimStart()}
                  rules={[{ required: true, message: validationMessage?.required }]}
                >
                  <TextArea placeholder={`Nhập chi tiết.`} rows={4} maxLength={1000} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
        <div className="h-8px w-full my-20px bg-[#f9f9f9]"></div>
        <div className="p-20px pt-2px">
          <h2 className="text-16px leading-24px font-semibold mb-8px">Thông tin giá</h2>
          <Form.Item label="Giá sản phẩm" name="isFixedCost">
            <Radio.Group>
              <Radio value={true}>Cố định</Radio>
              <Radio value={false}>Thương lượng</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, cur) => prev.isFixedCost !== cur.isFixedCost}>
            {({ getFieldValue }) =>
              getFieldValue('isFixedCost') ? (
                <Form.Item
                  name="unitPrice"
                  label={'Giá'}
                  colon={false}
                  rules={[{ required: true, message: validationMessage?.required }]}
                >
                  <InputNumberFormat placeholder="Nhập giá" style={{ width: '100%' }} min={1} suffix={'VNĐ'} />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Tồn kho"
                name="quantity"
                rules={[{ required: true, message: validationMessage?.required }]}
              >
                <InputNumberFormat placeholder={`Nhập số lượng.`} style={{ width: '100%' }} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Chọn đơn vị"
                name="productUnitId"
                rules={[{ required: true, message: validationMessage?.required }]}
              >
                <Select
                  placeholder="Chọn đơn vị."
                  options={((productUnitsData?.categories.items as CategoryEntity[]) ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  loading={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Trạng thái" name="isActive" valuePropName="checked" className="hidden">
            <Switch />
          </Form.Item>
        </div>
      </Form>
      {(edit || (!isDetail && !isEdit)) && (
        <div className="flex justify-end items-center fixed left-0 right-0 bottom-0 pr-[24px] py-8px bg-white">
          <Button
            className="mr-16px"
            type="default"
            onClick={() => {
              navigate(-1);
            }}
          >
            Huỷ
          </Button>
          <Button type="primary" htmlType="submit" form="create-product">
            Lưu
          </Button>
        </div>
      )}
      {openSelectAccessary && (
        <ModalSelectDevice
          vehicleTypesData={vehicleTypesData?.categories.items ?? []}
          modelsData={modelsData?.categories.items ?? []}
          manufacturersData={manufacturersData?.categories.items ?? []}
          openSelectAccessary={openSelectAccessary}
          setOpenSelectAccessary={setOpenSelectAccessary}
          onFinish={handleSelectAccessary}
        />
      )}
    </Spin>
  );
});
export default ProductForm;
