import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';

type Props = {
  options?: { value: string; label: string }[];
  defaultValue?: string;
  onChange?: (value: string) => void;
};

export const ButtonPicker = memo(({ options, defaultValue, onChange }: Props) => {
  const [value, setValue] = useState<string | undefined>();

  const triggerChange = useCallback(
    (newValue: string) => {
      onChange?.(newValue);
    },
    [onChange],
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="flex items-center flex-wrap">
      {(options ?? []).map((option) => (
        <Button
          onClick={() => triggerChange(option.value)}
          className={`border rounded  bg-white outline-none mr-12px mb-8px ${
            value === option.value
              ? '!border-grayscale-black text-grayscale-black'
              : 'border-grayscale-border text-grayscale-gray'
          }`}
          key={option.value}
        >
          <span className="font-normal text-14px">{option.label}</span>
        </Button>
      ))}
    </div>
  );
});
