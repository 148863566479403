import { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { Col, Radio, Row, Spin, notification } from 'antd';
import '../../style.less';

import { StarIcon } from '../../../../assets/icon';
import { ModalCustomize } from '../../../../components';
import { useAgencyGetTechniciansQuery } from '../../../../graphql/queries/agencyGetTechnicians.generated';
import { getNameCategoriesEntity } from '../../../../helpers/utils';
import { CategoryEntity, StatusEnum } from '../../../../graphql/type.interface';
import { useAgencyAssignBookingMutation } from '../../../../graphql/mutations/agencyAssignBooking.generated';
import { PartnerBookingDocument, usePartnerBookingQuery } from '../../../../graphql/queries/partnerBooking.generated';
import { PartnerBookingsDocument } from '../../../../graphql/queries/partnerBookings.generated';

type TProps = {
  bookingId: string;
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
};

export const AssignTechnician = memo(({ bookingId, ...props }: TProps) => {
  const [seletedTechnicianId, setSeletedTechnicianId] = useState<string>();

  const { data: res, loading: gettingTechnicians } = useAgencyGetTechniciansQuery({
    variables: { limit: 1000, isActive: StatusEnum.ACTIVE },
  });
  const technicians = useMemo(() => res?.agencyGetTechnicians.items, [res]);

  const { data: result, loading } = usePartnerBookingQuery({
    variables: { id: bookingId },
    fetchPolicy: 'cache-and-network',
    skip: !bookingId,
  });
  const data = useMemo(() => result?.partnerBooking, [result]);

  const [assignTechnicianApi, { loading: assigning }] = useAgencyAssignBookingMutation({
    onError(error) {
      notification.error({
        message: 'Điều phối kĩ thuật viên thất bại',
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: 'Đã gửi yêu cầu điều phối thành công',
      });
      props.onOk?.();
    },
  });

  const onFinish = useCallback(() => {
    if (bookingId && seletedTechnicianId) {
      assignTechnicianApi({
        variables: {
          input: {
            bookingId: bookingId,
            technicianId: seletedTechnicianId,
          },
        },
        refetchQueries: [PartnerBookingDocument, PartnerBookingsDocument],
      });
    }
  }, [assignTechnicianApi, bookingId, seletedTechnicianId]);

  const onChange = (id: string) => {
    setSeletedTechnicianId(id);
  };

  useEffect(() => {
    if (data?.technicianId) {
      setSeletedTechnicianId(data?.technicianId);
    }
  }, [data]);

  return (
    <ModalCustomize
      title="Phân giao Kỹ thuật viên"
      {...props}
      okButtonProps={{ disabled: !seletedTechnicianId || assigning || loading }}
      onCancel={props.onCancel}
      onOk={onFinish}
    >
      <Spin spinning={gettingTechnicians || assigning || loading}>
        <div className="h-[440px] overflow-y-auto text-14px leading-18px mt-[16px]">
          {(technicians || []).map((technician) => (
            <label htmlFor={technician.id} key={technician.id} className="hover:cursor-pointer">
              <Row
                align={'middle'}
                className="py-[22px] px-[16px] border border-solid rounded border-[#EEEEEE] mb-[16px]"
              >
                <Col span={2}>
                  <img
                    className="w-40px h-40px rounded-full border-2 border-solid border-white shadow-md"
                    src={technician?.avatar?.fullThumbUrl ?? ''}
                  />
                </Col>
                <Col span={20} className="pl-[18px]">
                  <h3 className="font-semibold text-[14px] leading-[20px] text-grayscale-black">
                    {technician.fullname}
                  </h3>
                  <p className="text-[12px] py-[4px]">
                    <StarIcon />
                    <span className="inline-block pl-[4px] pr-[10px]">{technician?.reviewSummary?.starAverage}</span>
                    <span className="text-grayscale-light text-[12px] font-normal">
                      ({technician?.reviewSummary?.total} đánh giá)
                    </span>
                  </p>
                  <p className="tex-[12px] font-normal text-grayscale-gray">
                    {getNameCategoriesEntity(technician?.qualifications as CategoryEntity[])}
                  </p>
                </Col>
                <Col span={2}>
                  <div className="float-right">
                    <Radio
                      id={technician.id}
                      checked={technician.id === seletedTechnicianId}
                      onChange={() => onChange(technician.id)}
                    />
                  </div>
                </Col>
              </Row>
            </label>
          ))}
        </div>
      </Spin>
    </ModalCustomize>
  );
});
